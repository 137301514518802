import { Container, Stack, TextField, Typography } from "@mui/material"
import { useEffect } from "react"
import { fetchFilter } from "../includes/dbFilterFunc"


const Home = () => {

    return (
        <Container className="home">
            <Stack direction='row' justifyContent='center' alignItems="baseline" sx={{mt: 10}}>
                <Typography
                    variant='h2'
                    component='h1'
                    sx={{
                        letterSpacing: {xs: 10, md: 15},
                        fontWeight: {xs: 300, sm: 100, md: 100},
                        fontSize: {xs: 46, sm: 68, md: 86 }
                    }}
                > <nobr>Products</nobr></Typography>
            </Stack>
        </Container>
     );
}
 
export default Home;