import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import RefreshIcon from '@mui/icons-material/Refresh'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'
import { FormGroup, FormControlLabel, Checkbox, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, IconButton, Paper, Stack, TextField, Typography, Alert } from '@mui/material'
import { createEmailaddress, deleteEmailaddress, editEmailaddress, exitsEmailtype, fetchEmailaddress, fetchEmailaddresses } from "../includes/dbEmailaddressesFunc";
import { DataGrid } from "@mui/x-data-grid"
import { AuthContext } from '../components/AuthProvider'
import { useSnackbar } from 'notistack'
import { useContext, useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import InfoEmail from '../components/InfoEmail'


const columns = [
    { field: 'id', headerName: 'id', width: 50 },   
    { field: 'address', headerName: 'Adresse', width: 400 },
    { field: 'type', headerName: 'Typ', width: 150 },
    { field: 'isactive', headerName: 'Aktiv', width: 100 }
];


const Emailaddressespage = () => {
    const { id: urlId } = useParams()   //ID aus der URL.
    const { enqueueSnackbar } = useSnackbar()
    const { apikey, isLoggedin } = useContext(AuthContext)
    const { data, isLoading, refetch} = useQuery(['emailaddresses'], () => fetchEmailaddresses(0))
    const [selectionModel, setSelectionModel] = useState([])
    const [mode, setMode] = useState('')
    const [values, setValues] = useState({})
    const [errors, setErrors] = useState({})
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [isDialogOpen, setIsDialogOpen] = useState(false)


    useEffect(() => {
        if(urlId){
            //Beim Aufruf der Seite wurde in der URL eine ID angegeben. Wir wollen diese nun zum bearbeiten anzeigen.
            handleEdit( urlId )            
        }
    // eslint-disable-next-line
    },[])


    //Den angegebenen Datensatz im Drawer bearbeiten
    const handleEdit = async ( id ) => {
        const row = await fetchEmailaddress(id)
        if(row && row.status === 1){
            if(row.count === 1){
                setValues(row.results)
                handleOpenDrawer('edit')
            }else{
                enqueueSnackbar('Es existiert keine Email-Adresse mit der Id ' + id, {variant: 'warning'})
            }
        }else{
            enqueueSnackbar('Fehler bei der Abfrage der Datenbank.', {variant: 'error'})
        }
    }


    //Den Drawer öffnen. Als mode entweder create oder edit angeben. Sollte edit gewählt werden, muss der zu beareitende Datensatz in values angegeben werden.
    const handleOpenDrawer= (mode) => {
        if(mode==='create'){
            setValues({address: '', type: '', isactive: 1})  //Die in den Inputs angezeigten Werte rücksetzen (ein leeres Object führt zu einem Fehler).
            setErrors({})
        }else if(mode === 'edit'){
            //Die values wurden schon bei handleRowClick gesetzt, weil wir da die id kennen.
            setErrors({})
        }else{
            enqueueSnackbar('Unbekannter Modus.', {variant: 'error'})
            return
        }
        setMode(mode)
        setIsDrawerOpen(true)
    }


    //Drawer (rechte Seite) schliessen.
    const handleCloseDrawer = () =>{
        setIsDrawerOpen(false)
    }


    //Kontrolliert die Ausgaben und gibt true/false zurück, ausserdem werden die errors-Werte gesetzt, für die einzelenen Eingabefelder.
    const handleCheck = async () => {
        let collectErrors = {}
        let namecheck = /[^a-zA-Z0-9_']/
        let emailcheck = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if(!values.address){
            collectErrors.address = 'Email-Adresse eingeben.'
        }else if(!emailcheck.test(values.address)){
            collectErrors.address = "Korrekte Email-Adresse eingeben!"
        }

        if(!values.type){
            collectErrors.type = 'Typ eingeben.'
        }else if(namecheck.test(values.type)){
            collectErrors.type = "Es sind nur Buchstaben, Zahlen und '_' zulässig!"
        }else if( mode === 'create' && await exitsEmailtype( values.type, null, apikey )){
            collectErrors.type = 'Typ wird schon verwendet.'
        }else if( mode === 'edit' && await exitsEmailtype( values.type, values.id, apikey )){
            collectErrors.type = 'Typ wird schon verwendet.'
        }
        
        setErrors({
            ...collectErrors
        })

        return Object.keys(collectErrors).length === 0
    }


    //Datensatz erstellen, oder einen bestehenden ändern.
    const handleSave = async () => {
        if(mode === 'create'){
            //Neu erstellen.
            const res = await createEmailaddress(values.address, values.type, values.isactive, apikey)
            if(res && res.status === 1){
                enqueueSnackbar("Email-Adresse erfolgreich angelegt.", {variant: 'success'})
                handleCloseDrawer()
                refetch()
            }else{
                enqueueSnackbar(res.message, {variant: 'error'})
            }
        }else if(mode === 'edit'){
            //Aendern.
            const res = await editEmailaddress(values.id, values.address, values.type, values.isactive, apikey)
            if(res && res.status === 1){
                enqueueSnackbar("Email-Adresse erfolgreich geändert.", {variant: 'success'})
                handleCloseDrawer()
                refetch()
            }else{
                enqueueSnackbar(res.message, {variant: 'error'})
            }
        }else{
            enqueueSnackbar('Unbekannter Modus.', {variant: 'error'})
        }
    }


    //Einen Datensatz löschen
    const handleDelete = async (id) => {
        const result = await deleteEmailaddress(id, apikey)
        if(result.status === 0){
            enqueueSnackbar(result.message, {variant: "error"})
            return
        }else{
            enqueueSnackbar("Email-Adresse erfolgreich gelöscht.", {variant: "success"})
            handleCloseDrawer()
            refetch()
            return
        }   
    }


    //Alle ausgewählten Zeilen löschen
    const handleDeleteChecked = async () => {
        //selectionModel enthällt eine Liste der id mit allen selectierten Zeilen. Wieso id als Standard verwendet wird, keine Ahnung!
        for(const id of selectionModel){
            const result = await deleteEmailaddress(id, apikey)
            if(result.status === 0){
                enqueueSnackbar(result.message, {variant: "error"})
            }
        }
        refetch()
    }



    return ( 
        <Container>
            <Typography variant="h1">
                Email-Adressen
            </Typography>
            { isLoggedin() &&
                <div>
                    <div className="data">
                        <div className="data-table-toolbar">
                            <Stack direction='row' spacing={1}  justifyContent="flex-start" alignItems="baseline">
                                <IconButton color="primary" onClick={() => handleOpenDrawer('create')}>
                                    <AddIcon />
                                </IconButton>
                                <IconButton color="warning" onClick={() => setIsDialogOpen(true)} disabled={selectionModel.length === 0}>
                                    <DeleteIcon />
                                </IconButton>
                                <IconButton color="primary" onClick={refetch}>
                                    <RefreshIcon />
                                </IconButton>
                                { isLoading && <HourglassBottomIcon color='warning' />}
                            </Stack>
                            <div className="data-table" style={{ height: 650, width: '100%' }}>
                                <DataGrid
                                    density="compact"
                                    rows={data ? data.results : []}
                                    columns={columns}
                                    disableSelectionOnClick
                                    checkboxSelection
                                    pageSize={15}
                                    rowsPerPageOptions={[15]}                    
                                    disableColumnFilter
                                    onRowClick={(e) => handleEdit(e.id)}
                                    onSelectionModelChange={(newModel) => setSelectionModel(newModel)}
                                    selectionModel={selectionModel}
                                />                            
                            </div>
                        </div>
                    </div>
                    <Paper sx={{p: 3}}>
                        <Typography variant='h3'>Infos</Typography>
                        <InfoEmail variant='h4' />
                    </Paper>
                </div>
            }
            { !isLoggedin() && <Typography variant='h3'>Keine Berechtigung</Typography> }
            <Drawer className='drawer'
                PaperProps={{ sx: {width: { xs: 1, sm: 0.4 }} }}
                anchor="right"
                open={isDrawerOpen}
                onClose={handleCloseDrawer}
            >
                <div className="drawer-content">
                    <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="baseline">
                        <IconButton onClick={handleCloseDrawer}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <Typography variant='h5' gutterBottom color="primary">
                        { mode === "edit" && 'Adresse ändern...'}
                        { mode === "create" && 'Neue Adresse anlegen...'}
                    </Typography>
                    <Paper
                        elevation={0}
                        component="form"
                        autoComplete="off"
                    >
                        <Stack spacing={4}>
                            <Stack spacing={2} alignItems='flex-start'>
                                { mode === 'edit' && <TextField variant="standard" label="id" type="text" disabled fullWidth value={ values.id }/> }
                                <TextField
                                    variant='standard'
                                    label='Email-Adresse'
                                    id='email'
                                    required
                                    fullWidth
                                    helperText={ errors.addresse }
                                    error= { errors.address ? true : false  }
                                    value={ values.address }
                                    onChange={(e) => {
                                        setValues(prev =>({
                                            ...prev,
                                            address: e.target.value
                                        }))
                                    }}
                                />
                                <TextField
                                    variant='standard'
                                    label='Typ'
                                    id='type'
                                    fullWidth
                                    helperText={ errors.type }
                                    error= { errors.type ? true : false  }
                                    value={ values.type ? values.type : '' }
                                    onChange={(e) => {
                                        setValues(prev =>({
                                            ...prev,
                                            type: e.target.value
                                        }))
                                    }}
                                />
                                <FormGroup>
                                    <FormControlLabel label="Ist aktiv" control={
                                        <Checkbox
                                            id="isactive"
                                            checked={ values.isactive === 1 ? true : false }
                                            onChange={(e) => {
                                                const newValue = e.target.checked === true ? 1 : 0
                                                setValues(prev =>({
                                                    ...prev,
                                                    isactive: newValue,
                                                    isdate: 0,
                                                }))
                                            }}
                                        />
                                    } />
                                </FormGroup>
                            </Stack>
                            <Stack direction='row' spacing={1}>
                                <Button variant='contained' type='submit'
                                    onClick={async (e) => {
                                        e.preventDefault()
                                        const res = await handleCheck()
                                        res && handleSave()
                                    }}
                                >Save</Button>
                                <Button variant='contained' type='reset' onClick={handleCloseDrawer}>Cancel</Button>
                                { mode==='edit' &&
                                    <Button variant='contained' color="warning"
                                        onClick={() => {
                                            handleDelete( values.id )
                                        }}
                                    >Delete</Button>
                                }
                            </Stack>
                        </Stack>
                    </Paper>
                </div>
            </Drawer>
            <Dialog open={isDialogOpen}>
                <DialogTitle>Löschen</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Wollen Sie den ausgewählten PType wirklich löschen?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color='warning'
                        onClick={() => {
                            handleDeleteChecked()
                            setIsDialogOpen(false)
                        }}
                    >Ok</Button>
                    <Button onClick={() => setIsDialogOpen(false)}>Abbruch</Button>
                </DialogActions>
            </Dialog>
        </Container>
     );
}


export default Emailaddressespage;