import { coreFetch } from './dbCoreFunc'


//Alle Queries abfragen
//limit: nur die ersten n-Datensätze abfragen. Wenn nicht angegeben oder 0 werde alle Datensätze geliefert.
//search: Wird hier ein Wert angegeben, werden alle sinnvollen Spalten nach diesem Wert durchsucht. Ansonsten Null angeben.  
//return: ein Object, mit status, message, und den queries als results
export const fetchQueries = async ( limit, search ) => {
    const params = new URLSearchParams()
    if(limit && limit>0){ params.append('limit', limit) }
    if(search){ params.append('search', "%" + search + "%") }
    const paramsStr = '?' + params.toString()
    const uri = process.env.REACT_APP_API_PATH + '/queries/' + (paramsStr !== '?' ? paramsStr : '')

    const headers = {
        "Content-Type": "application/json"
    }

    return await coreFetch(uri, 'GET', headers)
}


//Ein Query abfragen mit einer id
//id: id der Query die abgefragt werden soll.
//return: ein Object, mit status, message, und der Query als results
export const fetchQuery = async ( id ) => {
    var uri = process.env.REACT_APP_API_PATH + '/queries/' + id;

    const headers = {
        "Content-Type": "application/json",
    }

    return await coreFetch(uri, 'GET', headers)
}


//Eine neue Query erstellen.
//return: Es wird eine Objekt mit status und message zurückgegeben.
export async function createQuery(query, apikey){

    const payload = {
        query: query
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(process.env.REACT_APP_API_PATH + '/queries/', 'POST', headers, JSON.stringify(payload))
}


//Eine Query ändern.
//id:   id der Query die geändert werden soll
//new{x}: die neuen Werte der Query
//apikey: ein gültiger APIKEY, ansonsten kann der PUT-Request nicht durchgeführt werden.
//Es wird eine Objekt mit status und message zurückgegeben.
export async function editQuery(id, query, apikey){
    const payload = {
        query: query
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(process.env.REACT_APP_API_PATH + '/queries/' + id, 'PUT', headers, JSON.stringify(payload))
}


//Eine Query löschen
//Es wird eine Objekt mit status und message zurückgegeben.
export async function deleteQuery(id, apikey){
    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(process.env.REACT_APP_API_PATH + '/queries/' + id, 'DELETE', headers)
}


/**
 * Alle Queries aus der Datenbank löschen
 * @param {string} apikey Key um Delete-Operationen in der API ausführen zu können
 * @returns {object} Die Antwort der API als Object mit Status und bei einem Fehler mit der Message
 */
export async function deleteAllQueries(apikey){
    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(process.env.REACT_APP_API_PATH + '/queries/?all=true', 'DELETE', headers)
}