import InfoIcon from '@mui/icons-material/Info'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Typography, Checkbox, Box, Dialog, DialogContent, DialogTitle, FormControlLabel, FormGroup, Step, Stepper, StepLabel, StepContent, Button, Stack, CircularProgress, Alert, AlertTitle, List, ListItemButton, ListItemText, Collapse, Accordion, AccordionSummary, AccordionDetails, Tooltip } from "@mui/material"
import { useState  } from 'react'
import PropTypes from 'prop-types'
import { checkWizard, executeWizard } from "../includes/dbWizardsFunc"



const WizardExecute = ( { isOpen, onClose, wizId, args, values, properties, apikey } ) => {
    const [ activeStep, setActiveStep] = useState(0)
    const [ doOverride, setDoOverride ] = useState(false)
    const [ doOverrideTranslations, setDoOverrideTranslations ] = useState(false)
    const [ doClean, setDoClean ] = useState(false)
    const [ doCleanSources, setDoCleanSources ] = useState(false)
    const [ doSkip, setDoSkip ] = useState(false)
    const [ isChecking, setIsChecking ] = useState(false)
    const [ isExecuting, setIsExecuting ] = useState(false)
    const [ checkResult, setCheckResult ] = useState(true)
    const [ checkStatus, setCheckStatus ] = useState('success')
    const [ checkInfoCount, setCheckInfoCount ] = useState(0)
    const [ checkWarningCount, setCheckWarningCount ] = useState(0)
    const [ checkErrorCount, setCheckErrorCount ] = useState(0)
    const [ checkinfoExpanded, setCheckinfoExpanded] = useState(false);
    const [ executeResult, setExecuteResult ] = useState(true)
    const [ executeStatus, setExecuteStatus ] = useState('success')
    const [ executeInfoCount, setExecuteInfoCount ] = useState(0)
    const [ executeWarningCount, setExecuteWarningCount ] = useState(0)
    const [ executeErrorCount, setExecuteErrorCount ] = useState(0)
    const [ executeinfoExpanded, setExecuteinfoExpanded] = useState(false);


    //Beim Settings wurde auf WEITER geklickt.
    const handleEndSettings = async () => {
        setActiveStep(1)
        setIsChecking(true)

        //einen Testlauf um Fehler zu finden.
        const answer = await checkWizard(wizId, args, values, properties, {override: doOverride, clean: doClean, cleansources: doCleanSources && doClean, skip: doSkip, overridetranslations: doOverrideTranslations}, apikey)
        setCheckInfoCount(0)
        setCheckWarningCount(0)
        setCheckErrorCount(0)
        
        if(answer && answer.status === 1){
            const counts = [0,0,0]
            for (const key in answer.results.infos) {
                const info = answer.results.infos[key]
                if(info.level === 'info'){
                    counts[0] = counts[0] + 1
                }else if(info.level === 'warning'){
                    counts[1] = counts[1] + 1
                }else if(info.level === 'error'){
                    counts[2] = counts[2] + 1
                }
            };
            setCheckInfoCount(counts[0])
            setCheckWarningCount(counts[1])
            setCheckErrorCount(counts[2])
            setCheckResult(answer.results)

            if(counts[2]>0){
                setCheckStatus('error')
            }else if(counts[1]>0){
                setCheckStatus('warning')
            }else{
                setCheckStatus('success')
            }

        }else{
            setCheckResult({
                check: false,
                infos: {
                    error: {level: 'error', title: 'Ausführungsfehler', content: 'Check konnte aufgrund eines Programmfehlers nicht durchgeführt werden. ' + answer.message}
                }
            })
            setCheckErrorCount(1)
            setCheckStatus('error')
        }

        setIsChecking(false)
    }

    //Beim Check wurde auf WEITER geklickt.
    const handleEndCheck = async () => {
        if(!checkResult){return false}
        setActiveStep(2)

        setIsExecuting(true)
        //Ausführen
        const answer = await executeWizard(wizId, args, values, properties, {override: doOverride, clean: doClean, cleansources: doCleanSources && doClean, skip: doSkip, overridetranslations: doOverrideTranslations}, apikey)
        setExecuteInfoCount(0)
        setExecuteWarningCount(0)
        setExecuteErrorCount(0)

        if(answer && answer.status === 1){
            const counts = [0,0,0]
            for (const key in answer.results.infos) {
                const info = answer.results.infos[key]
                if(info.level === 'info'){
                    counts[0] = counts[0] + 1
                }else if(info.level === 'warning'){
                    counts[1] = counts[1] + 1
                }else if(info.level === 'error'){
                    counts[2] = counts[2] + 1
                }
            };
            setExecuteInfoCount(counts[0])
            setExecuteWarningCount(counts[1])
            setExecuteErrorCount(counts[2])
            setExecuteResult(answer.results)

            if(counts[2]>0){
                setExecuteStatus('error')
            }else if(counts[1]>0){
                setExecuteStatus('warning')
            }else{
                setExecuteStatus('success')
            }

        }else{
            setExecuteResult({
                check: false,
                infos: {
                    error: {level: 'error', title: 'Ausführungsfehler', content: 'Wizard konnte aufgrund eines Programmfehlers nicht durchgeführt werden. ' + answer.message}
                }
            })
            setExecuteErrorCount(1)
            setExecuteStatus('error')
        }
        setIsExecuting(false)
        setActiveStep(3)
    }

    const handleGoBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    const handleGotoStep = ( step ) => {
        setActiveStep(step)
    }

    

    const handleOnClose = ( returnValue ) => {
        setActiveStep(0)
        setDoClean(false)
        setDoCleanSources(false)
        setDoSkip(false)
        setDoOverride(false)
        setDoOverrideTranslations(false)
        onClose(returnValue)
    }


    return ( 
        <Dialog
            PaperProps={{ sx: {maxHeight: { xs: 1, sm: 0.8 }} }}
            fullWidth
            maxWidth='lg'
            open={isOpen ? isOpen : false}
        >
            <DialogTitle>Execute Wizard</DialogTitle>
            <DialogContent>
                <Stepper activeStep={activeStep} orientation="vertical">
                    <Step>
                        <StepLabel>Settings</StepLabel>
                        <StepContent>
                            <Box>
                                <FormGroup>
                                    <Stack direction='row'>
                                        <FormControlLabel control={ <Checkbox color='warning' disabled={(!wizId || wizId === '')} checked={doClean} onChange={(e) => setDoClean(e.target.checked)} /> } label='Alle bestehenden Produkte die mit diesem Wiz erstellt wurden zuerst löschen.' />
                                        {(!wizId || wizId === '') &&
                                            <Tooltip title='Nur möglich, wenn der Wiz schon gespeichert wurde.'>
                                                <InfoIcon color='info' />
                                            </Tooltip>
                                        }
                                    </Stack>
                                    <Stack direction='row'>
                                        <FormControlLabel control={ <Checkbox color='warning' disabled={(!wizId || wizId === '')} checked={doCleanSources} onChange={(e) => setDoCleanSources(e.target.checked)} /> } label='In den bestehenden Produkten verwendete Sources löschen.' />
                                        {(!wizId || wizId === '') &&
                                            <Tooltip title='Nur möglich, wenn der Wiz schon gespeichert wurde.'>
                                                <InfoIcon color='info' />
                                            </Tooltip>
                                        }
                                    </Stack>
                                    <FormControlLabel control={ <Checkbox disabled={doCleanSources} checked={doOverrideTranslations} onChange={(e) => setDoOverrideTranslations(e.target.checked)} /> } label='Vorhandene Übersetzungen von Sources überschreiben.' />
                                    <FormControlLabel
                                        label='Produkte mit identischer Nummer überschreiben.'
                                        control={
                                            <Checkbox checked={doOverride} onChange={(e) => {
                                                setDoOverride(e.target.checked)
                                                if(e.target.checked){setDoSkip(false)}
                                            }} />
                                        }
                                    />
                                    <FormControlLabel
                                        label='Falls Produktnummer schon existiert, diese Variante überspringen.'
                                        control={
                                            <Checkbox checked={doSkip} onChange={(e) => {
                                                setDoSkip(e.target.checked)
                                                if(e.target.checked){setDoOverride(false)}
                                            }} />
                                        }
                                    />
                                </FormGroup>
                                <Stack spacing={1} direction='row' alignItems='flex-start' mt={2} mb={3}>
                                    <Button variant='contained' onClick={handleEndSettings}>weiter</Button>
                                    <Button variant='contained' onClick={() => handleOnClose(false)}>close</Button>
                                </Stack>
                            </Box>
                        </StepContent>
                    </Step>
                    <Step>
                        <StepLabel>Kontrolle</StepLabel>
                        <StepContent>
                            {isChecking &&
                                <Stack direction='row' alignItems='center' spacing={2}>
                                    <CircularProgress />
                                    <Typography variant='caption'>Check in Progress</Typography>
                                </Stack>
                            }
                            {!isChecking &&
                                <div>
                                    <Alert severity={checkStatus }>
                                        <AlertTitle>Check abgeschlossen</AlertTitle>
                                        Check wurde mit {checkInfoCount} Infos, {checkWarningCount} Warnungen und {checkErrorCount} Fehlern abgeschlossen.
                                    </Alert>
                                    <List>
                                        <ListItemButton onClick={ () => setCheckinfoExpanded( !checkinfoExpanded )}>
                                            <ListItemText>Infos anzeigen</ListItemText>
                                            {checkinfoExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </ListItemButton>
                                    </List>
                                    <Collapse in={ checkinfoExpanded } unmountOnExit>
                                        { checkResult.infos && 
                                            Object.values(checkResult.infos).map((info, index) => (
                                                <Accordion
                                                    key={index}
                                                >
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                        <Typography color={info.level +'.main'}>{info.title}</Typography>                                                        
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                       <Typography variant='caption'>{info.content}</Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                            ))
                                        }
                                    </Collapse>
                                    <Stack spacing={1} direction='row' alignItems='flex-start' mt={2} mb={3}>
                                        <Button variant='contained' disabled={checkResult.check ? false : true} onClick={handleEndCheck}>weiter</Button>
                                        <Button variant='contained' disabled={isChecking} onClick={handleGoBack}>zurück</Button>
                                        <Button variant='contained' disabled={isChecking} onClick={() => handleOnClose(false)}>close</Button>
                                    </Stack>
                                </div>
                            }
                        </StepContent>
                    </Step>
                    <Step>
                        <StepLabel>Ausführung</StepLabel>
                        <StepContent>
                            {isExecuting &&
                                <Stack direction='row' alignItems='center' spacing={2}>
                                    <CircularProgress />
                                    <Typography variant='caption'>Executing ...</Typography>
                                </Stack>
                            }
                        </StepContent>
                    </Step>
                    <Step>
                        <StepLabel>Zusammenfassung</StepLabel>
                        <StepContent>
                            <Alert severity={executeStatus }>
                                <AlertTitle>Wizard abgeschlossen</AlertTitle>
                                Wizard wurde mit {executeInfoCount} Infos, {executeWarningCount} Warnungen und {executeErrorCount} Fehlern abgeschlossen.
                            </Alert>
                            <List>
                                <ListItemButton onClick={ () => setExecuteinfoExpanded( !executeinfoExpanded )}>
                                    <ListItemText>Infos anzeigen</ListItemText>
                                    {executeinfoExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </ListItemButton>
                            </List>
                            <Collapse in={ executeinfoExpanded } unmountOnExit>
                                { executeResult.infos && 
                                    Object.values(executeResult.infos).map((info, index) => (
                                        <Accordion
                                            key={index}
                                        >
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                <Typography color={info.level +'.main'}>{info.title}</Typography>                                                        
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography variant='caption'>{info.content}</Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    ))
                                }
                            </Collapse>
                            <Stack spacing={1} direction='row' alignItems='flex-start' mt={2} mb={3}>
                                <Button variant='contained' disabled={isChecking} onClick={() => handleGotoStep(0)}>zurück</Button>
                                <Button variant='contained' disabled={isChecking} onClick={() => handleOnClose(false)}>close</Button>
                            </Stack>
                        </StepContent>
                    </Step>
                </Stepper>
            </DialogContent>
        </Dialog>
     );
}

WizardExecute.propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    args: PropTypes.array.isRequired,
    values: PropTypes.object.isRequired,
    properties: PropTypes.array.isRequired,
    apikey: PropTypes.string.isRequired,
    wizId: PropTypes.number    
};
 
export default WizardExecute;