import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import RefreshIcon from '@mui/icons-material/Refresh'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { InputAdornment, FormControl, InputLabel, Select, MenuItem, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, IconButton, Paper, Stack, TextField, Typography, Alert, Tab, Tooltip } from '@mui/material'
import { createProduct, deleteProduct, editIsactive, editProduct, fetchProduct, fetchProducts } from '../includes/dbProductsFunc'
import { DataGrid } from "@mui/x-data-grid"
import { AuthContext } from '../components/AuthProvider'
import { useSnackbar } from 'notistack'
import { useContext, useState, useEffect, useRef, useCallback } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { checkSource } from '../includes/dbSourcesFunc'
import ImageDialog from '../components/ImageDialog'
import debounce from "lodash.debounce"
import ProductInput from '../components/ProductInput'
import ProductValues from '../components/ProductValues'
import { TabContext, TabList, TabPanel } from '@mui/lab'


const columns = [
    { field: 'id', headerName: 'id', width: 50 },
    { field: 'productnumber', headerName: 'Nummer', width: 150 },
    { field: 'isactive', headerName: 'Aktiv', width: 70 },
    { field: 'title_source', headerName: 'Titel', width: 200 },
    { field: 'series_source', headerName: 'Baureihe', width: 200 },
    { field: 'optiongroup_name', headerName: 'Options', width: 120 },
    { field: 'wizard_id', headerName: 'Wiz', width: 50 }
];

const Productspage = () => {
    const [rowsCount, setRowsCount] = useState(15)
    const [search, setSearch] = useState('')
    const { id: urlId } = useParams()   //ID aus der URL.
    const { enqueueSnackbar } = useSnackbar()
    const { apikey, isLoggedin } = useContext(AuthContext)
    const { data, isLoading, refetch} = useQuery(['products', rowsCount, search], () => fetchProducts(rowsCount, search))
    const [selectionModel, setSelectionModel] = useState([])
    const [mode, setMode] = useState('')
    const [values, setValues] = useState({})
    const [errors, setErrors] = useState({})
    const [warnings, setWarnings] = useState({})
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [imageDialogOpen, setimageDialogOpen] = useState(false)
    const [imagedialogfor, setImagedialogfor] = useState('')
    const [selectedTab, setSelectedTab] = useState('form')
    const imgRefImage = useRef(null)
    const searchInput = useRef(null)


    useEffect(() => {
        if(urlId){
            //Beim Aufruf der Seite wurde in der URL eine ID angegeben. Wir wollen diese nun zum bearbeiten anzeigen.
            handleEdit( urlId )            
        }
    // eslint-disable-next-line
    },[])


    //Den angegebenen Datensatz im Drawer bearbeiten
    const handleEdit = async ( id ) => {
        const row = await fetchProduct(id)
        if(row && row.status === 1){
            if(row.count === 1){
                setValues(row.results)
                handleOpenDrawer('edit')
            }else{
                enqueueSnackbar('Es existiert kein Produkt mit der Id ' + id, {variant: 'warning'})
            }
        }else{
            enqueueSnackbar('Fehler bei der Abfrage der Datenbank.', {variant: 'error'})
        }
    }


    //Den Drawer öffnen. Als mode entweder create oder edit angeben. Sollte edit gewählt werden, muss der zu beareitende Datensatz in values angegeben werden.
    const handleOpenDrawer= (mode) => {
        if(mode==='create'){
            setValues({productnumber: '', isactive: 1, image_id: null, title_source: '', description_source: '', series_source: '', serieslink_source: '', datasheetlink_source: '', cadlink_source: '', speclink_source: ''})  //Die in den Inputs angezeigten Werte rücksetzen (ein leeres Object führt zu einem Fehler).
            setErrors({})
            setWarnings({})
        }else if(mode === 'edit'){
            //Die values wurden schon bei handleRowClick gesetzt, weil wir da die id kennen.
            setErrors({})
            setWarnings({})
        }else{
            enqueueSnackbar('Unbekannter Modus.', {variant: 'error'})
            return
        }
        setMode(mode)
        setIsDrawerOpen(true)
    }
    

    //Drawer (rechte Seite) schliessen.
    const handleCloseDrawer = () =>{
        setIsDrawerOpen(false)
    }


    //Kontrolliert die Ausgaben und gibt true/false zurück, ausserdem werden die errors-Werte gesetzt, für die einzelenen Eingabefelder.
    const handleCheck = async () => {
        let collectErrors = {}
        let collectWarning = {}

        if(!values.productnumber){
            collectErrors.productnumber = 'Produktnummer eingeben.'
        }
        if(!values.image_id){
            collectErrors.image_id = 'Es muss ein Bild ausgewählt werden.'
        }
        if(!values.title_source){
            collectErrors.title_source = 'Titel eingeben.'
        }
        if(!values.description_source){
            collectErrors.description_source = 'Beschreibung eingeben.'
        }
        if(!values.series_source){
            collectErrors.series_source = 'Baureihe eingeben.'
        }
        if(!values.serieslink_source){
            collectErrors.serieslink_source = 'Baureihen-Link eingeben.'
        }

        if(imgRefImage.current){
            if(imgRefImage.current.naturalHeight > 500 || imgRefImage.current.naturalWidth > 500){
                collectWarning.image_id = `Dimensionen sollten nicht grösser als 500x500px sein! Aktuell: ${imgRefImage.current.naturalWidth}x${imgRefImage.current.naturalHeight}`
            }
        }

        setErrors({
            ...collectErrors
        })
        setWarnings({
            ...collectWarning
        })

        return Object.keys(collectErrors).length === 0
    }


    //Datensatz erstellen, oder einen bestehenden ändern.
    const handleSave = async () => {

        if(mode==='create' || mode==='edit'){
            const collectErrors = []

            //Sources kontrollieren, falls notwendig neu anlegen:
            let result = await checkSource(values.title_source, apikey)
            if(result){ values.title_id = result }else{ collectErrors.push(values.title_source) }
            result = await checkSource(values.description_source, apikey)
            if(result){ values.description_id = result }else{ collectErrors.push(values.description_source) }
            result = await checkSource(values.series_source, apikey)
            if(result){ values.series_id = result }else{ collectErrors.push(values.series_source) }
            result = await checkSource(values.serieslink_source, apikey)
            if(result){ values.serieslink_id = result }else{ collectErrors.push(values.serieslink_source) }
            if(!values.datasheetlink_source){
                values.datasheetlink_id = null
            }else{
                result = await checkSource(values.datasheetlink_source, apikey)
                if(result){ values.datasheetlink_id = result }else{ collectErrors.push(values.datasheetlink_source) }
            }
            if(!values.cadlink_source){
                values.cadlink_id = null
            }else{
                result = await checkSource(values.cadlink_source, apikey)
                if(result){ values.cadlink_id = result }else{ collectErrors.push(values.cadlink_source) }
            }
            if(!values.speclink_source){
                values.speclink_id = null
            }else{
                result = await checkSource(values.speclink_source, apikey)
                if(result){ values.speclink_id = result }else{ collectErrors.push(values.speclink_source) }
            }

            if(collectErrors.lenght > 0){
                collectErrors.forEach(error => {
                    enqueueSnackbar('Source ' + error +  ' konnte nicht erstellt werden', {variant: 'error'})
                });
                return false
            }
        }

        if(mode === 'create'){
            //Neues Produkt erstellen.
            const res = await createProduct(values.productnumber, values.isactive, values.image_id, values.title_id, values.description_id, values.series_id, values.serieslink_id, values.datasheetlink_id, values.cadlink_id, values.speclink_id, values.optiongroup_id, apikey)
            if(res && res.status === 1){
                enqueueSnackbar("Produkt erfolgreich angelegt.", {variant: 'success'})
                refetch()
                await handleEdit(res.id)                
            }else{
                enqueueSnackbar(res.message, {variant: 'error'})
            }
        }else if(mode === 'edit'){
            //Property ändern.
            const res = await editProduct(values.id, values.productnumber, values.isactive, values.image_id, values.title_id, values.description_id, values.series_id, values.serieslink_id, values.datasheetlink_id, values.cadlink_id, values.speclink_id, values.optiongroup_id, apikey)
            if(res && res.status === 1){
                enqueueSnackbar("Produkt erfolgreich geändert.", {variant: 'success'})
                handleCloseDrawer()
                refetch()
            }else{
                enqueueSnackbar(res.message, {variant: 'error'})
            }
        }else{
            enqueueSnackbar('Unbekannter Modus.', {variant: 'error'})
        }
    }


    //Einen Datensatz löschen
    const handleDelete = async (id) => {
        const result = await deleteProduct(id, apikey)
        if(result.status === 0){
            enqueueSnackbar(result.message, {variant: "error"})
            return
        }else{
            enqueueSnackbar("Product erfolgreich gelöscht.", {variant: "success"})
            handleCloseDrawer()
            refetch()
            return
        }   
    }


    //Alle ausgewählten Zeilen löschen
    const handleDeleteChecked = async () => {
        //selectionModel enthällt eine Liste der id mit allen selectierten Zeilen. Wieso id als Standard verwendet wird, keine Ahnung!
        for(const id of selectionModel){
            const result = await deleteProduct(id, apikey)
            if(result.status === 0){
                enqueueSnackbar(result.message, {variant: "error"})
            }
        }
        refetch()
    }


    /**
     * Alle selektierten Produkte den isactive-Wert setzten auf true oder false.
     */
    const handleSetActive = async ( status ) => {
        //selectionModel enthällt eine Liste der id mit allen selectierten Zeilen. Wieso id als Standard verwendet wird, keine Ahnung!
        let err  = false
        for(const id of selectionModel){
            const result = await editIsactive(id, status, apikey)
            if(result.status === 0){
                enqueueSnackbar("", {variant: "error"})
                err = true
            }
        }
        refetch()
        if(err === false){
            enqueueSnackbar("Status erfolgreich geändert.", {variant: "success"})
        }
    }


    //Ein ImageDialog zur Auswahl eines Logos wurde geschlossen. Als Result wird entweder eine Object eines Bildes (file) oder null zurückgegeben.
    const handleImageDialogClose = ( result ) => {
        if(result){
            switch (imagedialogfor) {
                case 'image':
                    setValues(prev =>({
                        ...prev,
                        image_id: result.id,
                        image_path: result.path,
                        image_alt: result.alt,
                        image_name: result.name,
                        image_description: result.description,
                    }))
                    break;
                default:
            }
        }
        setImagedialogfor('')
        setimageDialogOpen(false)
    }


    //Die Auswahl in der Tabelle aufgrund des Suchtextes einschränken.
    const handleSearch = ( text ) => {
        searchInput.current.value = text
        debounceSearch(text)
    }


    //Filtert die Tabelle aufgrund des Suchkriteriums, aber erst nach 500ms. Solange wird auf weitere Eingaben gewartet. Reduziert die Anzahl Anfragen.
    // eslint-disable-next-line
    const debounceSearch = useCallback(
        debounce( (value) => {
            setSearch( value )
        }, 500)
    ,[])



    return ( 
        <Container>
            <Typography variant="h1">
                Products
            </Typography>
            { isLoggedin() &&
                <div>
                    <Stack spacing={2} className="data">
                        <Stack direction='row' spacing={1}  justifyContent="flex-start" alignItems="baseline" className="data-table-search">
                            <TextField
                                variant='outlined'
                                label='Suche'
                                fullWidth
                                inputRef={ searchInput }
                                onChange={ (e) => debounceSearch(e.target.value) }
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton onClick={() => handleSearch('')}>
                                                <CloseIcon fontSize='small'/>
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <FormControl sx={{ width: 200 }}>
                                <InputLabel>Rows</InputLabel>
                                <Select
                                    label="Rows"
                                    value={rowsCount}
                                    onChange={(e) => setRowsCount(e.target.value)}
                                >
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={15}>15</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={30}>30</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                    <MenuItem value={100}>100</MenuItem>
                                    <MenuItem value={0}>Alle</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                        <Stack direction='row' spacing={1}  justifyContent="flex-start" alignItems="baseline">
                            <IconButton color="primary" onClick={() => handleOpenDrawer('create')}>
                                <AddIcon />
                            </IconButton>
                            <Tooltip title="Produkte inaktiv setzten">
                                <IconButton color="primary" onClick={() => handleSetActive(false)} disabled={selectionModel.length === 0}>
                                    <RadioButtonUncheckedIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Produkte aktiv setzten">
                                <IconButton color="primary" onClick={() => handleSetActive(true)} disabled={selectionModel.length === 0}>
                                    <RadioButtonCheckedIcon />
                                </IconButton>
                            </Tooltip>
                            <IconButton color="warning" onClick={() => setIsDialogOpen(true)} disabled={selectionModel.length === 0}>
                                <DeleteIcon />
                            </IconButton>
                            <IconButton color="primary" onClick={refetch}>
                                <RefreshIcon />
                            </IconButton>
                            { isLoading && <HourglassBottomIcon color='warning' />}
                        </Stack>
                        <div className="data-table" style={{ height: 650, width: '100%' }}>
                            <DataGrid
                                density="compact"
                                rows={data ? data.results : []}
                                columns={columns}
                                disableSelectionOnClick
                                checkboxSelection
                                pageSize={15}
                                rowsPerPageOptions={[15]}                    
                                disableColumnFilter
                                onRowClick={(e) => handleEdit(e.id)}
                                onSelectionModelChange={(newModel) => setSelectionModel(newModel)}
                                selectionModel={selectionModel}
                            />                            
                        </div>
                    </Stack>
                    <Paper sx={{p: 3}}>
                        <Typography variant='h4'>Infos</Typography>
                        <Typography>
                        </Typography>
                    </Paper>
                </div>
            }
            { !isLoggedin() && <Typography variant='h3'>Keine Berechtigung</Typography> }
            <Drawer className='drawer'
                PaperProps={{ sx: {width: { xs: 1, sm: 0.4 }} }}
                anchor="right"
                open={isDrawerOpen}
                onClose={handleCloseDrawer}
            >
                <div className="drawer-content">
                    <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="baseline">
                        <IconButton onClick={handleCloseDrawer}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <Typography variant='h5' gutterBottom color="primary">
                        { mode === "edit" && 'Produkt ändern...'}
                        { mode === "create" && 'Neues Produkt anlegen...'}
                    </Typography>

                    <TabContext value={ selectedTab }>
                        <TabList onChange={(e,n) => setSelectedTab(n)}>
                            <Tab value='form' label='Eingabe'/>
                            <Tab value='values' disabled={ mode === 'create' } label='Values'/>
                        </TabList>
                        <TabPanel value='form'>
                            <ProductInput
                                mode={mode}
                                values={values}
                                errors={errors}
                                warnings={warnings}
                                imgRefImage={imgRefImage}
                                setValues={setValues}
                                setWarnings={setWarnings}
                                setImagedialogfor={setImagedialogfor}
                                setimageDialogOpen={setimageDialogOpen}
                                handleCloseDrawer={handleCloseDrawer}
                                handleCheck={handleCheck}
                                handleSave={handleSave}
                                handleDelete={handleDelete}                    
                            />
                        </TabPanel>
                        <TabPanel value='values'>
                            <ProductValues product_id={values.id} apikey={apikey} />
                        </TabPanel>
                    </TabContext>
                </div>
            </Drawer>
            <Dialog open={isDialogOpen}>
                <DialogTitle>Löschen</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Wollen Sie die ausgewählten Produkte wirklich löschen?
                    </DialogContentText>
                    <Alert severity="warning">
                        Alle Values dieses Produkt werden ebenfalls gelöscht.
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color='warning'
                        onClick={() => {
                            handleDeleteChecked()
                            setIsDialogOpen(false)
                        }}
                    >Ok</Button>
                    <Button onClick={() => setIsDialogOpen(false)}>Abbruch</Button>
                </DialogActions>
            </Dialog>
            <ImageDialog
                keepMounted
                open={ imageDialogOpen }
                onClose={(e) => handleImageDialogClose(e)}
            />
        </Container>
     );
}

export default Productspage;

