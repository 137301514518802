import { coreFetch } from './dbCoreFunc'


//Alle Wizards abfragen
//limit: nur die ersten n-Datensätze abfragen. Wenn nicht angegeben oder 0 werde alle Datensätze geliefert.
//search: Wird hier ein Wert angegeben, werden alle sinnvollen Spalten nach diesem Wert durchsucht. Ansonsten Null angeben.  
//return: ein Object, mit status, message, und dem Wizard als results
export const fetchWizards = async ( limit, search ) => {
    const params = new URLSearchParams()
    if(limit && limit>0){ params.append('limit', limit) }
    if(search){ params.append('search', "%" + search + "%") }
    const paramsStr = '?' + params.toString()
    const uri = process.env.REACT_APP_API_PATH + '/wizards/' + (paramsStr !== '?' ? paramsStr : '')

    const headers = {
        "Content-Type": "application/json"
    }

    return await coreFetch(uri, 'GET', headers)
}


//Alle gelöschten Wizards abfragen
//return: ein Object, mit status, message, und den Wizards als results
export const fetchDeletedWizards = async ( ) => {
    var uri = process.env.REACT_APP_API_PATH + '/wizards/?deleted';

    const headers = {
        "Content-Type": "application/json",
    }

    return await coreFetch(uri, 'GET', headers)
}


//Ein Wizard abfragen mit einer id
//id: id des Wizard die abgefragt werden soll.
//return: ein Object, mit status, message, und dem Wizard als results
export const fetchWizard = async ( id ) => {
    var uri = process.env.REACT_APP_API_PATH + '/wizards/' + id;

    const headers = {
        "Content-Type": "application/json",
    }

    return await coreFetch(uri, 'GET', headers)
}


//Ein neuer Wizard erstellen.
//return: Es wird eine Objekt mit status und message zurückgegeben.
export async function createWizard(name, args, values, properties, apikey){

    const payload = {
        name: name,
        values: values,
        arguments: args,
        properties: properties
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(process.env.REACT_APP_API_PATH + '/wizards/', 'POST', headers, JSON.stringify(payload))
}


//Ein Wizard ändern.
//id:   id des Wizard das geändert werden soll
//new{x}: die neuen Werte des Wizard
//apikey: ein gültiger APIKEY, ansonsten kann der PUT-Request nicht durchgeführt werden.
//Es wird eine Objekt mit status und message zurückgegeben.
export async function editWizard(id, name, args, values, properties, apikey){
    const payload = {
        name: name,
        values: values,
        arguments: args,
        properties: properties     
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(process.env.REACT_APP_API_PATH + '/wizards/' + id, 'PUT', headers, JSON.stringify(payload))
}


//Ein Wizard löschen
//Es wird eine Objekt mit status und message zurückgegeben.
export async function deleteWizard(id, apikey){
    const payload = {
        deleted: true
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(process.env.REACT_APP_API_PATH + '/wizards/' + id, 'PUT', headers, JSON.stringify(payload))
}


//Ein Wizard endgültig löschen
//Es wird eine Objekt mit status und message zurückgegeben.
export async function deleteFinalyWizard(id, apikey){
    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(process.env.REACT_APP_API_PATH + '/wizards/' + id, 'DELETE', headers)
}


//Ein gelöschter Wizard wieder herstellen
//Es wird eine Objekt mit status und message zurückgegeben.
export async function restoreWizard(id, apikey){
    const payload = {
        deleted: false
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(process.env.REACT_APP_API_PATH + '/wizards/' + id, 'PUT', headers, JSON.stringify(payload))
}



/**
 * Kontrolliert ob ein Wiz mit den values, args, props durchgeführt werden kann.
 *  
 * @return {object} Eine Object mit Status bezüglich des Fetch. Als .Results wird ein weiteres Object mit dem Status des Check und einem Array von Infos zurückgegeben.
 */ 
export async function checkWizard(wizId, args, values, properties, settings, apikey){
    const payload = {
        wizid: wizId ? wizId : null,
        values: values,
        arguments: args,
        properties: properties,
        settings: settings
    }

    const headers = {
        "Content-Type": "application/json",
        "Authorization": apikey
    }
    
    return await coreFetch(process.env.REACT_APP_API_PATH + '/wizards/?check', 'POST', headers, JSON.stringify(payload))
}



/**
 * Einen Wiz durchführen
 *  
 * @return {object} Eine Object mit Status bezüglich des Fetch. Als .Results wird ein weiteres Object mit dem Status des Check und einem Array von Infos zurückgegeben.
 */ 
 export async function executeWizard(wizId, args, values, properties, settings, apikey){
    const payload = {
        wizid: wizId ? wizId : null,
        values: values,
        arguments: args,
        properties: properties,
        settings: settings
    }

    const headers = {
        "Content-Type": "application/json",
        "Authorization": apikey
    }
    
    return await coreFetch(process.env.REACT_APP_API_PATH + '/wizards/?execute', 'POST', headers, JSON.stringify(payload))
}