import AddIcon from '@mui/icons-material/Add'
import RefreshIcon from '@mui/icons-material/Refresh'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import { Avatar, Autocomplete, TextField, Stack, Typography, Card, CardHeader, IconButton, Box, CardContent } from "@mui/material";
import { useState } from 'react';
import { useEffect } from "react";
import { useQuery } from "react-query";
import { createOption, deleteOption, editOption, fetchOptionsFromGroup } from '../includes/dbOptionsFunc'
import { fetchLanguages } from '../includes/dbLanguagesFunc'
import { useSnackbar } from 'notistack'
import OptionControl from './OptionControl'
import { checkOption } from "../includes/optionFunc";



const OptionsgroupOptions = ( { group_id, apikey } ) => {
    const { data, refetch} = useQuery(['values', group_id], () => fetchOptionsFromGroup(group_id))
    const [languages, setLanguages] = useState([])
    const [mode, setMode] = useState('')
    const [values, setValues] = useState(null)
    const [errors, setErrors] = useState({})
    const [selectedLanguage, setSelectedLanguage] = useState(null)
    const { enqueueSnackbar } = useSnackbar()

   
    useEffect(() =>{
        (async () => {
            const answer = await fetchLanguages()
            setLanguages(answer.results)
        })();
    },[])


    const handleNew = () => {
        setMode('create')
        setSelectedLanguage(null)
        setValues({group_id: group_id, language_id: null, label: '', options: ''})  //Die in den Inputs angezeigten Werte rücksetzen (ein leeres Object führt zu einem Fehler).
        setErrors({})
    }


    const handleEdit = ( id ) => {
        const option = data.results.filter(v => v.id === id)[0]
        const language = languages.filter(v => v.id === option.language_id)[0]
        
        setMode('edit')
        setSelectedLanguage(language)
        setValues(option)
        setErrors({})
    }


    const handleCheck = () => {
        //Werte überprüfen:
        let collectErrors = {}

        if(!selectedLanguage){
            collectErrors.property_id = 'Sprache auswählen.'
        }

        if(!values.label){
            collectErrors.label = 'Label eingeben.'
        }

        if(!values.options){
            collectErrors.options = 'Option konfigurieren.'
        }else{
            const answer = checkOption(values.options)
            if(answer !== true){
                collectErrors.options = answer
            }
        }

        setErrors({
            ...collectErrors
        })

        return Object.keys(collectErrors).length === 0
    }


    const handleCheckOption = ( options ) => {
        const answer = checkOption(options)

        if (answer === true){
            setErrors(prev =>({
                ...prev,
                options: null
            }))

        }else{
            setErrors(prev =>({
                ...prev,
                options: answer
            }))
        }
    }


    const handleSave = async () => {
        if(mode === 'create'){
            //Neue Option erstellen.
            const res = await createOption(values.group_id, selectedLanguage.id, values.label, values.options, apikey)
            if(res && res.status === 1){
                enqueueSnackbar("Option erfolgreich angelegt.", {variant: 'success'})
                refetch()
                handleCancelEdit()
            }else{
                enqueueSnackbar(res.message, {variant: 'error'})
            }
        }else if(mode === 'edit'){
            //Value ändern.
            const res = await  editOption(values.id, values.group_id, selectedLanguage.id, values.label, values.options, apikey)
            if(res && res.status === 1){
                enqueueSnackbar("Option erfolgreich geändert.", {variant: 'success'})
                refetch()
                handleCancelEdit()
            }else{
                enqueueSnackbar(res.message, {variant: 'error'})
            }
        }else{
            enqueueSnackbar('Unbekannter Modus.', {variant: 'error'})
        }
      
    }

    
    const handleCancelEdit = () => {
        setMode('')
        setSelectedLanguage(null)
        setValues(null)
        setErrors({})
    }


    const handleDelete = async ( id ) => {
        const result = await deleteOption(id, apikey)
        if(result.status === 0){
            enqueueSnackbar(result.message, {variant: "error"})
        }else{
            enqueueSnackbar("Option erfolgreich gelöscht.", {variant: "success"})
            refetch()
        }  
    }




    return (
        <Box>
            <Stack direction='row' spacing={2} mb={2}>
                <IconButton onClick={() => {handleNew()}}><AddIcon color='primary' fontSize='small' /></IconButton>
                <IconButton onClick={() => {refetch()}}><RefreshIcon color='primary' fontSize='small' /></IconButton>
            </Stack>
            { mode === 'create' && 
                <Card sx={{mb: 5}}>
                    <CardHeader
                        title={<Typography variant='h4' color='primary'>Neue Option</Typography>}
                        action={
                            <>
                            <IconButton
                                onClick={() => {
                                    const check = handleCheck()
                                    check && handleSave()
                                }}
                            ><CheckCircleOutlineIcon color='success' fontSize='small' /></IconButton>
                            <IconButton
                                onClick={() => {
                                    handleCancelEdit()
                                }}
                            ><CancelOutlinedIcon fontSize='small' /></IconButton>
                            </>
                        }
                    />
                    <CardContent>
                        <Stack spacing={1} sx={{pb: 3}}>
                            <Autocomplete
                                fullWidth
                                value={ selectedLanguage ? selectedLanguage : null }
                                options={ languages }
                                getOptionLabel={(option) => option.name ?? option}
                                onChange={(e, language) => {
                                    setSelectedLanguage( language )
                                }}
                                renderInput={(params) => <TextField
                                                            {...params}
                                                            variant='standard'
                                                            label="Sprache"
                                                            required
                                                            helperText={ errors.language_id }
                                                            error={ errors.language_id ? true : false }
                                                        />}
                            />
                            <TextField
                                variant='standard'
                                label='Label'
                                fullWidth
                                helperText={ errors.label }
                                error= { errors.label ? true : false  }
                                value={ values.label ? values.label : '' }
                                onChange={(e) => {
                                    setValues(prev =>({
                                        ...prev,
                                        label: e.target.value
                                    }))
                                }}
                            />
                            <TextField
                                variant='standard'
                                label='Options'
                                fullWidth
                                helperText={ errors.options }
                                error= { errors.options ? true : false  }
                                value={ values.options ? values.options : '' }
                                onChange={(e) => {
                                    handleCheckOption(e.target.value)
                                    setValues(prev =>({
                                        ...prev,
                                        options: e.target.value
                                    }))
                                }}
                            />

                        </Stack>                        
                    </CardContent>
                </Card>
            }

            { data && data.results && data.results.length > 0 ? (
                <Stack spacing={1}>
                    {data.results.map(value =>
                        <Card key={value.id}>
                            <CardHeader
                                avatar={
                                    <Avatar>
                                        {value.language_symbol}
                                    </Avatar>
                                }                            
                                title={
                                    <Typography variant='h6' color='primary'>{value.label}</Typography>
                                }
                                action={ <Stack direction='row'>
                                    {mode === 'edit' && values && values.id === value.id ? (
                                        <>
                                        <IconButton
                                            onClick={() => {
                                                const check = handleCheck()
                                                check && handleSave()
                                            }}
                                        ><CheckCircleOutlineIcon color='success' fontSize='small' /></IconButton>
                                        <IconButton
                                            onClick={() => {
                                                handleCancelEdit()
                                            }}
                                        ><CancelOutlinedIcon fontSize='small' /></IconButton>
                                        </>
                                    ) : (
                                        <IconButton onClick={() => {handleEdit(value.id)}}><EditIcon fontSize='small' /></IconButton>
                                    )}
                                    <IconButton
                                        color='warning'
                                        onClick={async () => handleDelete(value.id)}
                                    ><DeleteIcon fontSize='small'/></IconButton>
                                </Stack> }
                            />
                            <CardContent sx={{pt: 0}}>
                                {mode === 'edit' && values && values.id === value.id ? (
                                    <Stack spacing={1} sx={{pb: 3}}>
                                        <Autocomplete
                                            fullWidth
                                            value={ selectedLanguage ? selectedLanguage : null }
                                            options={ languages }
                                            getOptionLabel={(option) => option.name ?? option}
                                            onChange={(e, language) => {
                                                setSelectedLanguage( language )
                                            }}
                                            renderInput={(params) => <TextField
                                                                        {...params}
                                                                        variant='standard'
                                                                        label="Language"
                                                                        required
                                                                        helperText={ errors.language_id }
                                                                        error={ errors.language_id ? true : false }
                                                                    />}
                                        />
                                        <TextField
                                            variant='standard'
                                            label='Label'
                                            fullWidth
                                            helperText={ errors.label }
                                            error= { errors.label ? true : false  }
                                            value={ values.label ? values.label : '' }
                                            onChange={(e) => {
                                                setValues(prev =>({
                                                    ...prev,
                                                    label: e.target.value
                                                }))
                                            }}
                                        />
                                        <TextField
                                            variant='standard'
                                            label='Options'
                                            fullWidth
                                            helperText={ errors.options }
                                            error= { errors.options ? true : false  }
                                            value={ values.options ? values.options : '' }
                                            onChange={(e) => {
                                                handleCheckOption(e.target.value)
                                                setValues(prev =>({
                                                    ...prev,
                                                    options: e.target.value
                                                }))
                                            }}
                                        />
                                    </Stack>
                                ) : (
                                    <>
                                    <Typography mb={2} variant='body2' color='gray'>{value.options}</Typography>
                                    <OptionControl option={value.options} label={value.label}/>
                                    </>
                                )}
                            </CardContent>
                        </Card>
                    )}
                </Stack>                
            ) : (
                <Typography>Noch keine Daten</Typography>
            )}
        </Box>
     );
}
 
export default OptionsgroupOptions;