import { TextField } from "@mui/material";
import { useState } from "react";


const WizardInputField = ( { value , onChange, fieldWidth, focusFieldWidth} ) => {
    const [hasFocus, setHasFocus] = useState(false)

    return(
        <TextField
            autoComplete='off'
            variant='standard'
            size='small'
            margin='none'
            value={ value }
            onChange={ onChange }
            onFocus={() => setHasFocus(true)}
            onBlur={() => setHasFocus(false)}            
            sx={{width: (hasFocus ? focusFieldWidth : fieldWidth) + 'px', mr: 1}}
        />
    )
}

export default WizardInputField;
