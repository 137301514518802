import { Stack, Typography } from '@mui/material'
import WizardLabel from '../components/WizardLabel'
import WizardInputField from '../components/WizardInputField'


const WizardValues = ( {values, setValues, fieldWidth, focusFieldWidth, languages, labelWidth} ) => {
    return ( 
        <Stack direction='column' spacing={3} alignItems='flex-start'>
            <div style={{whiteSpace: 'nowrap'}}>
                <WizardLabel labelWidth={labelWidth} component='span'/>
                <Typography component='span' color='primary' mb={2} sx={{width: fieldWidth, display: 'inline-block'}}>Field</Typography>
                {languages && 
                    languages.map((language, index) => (
                        !language.system && <Typography key={index} component='span' color='primary' mb={2} sx={{width: fieldWidth, display: 'inline-block'}}>{language.symbol}</Typography>
                    ))
                }
            </div>
            <div style={{whiteSpace: 'nowrap'}}>
                <WizardLabel labelWidth={labelWidth}>Aktiv *</WizardLabel>
                <WizardInputField
                    fieldWidth={fieldWidth}
                    focusFieldWidth={focusFieldWidth}
                    value={ values.isactive ? values.isactive: ''}
                    onChange={(e) => setValues(old => ({...old, isactive: e.target.value}))}
                />
            </div>            
            <div style={{whiteSpace: 'nowrap'}}>
                <WizardLabel labelWidth={labelWidth}>Bild id *</WizardLabel>
                <WizardInputField
                    fieldWidth={fieldWidth}
                    focusFieldWidth={focusFieldWidth}
                    value={ values.image_id ? values.image_id: ''}
                    onChange={(e) => setValues(old => ({...old, image_id: e.target.value}))}
                />
            </div>
            <div style={{whiteSpace: 'nowrap'}}>
                <WizardLabel labelWidth={labelWidth}>Nummer *</WizardLabel>
                <WizardInputField
                    fieldWidth={fieldWidth}
                    focusFieldWidth={focusFieldWidth}
                    value={ values.productnumber ? values.productnumber: ''}
                    onChange={(e) => setValues(old => ({...old, productnumber: e.target.value}))}
                />
            </div>
            <div style={{whiteSpace: 'nowrap'}}>
                <WizardLabel labelWidth={labelWidth}>Titel *</WizardLabel>
                <WizardInputField
                    fieldWidth={fieldWidth}
                    focusFieldWidth={focusFieldWidth}
                    value={ values.title ? values.title: ''}
                    onChange={(e) => setValues(old => ({...old, title: e.target.value}))}
                />
                {languages && 
                    languages.map((language, index) => (
                        !language.system &&
                        <WizardInputField
                            fieldWidth={fieldWidth}
                            focusFieldWidth={focusFieldWidth}
                            key={index}
                            value={ values['title_' + language.symbol] ? values['title_' + language.symbol]: ''}
                            onChange={(e) => setValues(old => ({...old, ['title_' + language.symbol]: e.target.value}))}       
                        />
                    ))
                }
            </div>
            <div style={{whiteSpace: 'nowrap'}}>
                <WizardLabel labelWidth={labelWidth}>Beschreibung *</WizardLabel>
                <WizardInputField
                    fieldWidth={fieldWidth}
                    focusFieldWidth={focusFieldWidth}
                    value={ values.description ? values.description: ''}
                    onChange={(e) => setValues(old => ({...old, description: e.target.value}))}
                />
                {languages && 
                    languages.map((language, index) => (
                        !language.system &&
                        <WizardInputField
                            fieldWidth={fieldWidth}
                            focusFieldWidth={focusFieldWidth}
                            key={index}
                            value={ values['description_' + language.symbol] ? values['description_' + language.symbol]: ''}
                            onChange={(e) => setValues(old => ({...old, ['description_' + language.symbol]: e.target.value}))}       
                        />
                    ))
                }
            </div>
            <div style={{whiteSpace: 'nowrap'}}>
                <WizardLabel labelWidth={labelWidth}>Baureihe *</WizardLabel>
                <WizardInputField
                    fieldWidth={fieldWidth}
                    focusFieldWidth={focusFieldWidth}
                    value={ values.series ? values.series: ''}
                    onChange={(e) => setValues(old => ({...old, series: e.target.value}))}
                />
                {languages && 
                    languages.map((language, index) => (
                        !language.system &&
                        <WizardInputField
                            fieldWidth={fieldWidth}
                            focusFieldWidth={focusFieldWidth}
                            key={index}
                            value={ values['series_' + language.symbol] ? values['series_' + language.symbol]: ''}
                            onChange={(e) => setValues(old => ({...old, ['series_' + language.symbol]: e.target.value}))}       
                        />
                    ))
                }
            </div>
            <div style={{whiteSpace: 'nowrap'}}>
                <WizardLabel labelWidth={labelWidth}>Baureihe Link *</WizardLabel>
                <WizardInputField
                    fieldWidth={fieldWidth}
                    focusFieldWidth={focusFieldWidth}
                    value={ values.serieslink ? values.serieslink: ''}
                    onChange={(e) => setValues(old => ({...old, serieslink: e.target.value}))}
                />
                {languages && 
                    languages.map((language, index) => (
                        !language.system &&
                        <WizardInputField
                            fieldWidth={fieldWidth}
                            focusFieldWidth={focusFieldWidth}
                            key={index}
                            value={ values['serieslink_' + language.symbol] ? values['serieslink_' + language.symbol]: ''}
                            onChange={(e) => setValues(old => ({...old, ['serieslink_' + language.symbol]: e.target.value}))}       
                        />
                    ))
                }
            </div>
            <div style={{whiteSpace: 'nowrap'}}>
                <WizardLabel labelWidth={labelWidth}>Datenblatt Link</WizardLabel>
                <WizardInputField
                    fieldWidth={fieldWidth}
                    focusFieldWidth={focusFieldWidth}
                    value={ values.datasheetlink ? values.datasheetlink: ''}
                    onChange={(e) => setValues(old => ({...old, datasheetlink: e.target.value}))}
                />
                {languages && 
                    languages.map((language, index) => (
                        !language.system &&
                        <WizardInputField
                            fieldWidth={fieldWidth}
                            focusFieldWidth={focusFieldWidth}
                            key={index}
                            value={ values['datasheetlink_' + language.symbol] ? values['datasheetlink_' + language.symbol]: ''}
                            onChange={(e) => setValues(old => ({...old, ['datasheetlink_' + language.symbol]: e.target.value}))}       
                        />
                    ))
                }
            </div>
            <div style={{whiteSpace: 'nowrap'}}>
                <WizardLabel labelWidth={labelWidth}>Spezifikations Link</WizardLabel>
                <WizardInputField
                    fieldWidth={fieldWidth}
                    focusFieldWidth={focusFieldWidth}
                    value={ values.speclink ? values.speclink: ''}
                    onChange={(e) => setValues(old => ({...old, speclink: e.target.value}))}
                />
                {languages && 
                    languages.map((language, index) => (
                        !language.system &&
                        <WizardInputField
                            fieldWidth={fieldWidth}
                            focusFieldWidth={focusFieldWidth}
                            key={index}
                            value={ values['speclink_' + language.symbol] ? values['speclink_' + language.symbol]: ''}
                            onChange={(e) => setValues(old => ({...old, ['speclink_' + language.symbol]: e.target.value}))}       
                        />
                    ))
                }
            </div>
            <div style={{whiteSpace: 'nowrap'}}>
                <WizardLabel labelWidth={labelWidth}>CAD Link</WizardLabel>
                <WizardInputField
                    fieldWidth={fieldWidth}
                    focusFieldWidth={focusFieldWidth}
                    value={ values.cadlink ? values.cadlink: ''}
                    onChange={(e) => setValues(old => ({...old, cadlink: e.target.value}))}
                />
                {languages && 
                    languages.map((language, index) => (
                        !language.system &&
                        <WizardInputField
                            fieldWidth={fieldWidth}
                            focusFieldWidth={focusFieldWidth}
                            key={index}
                            value={ values['cadlink_' + language.symbol] ? values['cadlink_' + language.symbol]: ''}
                            onChange={(e) => setValues(old => ({...old, ['cadlink_' + language.symbol]: e.target.value}))}       
                        />
                    ))
                }
            </div>
            <div style={{whiteSpace: 'nowrap'}}>
                <WizardLabel labelWidth={labelWidth}>Option id</WizardLabel>
                <WizardInputField
                    fieldWidth={fieldWidth}
                    focusFieldWidth={focusFieldWidth}
                    value={ values.optiongroup_id ? values.optiongroup_id: ''}
                    onChange={(e) => setValues(old => ({...old, optiongroup_id: e.target.value}))}
                />
            </div>

        </Stack>
     );
}
 
export default WizardValues;