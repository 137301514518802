import { coreFetch } from './dbCoreFunc'


//Alle sources abfragen
//limit: nur die ersten n-Datensätze abfragen. Wenn nicht angegeben oder 0 werde alle Datensätze geliefert.
//search: Wird hier ein Wert angegeben, werden alle sinnvollen Spalten nach diesem Wert durchsucht. Ansonsten Null angeben.  
//return: ein Object, mit status, message, und der source als results
export const fetchSources = async ( limit, search ) => {
    const params = new URLSearchParams({
        lang: '',
    })
    if(limit && limit>0){ params.append('limit', limit) }
    if(search){ params.append('search', "%" + search + "%") }
    const paramsStr = '?' + params.toString()
    const uri = process.env.REACT_APP_API_PATH + '/sources/' + (paramsStr !== '?' ? paramsStr : '')
    const headers = {
        "Content-Type": "application/json"
    }
    return await coreFetch(uri, 'GET', headers)
}


//Eine Source abfragen mit einer id
//id: id der source die abgefragt werden soll.
//return: ein Object, mit status, message, und der source als results
export const fetchSource = async ( id ) => {
    var uri = process.env.REACT_APP_API_PATH + '/sources/' + id;

    const headers = {
        "Content-Type": "application/json",
    }

    return await coreFetch(uri, 'GET', headers)
}


export const fetchSourceBySource = async ( source ) => {
    const params = new URLSearchParams({
        source: source,
    })
    const paramsStr = '?' + params.toString()

    var uri = process.env.REACT_APP_API_PATH + '/sources/' + paramsStr

    const headers = {
        "Content-Type": "application/json",
    }

    return await coreFetch(uri, 'GET', headers)
}




//Eine neue Source erstellen.
//source: String des neuen Sources
//apikey: ein gültiger APIKEY, ansonsten kann der PUT-Request nicht durchgeführt werden.
//return: Es wird eine Objekt mit status und message zurückgegeben.
export async function createSource(source, key, notranslations, apikey){
    const payload = {
        source: source,
        notranslations: notranslations,
        key: key
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(process.env.REACT_APP_API_PATH + '/sources/', 'POST', headers, JSON.stringify(payload))
}


//Eine source ändern.
//id:   id der source die geändert werden soll
//new{x}: die neuen Werte der source
//apikey: ein gültiger APIKEY, ansonsten kann der PUT-Request nicht durchgeführt werden.
//Es wird eine Objekt mit status und message zurückgegeben.
export async function editSource(id, source, key, notranslations, apikey){
    const payload = {
        source: source,
        notranslations: notranslations,
        key: key
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(process.env.REACT_APP_API_PATH + '/sources/' + id, 'PUT', headers, JSON.stringify(payload))
}


/**
 * Eine Source löschen.
 * @param {integer} id Id der Source die gelöscht wird
 * @param {string} apikey Der API-Key für den Zugriff auf die <API></API>
 * @param {boolan} protectKeys Wenn dieser Wert true, dann werden Sources die ein Key-Eintrag haben NICHT gelöscht.
 * @returns {object} Status (1: Erfolg, 0: Fehler) und eine Message falls der Status nicht 1 ist
 */
export async function deleteSource(id, apikey, protectKeys){
    const payload = {
        protectKeys: protectKeys
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(process.env.REACT_APP_API_PATH + '/sources/' + id, 'DELETE', headers, JSON.stringify(payload))
}


//Es wird nach der angegebenen Source gesucht, und dann ein Statuswert zurückgegeben.
//source: Die Source die gesucht wird (String)
//return: ein Object, mit status (von der Datenbankabfrage!! nicht mit results verwechseln), message, und als results den Status wie folgend beschrieben!
//        Als results wird folgender Status-Wert zurückgegeben: Wenn die source nicht existiert, dann 1. Wenn die source existiert, aber nicht alle übersetzungen hat, dann 2 und wenn alle übersetzungen vorhanden sind dann 3.
export async function sourceStatus(source){
    const params = new URLSearchParams({
        status: '',
        source: source
    })
    const paramsStr = '?' + params.toString()
    const uri = process.env.REACT_APP_API_PATH + '/sources/' + (paramsStr !== '?' ? paramsStr : '')
    const headers = {
        "Content-Type": "application/json"
    }

    return await coreFetch(uri, 'GET', headers)
}

//Es wird nach einem Source mit dem gewünschten Key gesucht.
//Danach wird die Übersetzung der angegebenen Sprache gesucht.
//Existiert eine Source mit dem Key, und eine Translation wird diese Translation zurückgegeben.
//Existiert die Source, aber keine Translation, wird die Source zurückgegeben.
//Existiert auch keine Source mit diesem Key, wird der übergebene Key ausgegeben.
export async function getTranslationByKey(key, language_id){
    const params = new URLSearchParams({
        key: key,
        lang: language_id
    })
    const paramsStr = '?' + params.toString()
    const uri = process.env.REACT_APP_API_PATH + '/sources/' + paramsStr
    const headers = {
        "Content-Type": "application/json"
    }

    const answer = await coreFetch(uri, 'GET', headers)
    if(answer.status === 1 && answer.count > 0){
        return [key, answer.translation]
    }else{
        return [key, key]
    }

}


//Gibt die Translation einer Source mit dem angegebenen Keys (als Array) in der angegebenen Sprache zurück.
//Ein Object, jeder KEY wird zum Property, und jede Translation ist dann der Wert dieser Property
export async function getTranslationsByKey(keys, language_id){
    const translations = keys.map((key) => getTranslationByKey(key, language_id)) 
    const translation_object = await Promise.all(
            translations
        ).then(function(data){
            const labels = {}
            data.forEach(translation => {
                labels[translation[0].toLowerCase()] = translation[1]
            });
            return labels
        })
    return translation_object
}


//Uebersetzung aller Sources die einen Key-Wert haben holen
//Ein Object, jeder KEY wird zum Property, und jede Translation ist dann der Wert dieser Property
export async function getAllKeyTranslations(language_id){
    const params = new URLSearchParams({
        keys: '',
        lang: language_id
    })
    const paramsStr = '?' + params.toString()
    const uri = process.env.REACT_APP_API_PATH + '/sources/' +  paramsStr
    const headers = {
        "Content-Type": "application/json"
    }

    const result = await coreFetch(uri, 'GET', headers)
    if(result && result.status === 1){
        return result.results
    }else{
        return {}
    }

}


//Gibt an, ob ein name schon existiert.
//source: source die kontrolliert wird
//exclude: hier kann eine [id] eines Datensatzes angegeben werden, welcher nicht berücksichtigt wird. Wieso? Wenn ein Datensatz geändert wird, darf der name identisch mit dem Wert des zu ändernden Datensatzes sein. Deshalb kann die [id] dieses Datensatzes als exclude angegeben werden. 
//return: true wenn der username schon existiert, ansonsten false
export async function exitsSource(source, exclude, apikey){
    const params = new URLSearchParams()
    params.append('exists', source)
    if(exclude){ params.append('exclude', exclude) }
    const paramsStr = '?' + params.toString()
    const uri = process.env.REACT_APP_API_PATH + '/sources/' + paramsStr
    const headers = {
        "Content-Type": "application/json",
        "Authorization": apikey
    }

    const answer = await coreFetch(uri, 'GET', headers)
    if(answer && answer.status === 1){
        return answer.results === 1 ? true : false
    }else{
        return false
    }
}




//Alle sources abfragen die nicht für alle Sprachen eine übersetzung haben.
//limit: nur die ersten n-Datensätze abfragen. Wenn nicht angegeben oder 0 werde alle Datensätze geliefert.
//offset: es werden nicht die ersten n Werte sondern um den Offset versetzt die n Datensätze abgerufen.
//search: Wird hier ein Wert angegeben, werden alle sinnvollen Spalten nach diesem Wert durchsucht. Ansonsten Null angeben.  
//return: ein Object, mit status, message, und der source als results
export const fetchNeedTranslation = async ( limit, offset, search ) => {
    const params = new URLSearchParams({
        needtranslation: '',
    })
    if(limit && limit>0){ params.append('limit', limit) }
    if(offset && offset>0){ params.append('offset', offset) }
    if(search){ params.append('search', "%" + search + "%") }
    const paramsStr = '?' + params.toString()
    const uri = process.env.REACT_APP_API_PATH + '/sources/' + (paramsStr !== '?' ? paramsStr : '')
    const headers = {
        "Content-Type": "application/json"
    }

    return await coreFetch(uri, 'GET', headers)
}




//Falls die Source nicht existiert, diese neu anlegen.
//Return: Id der schon vorhandenen oder neu angelegten Source oder False bei einem Fehler.
export const checkSource = async ( source, apikey ) => {

    let answer = await fetchSourceBySource(source)
    if(answer.status === 1 && answer.count === 0){
        //Source muss neu angelegt werden.
        answer = await createSource(source, null, 0, apikey)
        if(answer.status === 1){ return answer.id }else{
            return false
        }
    }else if(answer.status === 0){
        return false
    }else{
        return answer.id
    }
}