import { coreFetch } from './dbCoreFunc'


//Alle Thesaurs abfragen
//limit: nur die ersten n-Datensätze abfragen. Wenn nicht angegeben oder 0 werde alle Datensätze geliefert.
//search: Wird hier ein Wert angegeben, werden alle sinnvollen Spalten nach diesem Wert durchsucht. Ansonsten Null angeben.  
//return: ein Object, mit status, message, und dem Thesaurus als results
export const fetchThesauruses = async ( limit, search ) => {
    const params = new URLSearchParams()
    if(limit && limit>0){ params.append('limit', limit) }
    if(search){ params.append('search', "%" + search + "%") }
    const paramsStr = '?' + params.toString()
    const uri = process.env.REACT_APP_API_PATH + '/thesaurus/' + (paramsStr !== '?' ? paramsStr : '')

    const headers = {
        "Content-Type": "application/json"
    }

    return await coreFetch(uri, 'GET', headers)
}


//Ein Thesaurus abfragen mit einer id
//id: id des Thesaurus die abgefragt werden soll.
//return: ein Object, mit status, message, und dem Thesaurus als results
export const fetchThesaurus = async ( id ) => {
    var uri = process.env.REACT_APP_API_PATH + '/thesaurus/' + id;

    const headers = {
        "Content-Type": "application/json",
    }

    return await coreFetch(uri, 'GET', headers)
}


//Ein neuer Thesaurus erstellen.
//return: Es wird eine Objekt mit status und message zurückgegeben.
export async function createThesaurus(language_id, original, alternatives, apikey){

    const payload = {
        language_id: language_id,
        original: original,
        alternatives: alternatives
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(process.env.REACT_APP_API_PATH + '/thesaurus/', 'POST', headers, JSON.stringify(payload))
}


//Ein Thesaurus ändern.
//id:   id des Thesaurus das geändert werden soll
//new{x}: die neuen Werte des Thesaurus
//apikey: ein gültiger APIKEY, ansonsten kann der PUT-Request nicht durchgeführt werden.
//Es wird eine Objekt mit status und message zurückgegeben.
export async function editThesaurus(id, language_id, original, alternatives, apikey){
    const payload = {
        language_id: language_id,
        original: original,
        alternatives: alternatives
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(process.env.REACT_APP_API_PATH + '/thesaurus/' + id, 'PUT', headers, JSON.stringify(payload))
}


//Ein Thesaurus löschen
//Es wird eine Objekt mit status und message zurückgegeben.
export async function deleteThesaurus(id, apikey){
    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(process.env.REACT_APP_API_PATH + '/thesaurus/' + id, 'DELETE', headers)
}



/**
 * Kontrolliert ob das Orginal in Kombination mit der Language_id schon existiert in der Datenbank
 * 
 * @param {string} original Kontrolliert ob das Orginal in Kombination mit der Language_id schon existiert in der Datenbank
 * @param {int} language_id Kontrolliert ob das Orginal in Kombination mit der Language_id schon existiert in der Datenbank
 * @param {int} exclude Id eines Thesaurus, die bei der kontrolle nicht berücksichtigt wird
 * @param {string} apikey APIKEY für den Zugriff auf die Datenbank
 * @returns {boolean} true wenn das original schon existiert, ansonsten false
 */
export async function exitsThesaurus(original, language_id, exclude, apikey){
    const params = new URLSearchParams()
    params.append('exists', original)
    params.append('language', language_id)
    if(exclude){ params.append('exclude', exclude) }
    const paramsStr = '?' + params.toString()
    const uri = process.env.REACT_APP_API_PATH + '/thesaurus/' + paramsStr
    const headers = {
        "Content-Type": "application/json",
        "Authorization": apikey
    }

    const answer = await coreFetch(uri, 'GET', headers)
    if(answer && answer.status === 1){
        return answer.results === 1 ? true : false
    }else{
        return false
    }
}
