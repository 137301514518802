import { isNumeric } from "./coreFunc"



/**
 * Kontrolliert ob eine Option-Codeline korrekt ist oder Fehler enthällt.
 *
 * @param {string} option Der Option-String der kontrolliert wird.
 * @return {string} True wenn korrekt, ansonsten einen Fehlertext als String
 */
export const checkOption =  ( option ) => {
    
    //Sucht nach dem Typ der Option, also mit dem Startstring bis zur ersten eckigen Klammer
    const type = getType( option )
    /**
     * Die Parameter sind die Werte in den Klammern. Der Regex sucht nach einem Klammerpaar mit mindestens einem und max. zwei Klammerpaaren darin.
     * Die Werte werden als Array zurückgegeben.
     *  [0]: Den gesammten gefundenen Klammerausdruck (Bsp. [[t][125]]).
     *  [1]: Inhalt der ersten Klammer (Bsp. t)
     *  [2]: Der komplette zweite Klammerausdruck, wenn vorhanden, ansoneten null (Bsp. [125])
     *  [3]: Inhalt den zweiten Klammerausdrucks, wenn vorhanden, ansonsten null (Bsp. 125)
     */
    const parameter = getParameter( option )
    
    if(!type){
        return 'Falsches Format. Es konnte kein Option-Type (input, select, etc.) gefunden werden.'
    }
    if(!parameter){
        return 'Falsches Format. Es konnten keinen Option-Parameter (Bsp. select[[A,B,C]]) gefunden werden.'
    }


    switch (type) {
        case 'input':
            //Input Typ, muss folgendes Format aufweisen: input[[t][125]] oder input[[n]] oder input[[n][150,250]]
            if( !(parameter[0][0] === 't' || parameter[0][0] === 'n') ){
                return 'Erster Wert muss t: Text oder n:Nummer sein! (Bsp. input[[t][125]])'
            }

            if(parameter.length === 2){
                if(parameter[1].length > 2){
                    return 'Es dürfen maximal zwei Werte als zweiter Parameter angegeben werden! (Bsp. input[[n][50,150]])'
                }else if(parameter[0][0] === 't' && parameter[1].length > 1){
                    return 'Ist der erste Parameter "t" darf als zweiter Parameter nur ein Wert angegeben werden (Bsp. input[[t][125]])'
                }else if( !isNumeric(parameter[1][0])){
                    return 'Erster Wert des zweiten Parameters muss eine Zahl sein (Bsp. input[[t][125]])'
                }else if(parameter[1].length === 2 && !isNumeric(parameter[1][1])){
                    return 'Zweiter Wert des zweiten Parameters muss eine Zahl sein (Bsp. input[[n][50, 150]])'
                }
            }


            break;

        case 'select':
        case 'multiselect':
        case 'radio':
        case 'check':
            if( parameter.length > 1 ){
                return 'Für ' + type + ' darf kein zweiter Wert angegeben werden! (Bsp. '+ type +'[[A,B,C]])'
            }
            break;

        case 'slider':
            if( parameter.length > 1 && parameter[1][0] !== 'd'){
                return 'Als zweiter Parameter bei einem slider ist nur "d" für discret zulässig (Bsp. slider[[0,2.3,5.4,10][d]])'
            }
            if( parameter.length === 1 && parameter[0].length > 2 ){
                return 'Als Parameter sind nur max. zwei Zahlen zulässig. (Bsp. slider[[0,20]])'
            }
            if(parameter[0]){
                for (let index = 0; index < parameter[0].length; index++) {
                    const element = parameter[0][index];
                    if( !isNumeric(element) ){
                        return 'Parameter ' + element + ' ist keine Zahl. (Bsp. slider[[0,20]])'
                    }
                }
            }

            break;
   
        default:

            return 'Der gefundene Option-Type ' + type + ' ist nicht bekannt. Verwenden Sie input, select, multiselect, radio, check oder slider.'

    }
    
    //Kein Problem festgestellt!
    return true
  
}



export const getType = ( option ) => {

    //Sucht nach dem Typ der Option, also mit dem Startstring bis zur ersten eckigen Klammer
    const type = /^.+?(?=\[)/.exec(option)

    if(type && type.length > 0){
        return type[0]
    }else{
        return null
    }

}


export const getParameter = ( option ) => {

    const parameter = /\[\[(.*?)\](\[(.*)\])?\]/.exec(option)

    if (parameter && parameter.length === 4 && parameter[1]) {
        if(parameter[3]){
            return [
                parameter[1].split(',').map(e => {if(isNumeric(e)){return parseFloat(e)}else{return e.trim()}}),
                parameter[3].split(',').map(e => {if(isNumeric(e)){return parseFloat(e)}else{return e.trim()}})
            ]
        }else{
            return [
                parameter[1].split(',').map(e => {if(isNumeric(e)){return parseFloat(e)}else{return e.trim()}})
            ]
        }
        
    }else{
        return null
    }

}
