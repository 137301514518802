import SaveIcon from '@mui/icons-material/Save'
import FileOpenIcon from '@mui/icons-material/FileOpen'
import SaveAsIcon from '@mui/icons-material/SaveAs'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash'
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp'
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown'
import { Box, Stack, Button, Container, IconButton, TextField, Drawer, Typography, Tooltip, FormGroup, Switch, FormControlLabel, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import WizardArgumentsTable from '../components/WizardArgumentsTable'
import { useState, useContext, useEffect } from 'react'
import { LanguageContext } from '../components/LanguageProvider'
import { AuthContext } from '../components/AuthProvider'
import WizardValues from '../components/WizardValues'
import WizardProperties from '../components/WizardProperties'
import { createWizard, deleteWizard , deleteFinalyWizard, editWizard, fetchWizards, fetchDeletedWizards, restoreWizard} from '../includes/dbWizardsFunc'
import { useSnackbar } from 'notistack'
import WizardExecute from '../components/WizardExecute';
import { fetchProperties } from '../includes/dbPropertiesFunc'


const fieldWidth = 300
const labelWidth = 160

const delay = t => new Promise(resolve => setTimeout(resolve, t));


const initArgs = [
        [
            {id: 1, series: '32', torque: 'IF{stages==1?1}{stages==2?2}{stages==3?4}{else?anfragen}'},
            {id: 2, series: '42', torque: 'IF{stages==4?1}{stages==8?2}{stages==16?4}{else?anfragen}'},
            {id: 3,series: '52', torque: 'IF{stages==10?1}{stages==20?2}{stages==3?40}{else?anfragen}'},
            {id: 4, series: '62', torque: 'IF{stages==21?1}{stages==2?42}{stages==3?84}{else?anfragen}'}
        ],
        [
            { id: 1, ratio: 3.7, stages: 1 },
            { id: 2, ratio: 5.0, stages: 1 },
            { id: 3, ratio: 6.3, stages: 2 },
            { id: 4, ratio: 13.4, stages: 2 },
            { id: 5, ratio: 18.3, stages: 3 },
            { id: 6, ratio: 23.2, stages: 3 },
        ],
        [
            { id: 1, key: 'MERGE{series, stages}', switch: 'IF{key==322?on}{else?off}'}
        ]
    ]


const Wizardpage = () => {
    const [ argGroups, setArgGroups ] = useState(initArgs)
    const [ values, setValues ] = useState({productnumber: 'P1.{series}.{ratio}.{switch}', isactive: 1})
    const [ properties, setProperties ] = useState([])
    const [ wizards, setWizards ] = useState([])
    const [ deletedWizards, setDeletedWizards ] = useState([])
    const [ wizName, setWizName ] = useState('')
    const [ wizId, setWizId] = useState(null)
    const [ isDrawerOpen, setIsDrawerOpen ] = useState(false)
    const [ executeDialogIsOpen, setExecuteDialogIsOpen ] = useState(false)
    const [ exportDialogIsOpen, setExportDialogIsOpen ] = useState(false)
    const [ exportMode, setExportMode ] = useState('upload')
    const [ showDeleted, setShowDeleted ] = useState(false)
    const [ exportText, setExportText ] = useState("")
    const { languages } = useContext(LanguageContext)
    const { apikey } = useContext(AuthContext)
    const { enqueueSnackbar } = useSnackbar()


    useEffect(() => {
        (async () => {
            const answer = await fetchWizards()
            setWizards(answer.results)
        })();
    },[])


    const checkArg = ( arg ) => {
        //Kontrollieren ob der Argument-Name schon in einer der Gruppen vorhanden ist.
        for (let i = 0; i < argGroups.length; i++) {
            const args = argGroups[i];
            if(args.length > 0){
                if(!Object.keys(args[0]).find(e => e.toLowerCase() === arg.toLowerCase()) === false){
                    return false
                }
            }
        }
        return true
    }
    
    const onChange = ( argIndex, newValues ) => {
        const newArgs = argGroups.map((e, index) => index === argIndex ? newValues : e)
        setArgGroups(newArgs)
    }
    
    const handleAddArgGroup = () => {
        setArgGroups((args) => [...args, [{}]])
    }

    const handleRemoveArgGroup = ( index ) => {
        const newArgs = argGroups.filter((e, i) => index !== i)
        setArgGroups(newArgs)
    }

    const handleSaveWiz = async ( id ) => {
        const jsonArgs = JSON.stringify(argGroups)
        const jsonValues = JSON.stringify(values)
        const jsonProps = JSON.stringify(properties)

        if( id ){
            //geladener Wiz ändern.
            const answer = await editWizard(id, wizName, jsonArgs, jsonValues, jsonProps, apikey)
            if(answer && answer.status === 1){
                setWizId(id)
                enqueueSnackbar('Wiz erfolgreich gespeichert.', {variant: 'success'})
            }else{
                enqueueSnackbar('Wiz konnte nicht gespeichert werden. ' + answer.message, {variant: 'warning'})
            }
        }else{
            //neuer Wiz anlegen.
            const answer = await createWizard(wizName, jsonArgs, jsonValues, jsonProps, apikey)
            if(answer && answer.status === 1){
                setWizId(parseInt(answer.id, 10))
                enqueueSnackbar('Wiz erfolgreich angelegt.', {variant: 'success'})
            }else{
                enqueueSnackbar('Wiz konnte nicht gespeichert werden. ' + answer.message, {variant: 'warning'})
            }
        }
        handleRefreshWiz()
    }

    const handleRefreshWiz = async () => {
        const answer1 = await fetchWizards()
        const answer2 = await fetchDeletedWizards()
        if(answer1 && answer1.status === 1 && answer2 && answer2.status === 1){
            setWizards(answer1.results)
            setDeletedWizards(answer2.results)
            return true
        }else{
            return false
        }
    }
    

    const handleLoadWiz = async ( wiz ) => {
        if(wiz){
            setArgGroups([])
            setValues({})
            setProperties([])

            await delay(1)

            if(wiz.id){setWizId(wiz.id)}
            setWizName(wiz.name)
            setArgGroups(JSON.parse(wiz.arguments))
            setValues(JSON.parse(wiz.values))
            //Es könnten nicht mehr vorhandene Props geladen werden:
            const vals = JSON.parse(wiz.properties)
            const answer = await fetchProperties()
            if(answer && answer.status===1){
                const allprops = answer.results
                vals.forEach(val => {
                    if(!val.valuesource){  //gilt auch wenn leerer Zeichenstring
                        delete val.valuesource
                    }
                    const foundProp = allprops.find(e => e.id === val.property.id );
                    if(!foundProp || foundProp.label_source !== val.property.label_source){
                        enqueueSnackbar('Das geladenen Property (' +  val.property.id + ' - ' + val.property.label_source + ') konnte in der Datenbank nicht gefunden werden. Bitte wählen Sie eines aus der Liste.', {variant: 'error', autoHideDuration: 10000})
                   }
                });
            }else{
                enqueueSnackbar('Properties konnten nicht aus der Datenbank abgerufen werden.', {variant: 'error'})
            }
            setProperties(vals)
            return true
        }else{
            return false
        }
    }




    const handleDeleteWiz = async ( id ) => {
        const answer = await deleteWizard(id, apikey)
        if(answer && answer.status === 1){
            if( id === wizId ){
                setWizId(null)
                setWizName('')
                setArgGroups([])
                setValues({})
                setProperties([])
            }
            enqueueSnackbar('Wiz erfolgreich gelöscht.', {variant: 'success'})
            return true
        }else{
            enqueueSnackbar('Wiz konnte nicht gelöscht werden. ' + answer.message, {variant: 'warning'})
            return false
        }
    }


    const handleDeleteFinalyWiz = async ( id ) => {
        const answer = await deleteFinalyWizard(id, apikey)
        if(answer && answer.status === 1){
            if( id === wizId ){
                setWizId(null)
                setWizName('')
                setArgGroups([])
                setValues({})
                setProperties([])
            }
            enqueueSnackbar('Wiz erfolgreich gelöscht.', {variant: 'success'})
            return true
        }else{
            enqueueSnackbar('Wiz konnte nicht gelöscht werden. ' + answer.message, {variant: 'warning'})
            return false
        }
    }

    const handleRestoreWiz = async ( id ) => {
        const answer = await restoreWizard(id, apikey)
        if(answer && answer.status === 1){
            enqueueSnackbar('Wiz erfolgreich wieder hergestellt.', {variant: 'success'})
            return true
        }else{
            enqueueSnackbar('Wiz konnte nicht wieder hergestellt werden. ' + answer.message, {variant: 'warning'})
            return false
        }
    }


    const handleCloseExecute = ( answer ) => {
        setExecuteDialogIsOpen(false)
    }


    const handleCloseExport = () => {
        setExportDialogIsOpen(false)
    }

    const handleUpload = ( code ) => {
        let obj = null
        try {
            obj = JSON.parse(code)
        } catch (error) {
            enqueueSnackbar('Aus dem Text konnte kein Wiz erstellt werden.', {variant: 'warning'})
            return false
        }
        
        if(obj.arguments && obj.values && obj.properties){
            const wiz = {
                id: null,
                name: obj.name ? obj.name : '',
                arguments: JSON.stringify(obj.arguments),
                values: JSON.stringify(obj.values),
                properties: JSON.stringify(obj.properties)
            }
            if(handleLoadWiz(wiz)){
                enqueueSnackbar('Wiz erfolgreich eingelesen.', {variant: 'success'})
                setExportDialogIsOpen(false)
            }else{
                enqueueSnackbar('Aus dem Text konnte kein Wiz erstellt werden.', {variant: 'warning'})
            }
        }else{
            enqueueSnackbar('Aus dem Text konnte kein Wiz erstellt werden.', {variant: 'warning'})
        }

    }

    const handleDownload = () => {
        setExportMode('download')
        setExportDialogIsOpen(true)

        const exp = {
            name: wizName,
            arguments: argGroups,
            values: values,
            properties: properties
        }
        const jsonExp = JSON.stringify(exp)
        setExportText(jsonExp)
    }


    return (
        <Container>
            <Box mb={6} mt={1}>
                <Stack direction='row' alignItems='center' spacing={1}>
                    <IconButton color='primary' onClick={() => setIsDrawerOpen(true)}>
                        <FileOpenIcon/>
                    </IconButton>
                        <IconButton color='primary' disabled={wizId === null} onClick={() => handleSaveWiz( wizId )}>
                            <Tooltip title='Save'>
                                <SaveIcon />
                            </Tooltip>
                        </IconButton>
                    <Tooltip title='Save as'>
                        <IconButton color='primary' onClick={() => handleSaveWiz()}>
                            <SaveAsIcon />
                        </IconButton>
                    </Tooltip>
                    <IconButton onClick={() => {
                        setExportText('')                        
                        setExportMode('upload')
                        setExportDialogIsOpen(true)
                    }}>
                        <ArrowCircleUpIcon/>
                    </IconButton>
                    <IconButton onClick={handleDownload}>
                        <ArrowCircleDownIcon/>
                    </IconButton>
                    <TextField
                        fullWidth
                        label='Wiz-Name'
                        value={ wizName }
                        onChange={(e) => setWizName( e.target.value )}
                    />

                    <Button sx={{flexShrink: '0'}} variant='contained' color='error' onClick={() => setExecuteDialogIsOpen(true)}>Ausführen</Button>
                </Stack>
            </Box>
            <Box mb={4}>
                <Button sx={{mt: 3, mb: 3}} onClick={ handleAddArgGroup }>New Argument-Group</Button>
                <Stack direction='row' flexWrap='wrap'>
                    { argGroups && argGroups.length > 0 && 
                        argGroups.map((args, index) => (
                            <Box mr={1} mb={1} key={index}>
                                <WizardArgumentsTable
                                    values={args}
                                    checkArg={ checkArg }
                                    onChange={(e) => onChange(index, e) }
                                    onRemove={() => handleRemoveArgGroup(index)}
                                />
                            </Box>
                        ))
                    }
                </Stack>
            </Box>

            <Box mb={5} >
                <WizardValues fieldWidth={fieldWidth} focusFieldWidth={1000} labelWidth={labelWidth} languages={languages} values={values} setValues={setValues} />
                <WizardProperties fieldWidth={fieldWidth} focusFieldWidth={500} labelWidth={labelWidth} languages={languages} properties={properties} setProperties={setProperties} />
            </Box>

            <Drawer
                PaperProps={{ sx: {width: { xs: 1, sm: 0.4 }} }}
                anchor='right'
                open={ isDrawerOpen }
                onClose={() => setIsDrawerOpen(false)}
            >
                <div className="drawer-content">
                    <Typography variant='h5' mt={1}>Gespeicherte Wiz's</Typography>
                    <FormGroup>
                        <FormControlLabel control={<Switch checked={showDeleted} onChange={() => {
                            handleRefreshWiz()
                            setShowDeleted(!showDeleted)}
                        } />} label='Gelöschte anzeigen' />
                    </FormGroup>


                    <Stack direction='column' spacing={1} alignItems='flex-start' mt={2}>
                        {wizards &&
                            wizards.map((wiz, index) => (
                                <Stack key={index} direction='row' spacing={1} alignItems='center'>
                                    <IconButton color='primary' size='small' onClick={async () => {
                                        const answer = await handleLoadWiz( wiz )
                                        answer && setIsDrawerOpen(false)
                                    }}>
                                        <FileOpenIcon fontSize='small' />
                                    </IconButton>
                                    <IconButton color='warning' size='small' onClick={async () => {
                                        const answer = await handleDeleteWiz( wiz.id )
                                        answer && handleRefreshWiz()
                                    }}>
                                        <DeleteForeverIcon fontSize='small' />
                                    </IconButton>
                                    <Typography>{wiz.name}</Typography>
                                </Stack>
                            ))
                        }
                    </Stack>

                   
                    {showDeleted &&
                        <>
                        <Typography mt={3}>Gelöschte Wiz's</Typography>
                        {deletedWizards && 
                            deletedWizards.map((wiz, index) => (
                                <Stack key={index} direction='row' spacing={1} alignItems='center'>
                                    <IconButton color='primary' size='small' onClick={async () => {
                                        const answer = await handleLoadWiz( wiz )
                                        answer && setIsDrawerOpen(false)
                                    }}>
                                        <FileOpenIcon fontSize='small' />
                                    </IconButton>
                                    <IconButton color='success' size='small' onClick={async () => {
                                        const answer = await handleRestoreWiz( wiz.id )
                                        answer && handleRefreshWiz()
                                    }}>
                                        <RestoreFromTrashIcon fontSize='small' />
                                    </IconButton>                                    
                                    <IconButton color='warning' size='small' onClick={async () => {
                                        const answer = await handleDeleteFinalyWiz( wiz.id )
                                        answer && handleRefreshWiz()
                                    }}>
                                        <DeleteForeverIcon fontSize='small' />
                                    </IconButton>
                                    <Typography>{wiz.name}</Typography>
                                </Stack>
                            ))                        
                        }
                        </>
                    }


                </div>
            </Drawer>

            <WizardExecute
                wizId={ wizId }
                isOpen={executeDialogIsOpen}
                onClose={handleCloseExecute}
                args={ argGroups }
                values={ values }
                properties={ properties }
                apikey={ apikey ? apikey : '' }
            />

            <Dialog
                open={exportDialogIsOpen}
                onClose={handleCloseExport}
                fullWidth
                maxWidth={'lg'}

            >
                <DialogTitle>Import/Export</DialogTitle>
                <DialogContent>
                    <textarea
                        style={{width: '100%', boxSizing: 'border-box', resize: 'vertical'}}
                        rows="20"
                        value={exportText}
                        onChange={(e) => setExportText(e.target.value)}
                    ></textarea>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={() => setExportDialogIsOpen(false)}>Close</Button>
                    {exportMode === 'upload' && <Button variant='contained' onClick={() => handleUpload(exportText)}>Load</Button>}
                </DialogActions>
            </Dialog>

        </Container>
     );
}
 
export default Wizardpage;

