import AddCircleIcon from '@mui/icons-material/AddCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { useRef, useEffect, useState } from "react"
import PropTypes from 'prop-types'
import { IconButton, Button, Stack, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Paper, Typography } from '@mui/material';
import WizardArgumentInputField from './WizardArgumrntInputField'

const delay = t => new Promise(resolve => setTimeout(resolve, t));



const WizardArgumentsTable = ( props ) => {
    const { values: propValues, onChange, checkArg, onRemove } = props;
    const [args, setArgs] = useState([])
    const [values, setValues] = useState([])
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [argError, setArgError ] = useState(null)
    const argumentNameInput = useRef()


    useEffect(() =>{
        if(!propValues || propValues.length === 0){
            setValues([])
        }else{
            setArgs(Object.keys(propValues[0]).filter((e) => e !== 'id'))
            setValues(propValues)
        }
    },[propValues])


    const handleChangeArg = (arg, id, value) =>{
        const newValues = values.map(obj => {
            if(obj.id === id){
                obj[arg] = value
            }
            return obj
        })
        setValues(newValues)
        if(onChange){
            onChange(newValues)
        }
    }

    const handleAddValue = () => {
        const id = values.length + 1
        const newValue = { id }
        args.forEach(arg => {
            newValue[arg] = ''
        })
        setValues((oldValues) => [...oldValues, newValue]);
    }

    const handleAddArg = ( name ) => {
        //Es dürfen nur Buchstaben und Zahlen eingegeben werden.
        let namecheck = /[^a-zA-Z0-9_.\-']/
        if(namecheck.test(name)){
            setArgError("Es sind nur Buchstaben, Zahlen und '_' zulässig!")
            return false
        }

        if(!args.find(e => e.toLowerCase() === name.toLowerCase()) && (!checkArg || checkArg(name))){
            //Falls Argument-Name noch nicht vergeben, diesen ergänzen
            setArgError(null)
            args.push(name)
            if(values.length===0){handleAddValue()}
            
            values.forEach(val => {
                val[name] = ''
            })

            return true
        }else{
            setArgError('Name muss eindeutig sein.')
            return false
        }
    }

    const handleDeleteValue = async ( value ) => {
        const newValues = values.filter((e) => e.id !== value.id)

        setValues( [] )
        await delay(1)
        setValues( newValues )

        if(onChange){
            onChange(newValues)
        }
    }

    const handleDeleteArg = async ( name ) => {
        const myvalues = values
        setValues([])
        const newArgs = args.filter((e) => e !== name)
        setArgs(newArgs)

        if(newArgs && newArgs.length > 0){
            for (let index = 0; index < myvalues.length; index++) {
                const element = myvalues[index];
                delete element[name]
            }
            await delay(1)
            setValues(myvalues)
    
            if(onChange){
                onChange(myvalues)
            }
        }else{
            setValues([])
            if(onChange){
                onChange([])
            }
        }

       
    }

    const handleDialogClose = () => {
        setIsDialogOpen(false)
    }


    return (
        <Paper className="arguments-table" sx={{p: 1}}>
            <IconButton size='small' onClick={onRemove}>
                <DeleteForeverIcon fontSize='small'/>
            </IconButton>

            { args && args.length > 0 ? (
                <Stack direction='row' spacing={2} alignItems='flex-start'>
                    {args.map((arg, index) => (
                        <Stack direction='column' spacing={0.5} key={index} alignItems='center'>
                            <Stack
                                direction='row'
                                spacing={1}
                                alignItems='center'
                                ml={index === 0 ? 5 : 0}
                            >
                                <Typography>{arg}</Typography>
                                <IconButton size='small' onClick={() => handleDeleteArg(arg)}>
                                    <CancelIcon fontSize='small' color='warning'/>
                                </IconButton>
                            </Stack>
                            {values.map((value, index1) => (
                                <Stack key={index1} direction='row' alignItems='center' spacing={0.5}>
                                    {index === 0 &&
                                    <IconButton size='small' onClick={() => handleDeleteValue(value)}>
                                        <CancelIcon fontSize='small' color='warning'/>
                                    </IconButton>
                                    }
                                    <div>
                                    {/* <TextField
                                        // sx={{maxWidth: '150px'}}
                                        sx={{minWidth: hasFocus ? '500px' : 0}}
                                        onFocus={() => setHasFocus(true)}
                                        size='small'
                                        margin='none'
                                        defaultValue={value[arg]}
                                        onBlur={(e) => {
                                            setHasFocus(false)
                                            handleChangeArg(arg, value.id, e.target.value)
                                        }}
                                    /> */}
                                    <WizardArgumentInputField
                                        defaultValue={ value[arg] }
                                        focusFieldWidth={500}
                                        onBlur={(e) => handleChangeArg(arg, value.id, e.target.value)}
                                    />
                                    </div>
                                </Stack>
                            ))}
                        </Stack>
                    ))}
                    <IconButton size='small' onClick={() => setIsDialogOpen(true)}>
                        <AddCircleIcon fontSize='small' color='primary'/>
                    </IconButton>
                </Stack>
            ) : (
                <>
                <Typography>Keine Daten</Typography>
                <IconButton size='small' onClick={() => setIsDialogOpen(true)}>
                    <AddCircleIcon fontSize='small' color='primary'/>
                </IconButton>
                </>
            )}
            {args && args.length > 0 && 
                <IconButton sx={{mt: 1}} color='primary' onClick={handleAddValue} size='small'>
                    <AddCircleIcon fontSize='small'/>
                </IconButton>
            }

            <Dialog open={isDialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Argument-Name</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Name für dieses Argument eingeben.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin='dense'
                        fullWidth
                        variant='standard'
                        error={argError === null ? false : true}
                        helperText={argError ? argError : ''}
                        inputProps={{ ref: argumentNameInput }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancel</Button>
                    <Button onClick={() => {
                        if( handleAddArg(argumentNameInput.current.value) ){
                            setIsDialogOpen(false)
                        }
                    }}>Create</Button>
                </DialogActions>
            </Dialog>
        </Paper>
     );
}

WizardArgumentsTable.propTypes = {
    onChange: PropTypes.func,
    checkArg: PropTypes.func,
    onRemove: PropTypes.func.isRequired,
    values: PropTypes.array
};
 
export default WizardArgumentsTable;