import { Container } from "@mui/material";
import InfoAlgorithmus from "../components/InfoAlgorithmus";
import InfoThesaurus from "../components/InfoThesaurus";

const Hilfepage = () => {
    return ( 
        <Container>
            <InfoAlgorithmus variant='h2'/>
            <InfoThesaurus variant='h2' sx={{mt: 5}} />
        </Container>
     );
}
 
export default Hilfepage;