import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Home from './pages/Home'
import Layout from './components/Layout'
import Userspage from './pages/Userspage'
import Loginpage from './pages/Loginpage'
import { QueryClient, QueryClientProvider } from 'react-query'
import AuthProvider from './components/AuthProvider'
import LanguageProvider from './components/LanguageProvider'
import ProtectedRoute from './components/ProtectedRoute'
import Languagespage from './pages/Languagespage'
import Sourcespage from './pages/Sourcespage'
import Filespage from './pages/Filespage'
import Translationspage from './pages/Translationspage'
import { createTheme, ThemeProvider } from '@mui/material'
import { lime, teal } from '@mui/material/colors'
import Ptypespage from './pages/Ptypespage'
import Propertiespage from './pages/Propertiespage'
import Productspage from './pages/Productspage'
import Optionspage from './pages/Optionspage'
import Wizardpage from './pages/Wizardpage'
import Queriespage from './pages/Queriespage'
import Hilfepage from './pages/Hilfepage'
import Thesauruspage from './pages/Thesauruspage'
import Emailaddressespage from './pages/Emailaddressespage'


const queryClient = new QueryClient()

function App() {

  const theme = createTheme({
    palette: {
      primary: teal,
      secondary: lime,
    }
  })

  

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <LanguageProvider>
          <Router>
            <link
              rel="stylesheet"
              href="https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700&display=swap"
            />
            <div className="App">
              <header className="content">
                <ThemeProvider theme={theme}>
                  <Layout >
                    <Routes>
                      <Route path='/' element={<Home />} />
                      <Route path='/:id' element={<Home />} />
                      <Route path='/login' element={<Loginpage />} />
                      <Route path='/wizard' element={
                                                        <ProtectedRoute redirect="/login" onlyadmin="/">
                                                          <Wizardpage />
                                                        </ProtectedRoute>
                                                      } />
                      <Route path='/users' element={
                                                        <ProtectedRoute redirect="/login" onlyadmin="/">
                                                          <Userspage />
                                                        </ProtectedRoute>
                                                      } />
                      <Route path='/users/:id' element={
                                                        <ProtectedRoute redirect="/login" onlyadmin="/">
                                                          <Userspage />
                                                        </ProtectedRoute>
                                                      } />                      
                      <Route path='/emailaddresses' element={
                                                        <ProtectedRoute redirect="/login" onlyadmin="/">
                                                          <Emailaddressespage />
                                                        </ProtectedRoute>
                                                      } />
                      <Route path='/emailaddresses/:id' element={
                                                        <ProtectedRoute redirect="/login" onlyadmin="/">
                                                          <Emailaddressespage />
                                                        </ProtectedRoute>
                                                      } />
                      <Route path='/languages' element={
                                                        <ProtectedRoute redirect="/login" onlyadmin="/">
                                                          <Languagespage />
                                                        </ProtectedRoute>
                                                      } />
                      <Route path='/languages/:id' element={
                                                        <ProtectedRoute redirect="/login" onlyadmin="/">
                                                          <Languagespage />
                                                        </ProtectedRoute>
                                                      } />
                      <Route path='/sources' element={
                                                        <ProtectedRoute redirect="/login">
                                                          <Sourcespage />
                                                        </ProtectedRoute>
                                                      } />
                      <Route path='/sources/:id' element={
                                                        <ProtectedRoute redirect="/login">
                                                          <Sourcespage />
                                                        </ProtectedRoute>
                                                      } />
                      <Route path='/translations' element={
                                                        <ProtectedRoute redirect="/login">
                                                          <Translationspage />
                                                        </ProtectedRoute>
                                                      } />
                      <Route path='/files' element={
                                                        <ProtectedRoute redirect="/login">
                                                          <Filespage />
                                                        </ProtectedRoute>
                                                      } />
                      <Route path='/files/:id' element={
                                                        <ProtectedRoute redirect="/login">
                                                          <Filespage />
                                                        </ProtectedRoute>
                                                      } />
                      <Route path='/ptypes' element={
                                                        <ProtectedRoute redirect="/login">
                                                          <Ptypespage />
                                                        </ProtectedRoute>
                                                      } />
                      <Route path='/ptypes/:id' element={
                                                        <ProtectedRoute redirect="/login">
                                                          <Ptypespage />
                                                        </ProtectedRoute>
                                                      } />
                      <Route path='/properties' element={
                                                        <ProtectedRoute redirect="/login">
                                                          <Propertiespage />
                                                        </ProtectedRoute>
                                                      } />
                      <Route path='/properties/:id' element={
                                                        <ProtectedRoute redirect="/login">
                                                          <Propertiespage />
                                                        </ProtectedRoute>
                                                      } />                                                    
                      <Route path='/products' element={
                                                        <ProtectedRoute redirect="/login">
                                                          <Productspage />
                                                        </ProtectedRoute>
                                                      } />
                      <Route path='/products/:id' element={
                                                        <ProtectedRoute redirect="/login">
                                                          <Productspage />
                                                        </ProtectedRoute>
                                                      } />
                      <Route path='/options' element={
                                                        <ProtectedRoute redirect="/login">
                                                          <Optionspage />
                                                        </ProtectedRoute>
                                                      } />
                      <Route path='/options/:id' element={
                                                        <ProtectedRoute redirect="/login">
                                                          <Optionspage />
                                                        </ProtectedRoute>
                                                      } />
                      <Route path='/queries' element={
                                                        <ProtectedRoute redirect="/login">
                                                          <Queriespage />
                                                        </ProtectedRoute>
                                                      } />
                      <Route path='/queries/:id' element={
                                                        <ProtectedRoute redirect="/login">
                                                          <Optionspage />
                                                        </ProtectedRoute>
                                                      } />
                      <Route path='/thesaurus' element={
                                                        <ProtectedRoute redirect="/login">
                                                          <Thesauruspage />
                                                        </ProtectedRoute>
                                                      } />
                      <Route path='/thesaurus/:id' element={
                                                        <ProtectedRoute redirect="/login">
                                                          <Thesauruspage />
                                                        </ProtectedRoute>
                                                      } />
                      <Route path='/hilfe' element={
                                                        <ProtectedRoute redirect="/login">
                                                          <Hilfepage />
                                                        </ProtectedRoute>
                                                      } />
                    </Routes>
                  </Layout>
                </ThemeProvider>
              </header>
            </div>
          </Router>
          </LanguageProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
