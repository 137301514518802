

/**
 * Pausiert den Programmablauf
 *
 * @param {integer} t Zeit der Pause in Millisekunden
 */
export const delay = t => new Promise(resolve => setTimeout(resolve, t));


/**
 * Wartet bis ein element im Document existiert
 *
 * @param {string} doc Document, in dem das Element mit getElementById gesucht wird
 * @param {string} id Id des Elementes, welches im Doc vorhanden sein muss, damit eine Schlaufe beendet wird.
 */
export const waitFor = async (doc, id) => {

    for (let i = 0; i < 10; i++) {
        await delay(10)
        if(doc.getElementById(id)){
            return true
        }
    }
    return false
}


/**
 * Kontrolliert ob ein String nummerisch ist.
 *
 * @param {string} n String der kontrolliert wird.
 * @return {boolena} True wenn nummersich, ansoneten false.
 */
export function isNumeric(n) {
    if(n === '-' || n === ''){return true}
    return !isNaN(parseFloat(n)) && isFinite(n);
}