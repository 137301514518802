import PropTypes from 'prop-types'
import { Typography } from "@mui/material";
import { useRef, useState } from "react";
import { useEffect } from "react";
import { isNumeric } from "../includes/coreFunc";
import { checkOption, getType, getParameter } from "../includes/optionFunc";



const OptionControl = ( props ) => {
    const id = "OPC" + Math.floor(Math.random() * 10000) + 1
    const { option, onChange } = props;
    const [type, setType] = useState(null)
    const [parameter, setParameter] = useState(null)
    const [inputValue, setInputValue] = useState('')
    const [inputType, setInputType] = useState('t')
    const previousNumber = useRef(0)


    useEffect(() => {

        if(checkOption(option) === true){
            const mytype = getType(option)
            const myparameter = getParameter(option)
            setType( mytype )
            setParameter ( myparameter )

            if(mytype==='input'){
                setInputType(myparameter[0][0])  //setzt inputType auf 't' für string oder 'n' für Zahlen
                if(myparameter[0][0] === 'n' && myparameter.length === 2 && myparameter[1].length === 2){
                    setInputValue(myparameter[1][0])  //Minimaler Zahlewert setzten
                    previousNumber.current = myparameter[1][0]
                }else if(myparameter[0][0] === 'n'){
                    setInputValue(0)
                    previousNumber.current = 0
                } 
            }  

        }else{
            setType(null)
            setParameter(null)
        }


    },[option])


    useEffect(() => {
        if(onChange){
            onChange(inputValue)
        }
        // eslint-disable-next-line
    },[inputValue])


    const handleInputLostFocus = (e) => {
        const input = e.target.value

        if(inputType === 'n'){
            //Auswertung der Eingaben vom Input-Field wenn nur Zahlen eingegeben werden dürfen.
            if(!isNumeric(input)){
                setInputValue(previousNumber.current)
            }else{
                if( parameter.length === 2 && parameter[1].length === 1 && parseFloat(input) > parameter[1][0] ){
                    setInputValue(parameter[1][0])
                    previousNumber.current = parameter[1][0]
                }else if( parameter.length === 2 && parameter[1].length === 2 && parseFloat(input) > parameter[1][1] ){
                    setInputValue(parameter[1][1])
                    previousNumber.current = parameter[1][1]
                }else if( parameter.length === 2 && parameter[1].length === 2 && parseFloat(input) < parameter[1][0] ){
                    setInputValue(parameter[1][0])
                    previousNumber.current = parameter[1][0]
                }else{
                    setInputValue(input)
                    previousNumber.current = input
                }
            }
        }else{
            //Texteingabe
            setInputValue(input)
            previousNumber.current = input
        }
    }


    const handleInputChanged = (e) => {
        const input = e.target.value
        setInputValue(input)
    }


    const inputCount = ( increment ) => {
        if(inputType === 'n' && isNumeric(inputValue)){
            const newValue = parseFloat(inputValue) + increment
            
            if(parameter.length===2 && parameter[1].length === 1 && newValue <= parameter[1][0]){
                setInputValue( newValue )
            }else if(parameter.length===2 && parameter[1].length === 2 && newValue >= parameter[1][0] && newValue <= parameter[1][1] ){
                setInputValue( newValue )
            }
        }
    }


    return ( 
        <div>
            { !type && <Typography>Unbekannte Option</Typography> }
            { type && type === 'input' && 
                //Input-Option
                <>
                    {parameter[0][0] === 'n' ? (
                        <>
                        {parameter.length === 1 ? (
                            <div className="inputbox-numeric">
                                <button onClick={() => inputCount(-1)}>-</button>
                                <input className="textfield-numeric" type="text" value={inputValue} onBlur={(e) => handleInputLostFocus(e)} onChange={(e) => handleInputChanged(e)}/>
                                <button onClick={() => inputCount(1)}>+</button>
                            </div>
                        ) : (
                            <>
                            { parameter[1].length === 2 &&
                                <div className="inputbox-numeric">
                                    <button onClick={() => inputCount(-1)}>-</button>
                                    <input type="text" value={inputValue} onBlur={(e) => handleInputLostFocus(e)} onChange={(e) => handleInputChanged(e)} min={parameter[1][0]} max={parameter[1][1]}/>
                                    <button onClick={() => inputCount(1)}>+</button>
                                </div>
                            }
                            { parameter[1].length === 1 &&
                                <div className="inputbox-numeric">
                                <button onClick={() => inputCount(-1)}>-</button>
                                    <input type="text" value={inputValue} onBlur={(e) => handleInputLostFocus(e)} onChange={(e) => handleInputChanged(e)} max={parameter[1][0]}/>
                                    <button onClick={() => inputCount(1)}>+</button>
                                </div>
                            }
                            </>
                        )}
                        </>
                    ) : (
                        <>
                        {parameter.length === 1 ? (
                            <input type="text" value={inputValue} onChange={(e) => handleInputChanged(e)}/>
                        ) : (
                            <input type="text" maxLength={parameter[1][0]} value={inputValue} onChange={(e) => handleInputChanged(e)}/>
                        )}
                        </>
                    )}
                </>            
            }
            { type && (type === 'select' || type === 'multiselect') &&
                //Select und Multiselect
                <select className='' multiple={type === 'multiselect'}>
                    {parameter[0].map((option, index) => (
                        <option key={index} value={option}>{option}</option>
                    ))}
                </select>
            }
            { type && type === 'radio' &&
                //Radio-Buttons
                parameter[0].map((option, index) => (
                    <div key={index} className='' >
                        <input type='radio' key={index} value={option} name={id}/>
                        <label>{option}</label>
                    </div>
                ))
            }
            { type && type === 'check' &&
                //Checkbox
                parameter[0].map((option, index) => (
                    <div key={index} className='' >
                        <input type='checkbox' key={index} value={option} name={option}/>
                        <label>{option}</label>
                    </div>
                ))
            }
            { type && type === 'slider' && parameter[0] &&
                //Slider
                <>
                {parameter[0].length === 1 ? <input type="range" max={parameter[0][0]} /> : <input type="range" min={parameter[0][0]} max={parameter[0][1]} />}
                </>
            }
        </div>
     );
}


OptionControl.propTypes = {
    option: PropTypes.string.isRequired,
    onChange: PropTypes.func,
};


export default OptionControl;