import { Typography } from "@mui/material";


const WizardLabel = (props) => {
    const { labelWidth, children, ...other } = props;    
    return(
        <Typography
            component='span'
            sx={{display: 'inline-block', width: labelWidth + 'px'}}
            {...other}
        >{children}</Typography>
    )
}

export default WizardLabel;
