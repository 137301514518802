import { coreFetch } from './dbCoreFunc'


//Alle Values abfragen
//limit: nur die ersten n-Datensätze abfragen. Wenn nicht angegeben oder 0 werde alle Datensätze geliefert.
//search: Wird hier ein Wert angegeben, werden alle sinnvollen Spalten nach diesem Wert durchsucht. Ansonsten Null angeben.  
//return: ein Object, mit status, message, und dem Values als results
export const fetchValues = async ( limit, search ) => {
    const params = new URLSearchParams()
    if(limit && limit>0){ params.append('limit', limit) }
    if(search){ params.append('search', "%" + search + "%") }
    const paramsStr = '?' + params.toString()
    const uri = process.env.REACT_APP_API_PATH + '/values/' + (paramsStr !== '?' ? paramsStr : '')

    const headers = {
        "Content-Type": "application/json"
    }

    return await coreFetch(uri, 'GET', headers)
}


//Ein Value abfragen mit einer id
//id: id des Value die abgefragt werden soll.
//return: ein Object, mit status, message, und dem Values als results
export const fetchValue = async ( id ) => {
    var uri = process.env.REACT_APP_API_PATH + '/values/' + id;

    const headers = {
        "Content-Type": "application/json",
    }

    return await coreFetch(uri, 'GET', headers)
}


//Ein neues Value erstellen.
//return: Es wird eine Objekt mit status und message zurückgegeben.
export async function createValue(product_id, property_id, value, value_id, apikey){

    const payload = {
        product_id: product_id,
        value: value ? value : null,
        value_id: value_id ? value_id : null,
        property_id: property_id
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(process.env.REACT_APP_API_PATH + '/values/', 'POST', headers, JSON.stringify(payload))
}


//Ein Values ändern.
//id:   id des Values das geändert werden soll
//new{x}: die neuen Werte des Values
//apikey: ein gültiger APIKEY, ansonsten kann der PUT-Request nicht durchgeführt werden.
//Es wird eine Objekt mit status und message zurückgegeben.
export async function editValue(id, product_id, property_id, value, value_id, apikey){
    const payload = {
        product_id: product_id,
        value: value ? value : null,
        value_id: value_id ? value_id : null,
        property_id: property_id
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(process.env.REACT_APP_API_PATH + '/values/' + id, 'PUT', headers, JSON.stringify(payload))
}


//Ein Values löschen
//Es wird eine Objekt mit status und message zurückgegeben.
export async function deleteValue(id, apikey){
    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(process.env.REACT_APP_API_PATH + '/values/' + id, 'DELETE', headers)
}


//Alle Values von einem Produkt abfragen
//product_id: id des Produkts
export async function fetchValuesFromProduct(product_id){
    const params = new URLSearchParams()
    params.append('product', product_id)
    const paramsStr = '?' + params.toString()
    const uri = process.env.REACT_APP_API_PATH + '/values/' + (paramsStr !== '?' ? paramsStr : '')

    const headers = {
        "Content-Type": "application/json"
    }

    return await coreFetch(uri, 'GET', headers)
}