import { coreFetch } from './dbCoreFunc'


//Alle languages abfragen
//limit: nur die ersten n-Datensätze abfragen. Wenn nicht angegeben oder 0 werde alle Datensätze geliefert.
//search: Wird hier ein Wert angegeben, werden alle sinnvollen Spalten nach diesem Wert durchsucht. Ansonsten Null angeben.  
//return: ein Object, mit status, message, und der languages als results
export const fetchLanguages = async ( limit, search ) => {
    const params = new URLSearchParams()
    if(limit && limit>0){ params.append('limit', limit) }
    if(search){ params.append('search', "%" + search + "%") }
    const paramsStr = '?' + params.toString()
    const uri = process.env.REACT_APP_API_PATH + '/languages/' + (paramsStr !== '?' ? paramsStr : '')

    const headers = {
        "Content-Type": "application/json"
    }

    return await coreFetch(uri, 'GET', headers)
}


//Eine Language abfragen mit einer id
//id: id der language die abgefragt werden soll.
//return: ein Object, mit status, message, und der language als results
export const fetchLanguage = async ( id ) => {
    var uri = process.env.REACT_APP_API_PATH + '/languages/' + id;

    const headers = {
        "Content-Type": "application/json",
    }

    return await coreFetch(uri, 'GET', headers)
}


//Eine neue Language erstellen.
//return: Es wird eine Objekt mit status und message zurückgegeben.
export async function createLanguage(name, leo, symbol, apikey){
    const payload = {
        name: name,
        leo: leo,
        symbol: symbol
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(process.env.REACT_APP_API_PATH + '/languages/', 'POST', headers, JSON.stringify(payload))
}


//Eine Language ändern.
//id:   id der Language die geändert werden soll
//new{x}: die neuen Werte der language
//apikey: ein gültiger APIKEY, ansonsten kann der PUT-Request nicht durchgeführt werden.
//Es wird eine Objekt mit status und message zurückgegeben.
export async function editLanguage(id, name, leo, symbol, apikey){
    const payload = {
        name: name,
        leo: leo,
        symbol: symbol
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(process.env.REACT_APP_API_PATH + '/languages/' + id, 'PUT', headers, JSON.stringify(payload))
}


//Eine language löschen
//Es wird eine Objekt mit status und message zurückgegeben.
export async function deleteLanguage(id, apikey){
    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(process.env.REACT_APP_API_PATH + '/languages/' + id, 'DELETE', headers)
}


//Gibt an, ob die language mit der id die Systemsprache ist.
//Return: true wenn es die Systemsprache ist, ansonsten false.
export async function isSystem(id){
    const system = await coreFetch(process.env.REACT_APP_API_PATH + '/languages/?system', 'GET', {"Content-Type": "application/json"})
    if(system && system.results.id === id){
        return true
    }else{
        return false
    }
}


//Gibt die Systemsprache zurück.
export async function getSystemLanguage(){
    return await coreFetch(process.env.REACT_APP_API_PATH + '/languages/?system', 'GET', {"Content-Type": "application/json"})
}