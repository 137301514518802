import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";

const InfoThesaurus = ( {variant, ...other} ) => {
    const [headers, setHeaders] = useState([])

    useEffect(()=> {
        switch (variant) {
            case 'h2':
                setHeaders(['h2', 'h3', 'h4', 'h5', 'h6', 'h6'])
                break;
            case 'h3':
                setHeaders(['h3', 'h4', 'h5', 'h6', 'h6', 'h6'])
                break;
            case 'h4':
                setHeaders(['h4', 'h5', 'h6', 'h6', 'h6', 'h6'])
                break;
            case 'h5':
                setHeaders(['h5', 'h6', 'h6', 'h6', 'h6', 'h6'])
                break;
            case 'h6':
                setHeaders(['h6', 'h6', 'h6', 'h6', 'h6', 'h6'])
                break;
            default:
                setHeaders(['h1', 'h2', 'h3', 'h4', 'h5', 'h6'])
                break;
        }
    },[variant])

    return ( 
        <Box className="info-algorithmus" {...other}>
            <Typography variant={variant ? variant : headers[0]} mb={1}>Thesaurus</Typography>
            <Typography>Stellen wir uns vor, in der Eingabe steht nicht «Nennmoment», sondern nur «Moment» oder «Sollmoment». Dies könnte der Algorithmus also nicht einem Property mit dem Label «Nenndrehmoment» zuweisen. Um dies zu realisieren wird der Thesaurus verwendet.</Typography>
            <Typography mt={2}>Hier können alternative Begriffe verwendet werden. Man könnte also einen Thesaurus-Eintrag in der Sprache Deutsch anlegen, mit dem «original»-Eintrag «Nenndrehmoment» welches beispielsweise dem Label eines Property entspricht und als «alternatives» wird dann «Moment, Solldrehmoment» etc.  eingetragen. So wird der Algorithmus nicht nur «Nenndrehmoment» in der Eingabe finden, sondern auch Bsp. «Moment 14Nm».</Typography>
            <Typography mt={2}>Thesaurus Einträge sind auch für Properties mit der Option «Values mit einbeziehen» möglich. Wenn also nicht nach dem Property-Label sondern nach den im Produkt (bei dem Property) eingegebenen Werten gesucht wird. Hat man als Beispiel das Property «Getriebeart» und in den Produkten bei diesem Property die Values «Planetengetriebe, Schneckengetriebe» würde in der Eingabe «Planet mit 12Nm» der Text Planet nicht dem Property Getriebeart zugewiesen. Es kann aber im Thesaurus zum Original «Planetengetriebe» (der Eintrag im Produkt) die Alternativen «Planet, Planeten-Getriebe, Umlaufrädergetriebe» ergänzt werden.</Typography>
            <Typography variant={headers[1]}>API</Typography>
            <Typography><code>alternatives</code> enthällt eine Auflistung von Wörtern, getrennt durch Komma (,). Soll in einem der Wörter ein Komma enthalten sein, muss dieses einem Backslash vorangehen (\,)</Typography>
        </Box>
     );
}
 
export default InfoThesaurus;