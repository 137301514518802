import CancelIcon from '@mui/icons-material/Cancel'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { useEffect } from 'react'
import { useState } from 'react'
import { fetchProperties } from '../includes/dbPropertiesFunc'
import { Stack, Autocomplete, TextField, Typography, IconButton } from '@mui/material'
import WizardLabel from '../components/WizardLabel'
import WizardInputField from '../components/WizardInputField'

const delay = t => new Promise(resolve => setTimeout(resolve, t));


const WizardProperties = ({ fieldWidth, focusFieldWidth, labelWidth, languages, properties, setProperties }) => {
    const [ propertyOptions, setPropertyOptions ] = useState([])


    useEffect(() => {
        (async () => {
            const answer = await fetchProperties()
            setPropertyOptions(answer.results)
        })();
    },[])

    const handleAddProperty = () => {
        setProperties((old) => [...old, {}])
    }


    const handleChangeProperty = ( index, element, change) => {
        setProperties(
            properties.map((prop, i) => {
                if(i === index){
                    prop[element] = change
                    return prop
                }else{
                    return prop
                }
            })
        )
    }

    const handleDeleteProperty = async ( index ) => {
        const newProperties = properties.filter((e, i) => i !== index)

        setProperties( [] )
        await delay(1)
        setProperties( newProperties )
    }


    return ( 
        <Stack direction='column' spacing={3} alignItems='flex-start'>

            <div style={{whiteSpace: 'nowrap', marginTop: '40px'}}>
                <WizardLabel  labelWidth={labelWidth} color='primary' variant='h6'>Properties</WizardLabel>
                <Typography component='span' color='primary' mb={2} sx={{width: fieldWidth, display: 'inline-block'}}>Property</Typography>
                <Typography component='span' color='primary' mb={2} sx={{width: fieldWidth, display: 'inline-block'}}>Value</Typography>
                {languages && 
                    languages.map((language, index) => (
                        <Typography key={index} component='span' color='primary' mb={2} sx={{width: fieldWidth, display: 'inline-block'}}>Value-{language.symbol}</Typography>
                    ))
                }
            </div>

            {properties &&
                properties.map((property, index) => (
                    <div key={index} style={{whiteSpace: 'nowrap'}}>
                        <WizardLabel labelWidth={labelWidth}>
                            <IconButton color='warning' onClick={(e) => handleDeleteProperty( index )}>
                                <CancelIcon />
                            </IconButton>
                        </WizardLabel>

                        <Autocomplete
                            sx={{width: fieldWidth + 'px', display: 'inline-block'}}
                            value={ property.property ? property.property : null }
                            options={ propertyOptions }
                            getOptionLabel={(option) => option.label_source + ' (' + option.ptype_name + ')' ?? option}
                            onChange={(e, propOpt) => {
                                setProperties(
                                    properties.map((el, i) => {
                                        if(i === index){
                                            property.property = propOpt
                                            return property
                                        }else{
                                            return el
                                        }
                                    })
                                )
                            }}
                            isOptionEqualToValue={(option, value) => {
                                return !value ? true : option.name === value.name
                            }}                                    
                            renderInput={(params) => <TextField
                                                        {...params}
                                                        autoComplete='off'
                                                        variant='standard'
                                                        size='small'
                                                        margin='none'
                                                        required
                                                    />}
                        />

                        <WizardInputField
                            fieldWidth={fieldWidth}
                            focusFieldWidth={focusFieldWidth}
                            value={ property.value ? property.value: ''}
                            onChange={(e) => handleChangeProperty(index, 'value', e.target.value)}
                        />
                        {languages && 
                            languages.map((language, i) => (
                                <WizardInputField
                                    fieldWidth={fieldWidth}
                                    focusFieldWidth={focusFieldWidth}
                                    key={i}
                                    value={
                                        language.system ? (
                                            property['valuesource'] ? property['valuesource']: ''
                                        ) : (
                                            property['valuesource_' + language.symbol] ? property['valuesource_' + language.symbol]: ''
                                        )
                                    }
                                    onChange={(e) => {
                                        language.system ? (
                                            handleChangeProperty(index, 'valuesource', e.target.value)
                                        ) : (
                                            handleChangeProperty(index, 'valuesource_' + language.symbol, e.target.value)
                                        )
                                    }}
                                />
                            ))
                        }
                    </div>
                ))
            }
            <IconButton color='primary' onClick={handleAddProperty}>
                <AddCircleIcon/>
            </IconButton>

        </Stack>
     );
}
 
export default WizardProperties;