import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import RefreshIcon from '@mui/icons-material/Refresh'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'
import { FormGroup, FormControlLabel, Checkbox, Autocomplete, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, IconButton, Paper, Stack, TextField, Typography, Alert } from '@mui/material'
import { createProperty, deleteProperty, editProperty, fetchProperties, fetchProperty } from '../includes/dbPropertiesFunc'
import { fetchPtypes } from "../includes/dbPtypesFunc";
import { DataGrid } from "@mui/x-data-grid"
import { AuthContext } from '../components/AuthProvider'
import { useSnackbar } from 'notistack'
import { useContext, useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import SourceTextfield from '../components/SourceTextfield'
import { checkSource } from '../includes/dbSourcesFunc'
import InfoAlgorithmus from '../components/InfoAlgorithmus'
import InfoThesaurus from '../components/InfoThesaurus'


const columns = [
    { field: 'id', headerName: 'id', width: 50 },   
    { field: 'label_source', headerName: 'Label', width: 200 },
    { field: 'ptype_name', headerName: 'PType', width: 200 },
    { field: 'unit', headerName: 'Unit', width: 150 },
    { field: 'group_source', headerName: 'Gruppe', width: 150 },
    { field: 'priority', headerName: 'Prio', width: 70 },
    { field: 'usevalues', headerName: 'useValues', width: 100 },
    { field: 'useunit', headerName: 'useUnit', width: 100 }
];


const Propertiespage = () => {
    const { id: urlId } = useParams()   //ID aus der URL.
    const { enqueueSnackbar } = useSnackbar()
    const { apikey, isLoggedin } = useContext(AuthContext)
    const { data, isLoading, refetch} = useQuery(['properties'], () => fetchProperties(0))
    const [selectionModel, setSelectionModel] = useState([])
    const [mode, setMode] = useState('')
    const [ptypes, setPtypes] = useState([])
    const [selectedPtype, setSelectedPtype] = useState(null)
    const [label, setLabel] = useState('')
    const [values, setValues] = useState({})
    const [errors, setErrors] = useState({})
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    

    useEffect(() => {
        (async () => {
            const data = await fetchPtypes()
            setPtypes(data.results)
        })();

        if(urlId){
            //Beim Aufruf der Seite wurde in der URL eine ID angegeben. Wir wollen diese nun zum bearbeiten anzeigen.
            handleEdit( urlId )            
        }
    // eslint-disable-next-line
    },[])


    //Den angegebenen Datensatz im Drawer bearbeiten
    const handleEdit = async ( id ) => {
        const row = await fetchProperty(id)
        if(row && row.status === 1){
            if(row.count === 1){
                setValues(row.results)
                setLabel(row.results.label_source)
                if(ptypes){
                    const ptype = ptypes.find(e => e.id === row.results.ptype_id )
                    setSelectedPtype(ptype)
                }                
                handleOpenDrawer('edit')
            }else{
                enqueueSnackbar('Es existiert kein Property mit der Id ' + id, {variant: 'warning'})
            }
        }else{
            enqueueSnackbar('Fehler bei der Abfrage der Datenbank.', {variant: 'error'})
        }
    }
    

    //Den Drawer öffnen. Als mode entweder create oder edit angeben. Sollte edit gewählt werden, muss der zu beareitende Datensatz in values angegeben werden.
    const handleOpenDrawer= (mode) => {
        if(mode==='create'){
            setValues({label_id: null, ptype_id: null, unit: '', group: '', priority: 999999, usevalues: 0, useunit: 0})  //Die in den Inputs angezeigten Werte rücksetzen (ein leeres Object führt zu einem Fehler).
            setSelectedPtype(null)
            setErrors({})
        }else if(mode === 'edit'){
            //Die values wurden schon bei handleRowClick gesetzt, weil wir da die id kennen.
            setErrors({})
        }else{
            enqueueSnackbar('Unbekannter Modus.', {variant: 'error'})
            return
        }
        setMode(mode)
        setIsDrawerOpen(true)
    }
    

    //Drawer (rechte Seite) schliessen.
    const handleCloseDrawer = () =>{
        setLabel(null)
        setIsDrawerOpen(false)
    }


    //Kontrolliert die Ausgaben und gibt true/false zurück, ausserdem werden die errors-Werte gesetzt, für die einzelenen Eingabefelder.
    const handleCheck = async () => {
        let intcheck = /[^0-9]/;

        let collectErrors = {}
        if(!label){
            collectErrors.label_id = 'Label eingeben.'
        }

        if(selectedPtype === null){
            collectErrors.ptype_id = 'Es muss ein PType ausgewählt werden.'
        }

        if(!values.priority || values.priority === '' || intcheck.test(values.priority)){
            collectErrors.priority = 'Muss eine ganze Zahl sein.'
        }
        
        setErrors({
            ...collectErrors
        })

        return Object.keys(collectErrors).length === 0
    }


    //Datensatz erstellen, oder einen bestehenden ändern.
    const handleSave = async () => {
        const ptype_id = selectedPtype.id

        if(mode === 'create'){
            //Kontrollieren, ob Source für Label schon existiert, ansonsten anlegen. Es wird entweder die id der Source oder False zurückgegeben.
            const label_id = await checkSource(label, apikey)
            const group_id = await checkSource(values.group_source ? values.group_source : null, apikey)
            if(label_id){
                //Neues Property erstellen.
                const res = await createProperty(label_id, ptype_id, values.unit, group_id, values.priority, values.usevalues, values.useunit, apikey)
                if(res && res.status === 1){
                    enqueueSnackbar("Property erfolgreich angelegt.", {variant: 'success'})
                    handleCloseDrawer()
                    refetch()
                }else{
                    enqueueSnackbar(res.message, {variant: 'error'})
                }
            }else{
                enqueueSnackbar('Source für Label konnte nicht erstellt werden.', {variant: 'error'})
            }



        }else if(mode === 'edit'){
            //Kontrollieren, ob Source für Label schon existiert, ansonsten anlegen. Es wird entweder die id der Source oder False zurückgegeben.
            const label_id = await checkSource(label, apikey)
            const group_id = await checkSource(values.group_source ? values.group_source : null, apikey)
            if(label_id ){
                //Property ändern.
                const res = await editProperty(values.id, label_id, ptype_id, values.unit, group_id, values.priority, values.usevalues, values.useunit, apikey)
                if(res && res.status === 1){
                    enqueueSnackbar("Property erfolgreich geändert.", {variant: 'success'})
                    handleCloseDrawer()
                    refetch()
                }else{
                    enqueueSnackbar(res.message, {variant: 'error'})
                }
            }else{
                enqueueSnackbar('Source für Label konnte nicht erstellt werden.', {variant: 'error'})
            }
        }else{
            enqueueSnackbar('Unbekannter Modus.', {variant: 'error'})
        }
    }

    
    //Einen Datensatz löschen
    const handleDelete = async (id) => {
        const result = await deleteProperty(id, apikey)
        if(result.status === 0){
            enqueueSnackbar(result.message, {variant: "error"})
            return
        }else{
            enqueueSnackbar("Property erfolgreich gelöscht.", {variant: "success"})
            handleCloseDrawer()
            refetch()
            return
        }   
    }


    //Alle ausgewählten Zeilen löschen
    const handleDeleteChecked = async () => {
        //selectionModel enthällt eine Liste der id mit allen selectierten Zeilen. Wieso id als Standard verwendet wird, keine Ahnung!
        for(const id of selectionModel){
            const result = await deleteProperty(id, apikey)
            if(result.status === 0){
                enqueueSnackbar(result.message, {variant: "error"})
            }
        }
        refetch()
    }



    return (
        <Container>
            <Typography variant="h1">
                Properties
            </Typography>
            { isLoggedin() &&
                <div>
                    <div className="data">
                        <div className="data-table-toolbar">
                            <Stack direction='row' spacing={1}  justifyContent="flex-start" alignItems="baseline">
                                <IconButton color="primary" onClick={() => handleOpenDrawer('create')}>
                                    <AddIcon />
                                </IconButton>
                                <IconButton color="warning" onClick={() => setIsDialogOpen(true)} disabled={selectionModel.length === 0}>
                                    <DeleteIcon />
                                </IconButton>
                                <IconButton color="primary" onClick={refetch}>
                                    <RefreshIcon />
                                </IconButton>
                                { isLoading && <HourglassBottomIcon color='warning' />}
                            </Stack>
                            <div className="data-table" style={{ height: 650, width: '100%' }}>
                                <DataGrid
                                    density="compact"
                                    rows={data ? data.results : []}
                                    columns={columns}
                                    disableSelectionOnClick
                                    checkboxSelection
                                    pageSize={15}
                                    rowsPerPageOptions={[15]}                    
                                    disableColumnFilter
                                    onRowClick={(e) => handleEdit(e.id)}
                                    onSelectionModelChange={(newModel) => setSelectionModel(newModel)}
                                    selectionModel={selectionModel}
                                />                            
                            </div>
                        </div>
                    </div>
                    <Paper sx={{p: 3}}>
                        <Typography variant='h3'>Infos</Typography>
                        <Typography>
                            Ein Property ist Beispielsweise das Nenndrehmoment. Dieses hat eine Einheit (Nm) und eine Beschriftung/Label (Nenndrehmoment) welche übersetzt werden muss, deshalb ein Source.
                            Ausserdem ein PType, um festzulegen, wie dieses Property in den Filtern dargestellt wird.
                        </Typography>

                        <InfoAlgorithmus variant='h4' sx={{mt: 5}}/>
                        <InfoThesaurus variant='h4' sx={{mt: 5}}/>
                    </Paper>
                </div>
            }
            { !isLoggedin() && <Typography variant='h3'>Keine Berechtigung</Typography> }
            <Drawer className='drawer'
                PaperProps={{ sx: {width: { xs: 1, sm: 0.4 }} }}
                anchor="right"
                open={isDrawerOpen}
                onClose={handleCloseDrawer}
            >
                <div className="drawer-content">
                    <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="baseline">
                        <IconButton onClick={handleCloseDrawer}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <Typography variant='h5' gutterBottom color="primary">
                        { mode === "edit" && 'Property ändern...'}
                        { mode === "create" && 'Neuen Property anlegen...'}
                    </Typography>
                    <Paper
                        elevation={0}
                        component="form"
                        autoComplete="off"
                    >
                        <Stack spacing={4}>
                            <Stack spacing={2} alignItems='flex-start'>
                                { mode === 'edit' && <TextField variant="standard" label="id" type="text" disabled fullWidth value={ values.id }/> }

                                <SourceTextfield
                                    label="Label"
                                    required={true}
                                    fullWidth
                                    value={ label }
                                    helperText={ errors.label_id }
                                    onChange={(n) => setLabel(n)}
                                />

                                <Autocomplete
                                    id="ptype"
                                    fullWidth
                                    value={ selectedPtype ? selectedPtype : '' }
                                    options={ ptypes }
                                    getOptionLabel={(option) => option.name ?? option}
                                    onChange={(e, ptype) => {
                                        setSelectedPtype( ptype )
                                    }}
                                    isOptionEqualToValue={(option, value) => {
                                        return !value ? true : option.name === value.name
                                    }}
                                    renderInput={(params) => <TextField
                                                                {...params}
                                                                variant='standard'
                                                                label="PType"
                                                                required
                                                                helperText={ errors.ptype_id }
                                                                error={ errors.ptype_id ? true : false }
                                                            />}
                                />
                                <TextField
                                    variant='standard'
                                    label='Unit'
                                    id='unit'
                                    fullWidth
                                    helperText={ errors.unit }
                                    error= { errors.unit ? true : false  }
                                    value={ values.unit ? values.unit : '' }
                                    onChange={(e) => {
                                        setValues(prev =>({
                                            ...prev,
                                            unit: e.target.value
                                        }))
                                    }}
                                />
                                <SourceTextfield
                                    label="Gruppe"
                                    required={false}
                                    fullWidth
                                    value={ values.group_source }
                                    helperText={ errors.group_source }
                                    onChange={(n) => {
                                        setValues(prev =>({
                                            ...prev,
                                            group_source: n
                                        }))
                                    }}
                                />
                                <TextField
                                    variant='standard'
                                    label='Priority'
                                    id='priority'
                                    fullWidth
                                    helperText={ errors.priority }
                                    error= { errors.priority ? true : false  }
                                    value={ values.priority ? values.priority : '' }
                                    onChange={(e) => {
                                        setValues(prev =>({
                                            ...prev,
                                            priority: e.target.value
                                        }))
                                    }}
                                />
                                <FormGroup>
                                    <FormControlLabel label="Values mit einbeziehen." control={
                                        <Checkbox
                                            id="usevalues"
                                            checked={ values.usevalues === 1 ? true : false }
                                            onChange={(e) => {
                                                const newValue = e.target.checked === true ? 1 : 0
                                                setValues(prev =>({
                                                    ...prev,
                                                    usevalues: newValue
                                                }))
                                            }}
                                        />
                                    } />
                                </FormGroup>
                                <FormGroup>
                                    <FormControlLabel label="Property als Standard für diese Einheit" control={
                                        <Checkbox
                                            id="useunit"
                                            checked={ values.useunit === 1 ? true : false }
                                            onChange={(e) => {
                                                const newValue = e.target.checked === true ? 1 : 0
                                                setValues(prev =>({
                                                    ...prev,
                                                    useunit: newValue
                                                }))
                                            }}
                                        />
                                    } />
                                </FormGroup>
                            </Stack>
                            <Stack direction='row' spacing={1}>
                                <Button variant='contained' type='submit'
                                    onClick={async (e) => {
                                        e.preventDefault()
                                        const res = await handleCheck()
                                        res && handleSave()
                                    }}
                                >Save</Button>
                                <Button variant='contained' type='reset' onClick={handleCloseDrawer}>Cancel</Button>
                                { mode==='edit' &&
                                    <Button variant='contained' color="warning"
                                        onClick={() => {
                                            handleDelete( values.id )
                                        }}
                                    >Delete</Button>
                                }
                            </Stack>
                            { mode === 'edit' &&
                                <Alert severity="warning">
                                    Wenn dieses Property in einem Value genutzt wird, kann er nicht mehr gelöscht werden.
                                </Alert>
                            }
                        </Stack>
                    </Paper>
                </div>
            </Drawer>
            <Dialog open={isDialogOpen}>
                <DialogTitle>Löschen</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Wollen Sie den ausgewählten Property wirklich löschen?
                    </DialogContentText>
                    <Alert severity="warning">
                        Wenn dieser Property in einem Value genutzt wird, kann er nicht mehr gelöscht werden.
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color='warning'
                        onClick={() => {
                            handleDeleteChecked()
                            setIsDialogOpen(false)
                        }}
                    >Ok</Button>
                    <Button onClick={() => setIsDialogOpen(false)}>Abbruch</Button>
                </DialogActions>
            </Dialog>
        </Container>
     );
}
 
export default Propertiespage;