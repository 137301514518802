import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import RefreshIcon from '@mui/icons-material/Refresh'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'
import { InputAdornment, FormControl, InputLabel, Select, MenuItem, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, IconButton, Paper, Stack, TextField, Typography, Alert, Tab } from '@mui/material'
import { createOptiongroup, deleteOptiongroup, editOptiongroup, fetchOptiongroup, fetchOptiongroups } from '../includes/dbOptiongroupsFunc'
import { DataGrid } from "@mui/x-data-grid"
import { AuthContext } from '../components/AuthProvider'
import { useSnackbar } from 'notistack'
import { useContext, useState, useEffect, useRef, useCallback } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import debounce from "lodash.debounce"
import { TabContext, TabList, TabPanel } from '@mui/lab'
import OptionsgroupOptions from '../components/OptionsgroupOptions'
import OptionControl from '../components/OptionControl'


const columns = [
    { field: 'id', headerName: 'id', width: 50 },
    { field: 'name', headerName: 'Name', width: 200 }
];

const Optionspage = () => {
    const [rowsCount, setRowsCount] = useState(15)
    const [search, setSearch] = useState('')
    const { id: urlId } = useParams()   //ID aus der URL.
    const { enqueueSnackbar } = useSnackbar()
    const { apikey, isLoggedin } = useContext(AuthContext)
    const { data, isLoading, refetch} = useQuery(['optiongroupss', rowsCount, search], () => fetchOptiongroups(rowsCount, search))
    const [selectionModel, setSelectionModel] = useState([])
    const [mode, setMode] = useState('')
    const [values, setValues] = useState({})
    const [errors, setErrors] = useState({})
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [selectedTab, setSelectedTab] = useState('form')
    const searchInput = useRef(null)


    useEffect(() => {
        if(urlId){
            //Beim Aufruf der Seite wurde in der URL eine ID angegeben. Wir wollen diese nun zum bearbeiten anzeigen.
            handleEdit( urlId )            
        }
    // eslint-disable-next-line
    },[])


    //Den angegebenen Datensatz im Drawer bearbeiten
    const handleEdit = async ( id ) => {
        const row = await fetchOptiongroup(id)
        if(row && row.status === 1){
            if(row.count === 1){
                setValues(row.results)
                handleOpenDrawer('edit')
            }else{
                enqueueSnackbar('Es existiert kein OptionGruppe mit der Id ' + id, {variant: 'warning'})
            }
        }else{
            enqueueSnackbar('Fehler bei der Abfrage der Datenbank.', {variant: 'error'})
        }
    }


    //Den Drawer öffnen. Als mode entweder create oder edit angeben. Sollte edit gewählt werden, muss der zu beareitende Datensatz in values angegeben werden.
    const handleOpenDrawer= (mode) => {
        if(mode==='create'){
            setValues({name: ''})  //Die in den Inputs angezeigten Werte rücksetzen (ein leeres Object führt zu einem Fehler).
            setErrors({})
        }else if(mode === 'edit'){
            //Die values wurden schon bei handleRowClick gesetzt, weil wir da die id kennen.
            setErrors({})
        }else{
            enqueueSnackbar('Unbekannter Modus.', {variant: 'error'})
            return
        }
        setMode(mode)
        setIsDrawerOpen(true)
    }
    

    //Drawer (rechte Seite) schliessen.
    const handleCloseDrawer = () =>{
        setIsDrawerOpen(false)
    }


    //Kontrolliert die Ausgaben und gibt true/false zurück, ausserdem werden die errors-Werte gesetzt, für die einzelenen Eingabefelder.
    const handleCheck = async () => {
        let collectErrors = {}

        if(!values.name){
            collectErrors.name = 'Name eingeben.'
        }

        setErrors({
            ...collectErrors
        })

        return Object.keys(collectErrors).length === 0
    }


    //Datensatz erstellen, oder einen bestehenden ändern.
    const handleSave = async () => {
        if(mode === 'create'){
            //Neue Optiongruppe erstellen.
            const res = await createOptiongroup(values.name, apikey)
            if(res && res.status === 1){
                enqueueSnackbar("Optiongroup erfolgreich angelegt.", {variant: 'success'})
                refetch()
                await handleEdit(res.id)                
            }else{
                enqueueSnackbar(res.message, {variant: 'error'})
            }
        }else if(mode === 'edit'){
            //Optiongroup ändern.
            const res = await editOptiongroup(values.id, values.name, apikey)
            if(res && res.status === 1){
                enqueueSnackbar("Optiongroup erfolgreich geändert.", {variant: 'success'})
                handleCloseDrawer()
                refetch()
            }else{
                enqueueSnackbar(res.message, {variant: 'error'})
            }
        }else{
            enqueueSnackbar('Unbekannter Modus.', {variant: 'error'})
        }
    }


    //Einen Datensatz löschen
    const handleDelete = async (id) => {
        const result = await deleteOptiongroup(id, apikey)
        if(result.status === 0){
            enqueueSnackbar(result.message, {variant: "error"})
            return
        }else{
            enqueueSnackbar("Optiongroup erfolgreich gelöscht.", {variant: "success"})
            handleCloseDrawer()
            refetch()
            return
        }   
    }


    //Alle ausgewählten Zeilen löschen
    const handleDeleteChecked = async () => {
        //selectionModel enthällt eine Liste der id mit allen selectierten Zeilen. Wieso id als Standard verwendet wird, keine Ahnung!
        for(const id of selectionModel){
            const result = await deleteOptiongroup(id, apikey)
            if(result.status === 0){
                enqueueSnackbar(result.message, {variant: "error"})
            }
        }
        refetch()
    }


    //Die Auswahl in der Tabelle aufgrund des Suchtextes einschränken.
    const handleSearch = ( text ) => {
        searchInput.current.value = text
        debounceSearch(text)
    }


    //Filtert die Tabelle aufgrund des Suchkriteriums, aber erst nach 500ms. Solange wird auf weitere Eingaben gewartet. Reduziert die Anzahl Anfragen.
    // eslint-disable-next-line
    const debounceSearch = useCallback(
        debounce( (value) => {
            setSearch( value )
        }, 500)
    ,[])



    return ( 
        <Container>
            <Typography variant="h1">
                Options
            </Typography>
            { isLoggedin() &&
                <div>
                    <Stack spacing={2} className="data">
                        <Stack direction='row' spacing={1}  justifyContent="flex-start" alignItems="baseline" className="data-table-search">
                            <TextField
                                variant='outlined'
                                label='Suche'
                                fullWidth
                                inputRef={ searchInput }
                                onChange={ (e) => debounceSearch(e.target.value) }
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton onClick={() => handleSearch('')}>
                                                <CloseIcon fontSize='small'/>
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <FormControl sx={{ width: 200 }}>
                                <InputLabel>Rows</InputLabel>
                                <Select
                                    label="Rows"
                                    value={rowsCount}
                                    onChange={(e) => setRowsCount(e.target.value)}
                                >
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={15}>15</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={30}>30</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                    <MenuItem value={100}>100</MenuItem>
                                    <MenuItem value={0}>Alle</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                        <Stack direction='row' spacing={1}  justifyContent="flex-start" alignItems="baseline">
                            <IconButton color="primary" onClick={() => handleOpenDrawer('create')}>
                                <AddIcon />
                            </IconButton>
                            <IconButton color="warning" onClick={() => setIsDialogOpen(true)} disabled={selectionModel.length === 0}>
                                <DeleteIcon />
                            </IconButton>
                            <IconButton color="primary" onClick={refetch}>
                                <RefreshIcon />
                            </IconButton>
                            { isLoading && <HourglassBottomIcon color='warning' />}
                        </Stack>
                        <div className="data-table" style={{ height: 650, width: '100%' }}>
                            <DataGrid
                                density="compact"
                                rows={data ? data.results : []}
                                columns={columns}
                                disableSelectionOnClick
                                checkboxSelection
                                pageSize={15}
                                rowsPerPageOptions={[15]}                    
                                disableColumnFilter
                                onRowClick={(e) => handleEdit(e.id)}
                                onSelectionModelChange={(newModel) => setSelectionModel(newModel)}
                                selectionModel={selectionModel}
                            />                            
                        </div>
                    </Stack>
                    <Paper sx={{p: 3}}>
                        <Typography variant='h4'>Infos</Typography>
                        <Typography>Optionen können einem Produkt zugewiesen werden. Diese werden bei einer Anfrage oder Bestellung angezeigt und können Varianten, etc. abbilden.</Typography>
                        <Typography>Beispielsweise für ein Schneckengetriebe müssten Angaben über die Abtriebswelle (Hohlwelle, Beidseitig,) zusätzlich gemacht werden.</Typography>
                        <Typography>Bei einem Planetengetriebe ob der Motor angebaut wird, oder ob eine Kupplung, Eingangswelle gewünscht wird.</Typography>
                        <Typography variant='h5' mt={4}>Mögliche Angaben</Typography>
                        <Typography>Optionen werden als Code-String angegeben. Dieser Beschreibt zum einen die Darstellung wie auch die möglichen Optionen.</Typography>
                        <Typography>Da die Optionen Text enthalten (zum einten das Label abder auch mögliche Optionen) wird jeder Option eine Sprache zugewiesen.</Typography>
                        <Typography>Es werden NICHT die sources verwendet (zu aufwendig in der Umsetzung).</Typography>
                        <Typography>Folgende Option-Codezeilen sind möglich:</Typography>
                        <Typography variant='h6' mt={2}>Text oder Nummer-Eingabefeld</Typography>
                        <Typography color='primary'>input[[t][15]]</Typography>
                        <OptionControl option='input[[t][15]]' />
                        <Typography>Erster Parameter [t] erzeugt ein Textfeld für Eingabe von Text.</Typography>
                        <Typography>Zweiter Parameter legt die maximale Anzahl Zeichen die eingegeben werden können fest. Dieser kann auch weggelassen werden, dann sind unbegrenzte Eingaben möglich.</Typography>
                        <Typography color='primary'>input[[n][25,100]]</Typography>
                        <OptionControl option='input[[n][25,100]]' />
                        <Typography>Erster Parameter [n] erzeugt ein Textfeld zur Eingabe von Zahlen.</Typography>
                        <Typography>Zweiter Parameter kann entweder weggelassen werden oder hat 1 oder 2 Werte. Bei einem Wert ist dies der maximale Wert, bei zwei Werten ist der Erste das Minimum und der zweite das Maximum</Typography>
                        <Typography variant='h6' mt={2}>Dropdown-Auswahl</Typography>
                        <Typography color='primary'>select[[A,B,C,15]]</Typography>
                        <OptionControl option='select[[A,B,C,15]]'/>
                        <Typography>Es ist nur ein Parameter zulässig. Dieser entällt eine mit Komma getrennte Liste aller Auswahlmöglichkeiten.</Typography>
                        <Typography variant='h6' mt={2}>Multiselect-Auswahl</Typography>
                        <Typography color='primary'>multiselect[[A,B,C,15]]</Typography>
                        <OptionControl option='multiselect[[A,B,C,15]]'/>
                        <Typography>Es ist nur ein Parameter zulässig. Dieser entällt eine mit Komma getrennte Liste aller Auswahlmöglichkeiten.</Typography>
                        <Typography variant='h6' mt={2}>Option-Buttons</Typography>
                        <Typography color='primary'>radio[[A,B,C,15]]</Typography>
                        <OptionControl option='radio[[A,B,C,15]]'/>
                        <Typography>Es ist nur ein Parameter zulässig. Dieser entällt eine mit Komma getrennte Liste aller Auswahlmöglichkeiten.</Typography>
                        <Typography variant='h6' mt={2}>Check-Buttons</Typography>
                        <Typography color='primary'>check[[A,B,C,15]]</Typography>
                        <OptionControl option='check[[A,B,C,15]]'/>
                        <Typography>Es ist nur ein Parameter zulässig. Dieser entällt eine mit Komma getrennte Liste aller Auswahlmöglichkeiten.</Typography>
                        <Typography variant='h6' mt={2}>Slider</Typography>
                        <Typography color='primary'>slider[[20]]</Typography>
                        <OptionControl option='slider[[20]]'/>
                        <Typography>Erster Parameter kann ein oder zwei Werte entalten. Wird nur einer angegeben, ist dies der Maximalwert. Minimal wird mit 0 angenommen.</Typography>
                        <Typography>Werden zwei Werte angegeben slider[[120,1400]] ist der erste Wert der Minimalwert, der zweite der Maximalwert</Typography>
                    </Paper>
                </div>
            }
            { !isLoggedin() && <Typography variant='h3'>Keine Berechtigung</Typography> }
            <Drawer className='drawer'
                PaperProps={{ sx: {width: { xs: 1, sm: 0.4 }} }}
                anchor="right"
                open={isDrawerOpen}
                onClose={handleCloseDrawer}
            >
                <div className="drawer-content">
                    <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="baseline">
                        <IconButton onClick={handleCloseDrawer}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <Typography variant='h5' gutterBottom color="primary">
                        { mode === "edit" && 'Optiongroup ändern...'}
                        { mode === "create" && 'Neue Optiongroup anlegen...'}
                    </Typography>

                    <TabContext value={ selectedTab }>
                        <TabList onChange={(e,n) => setSelectedTab(n)}>
                            <Tab value='form' label='Eingabe'/>
                            <Tab value='options' disabled={ mode === 'create' } label='Optionen'/>
                        </TabList>
                        <TabPanel value='form'>
                            <Stack spacing={4}>
                                <Stack spacing={2} alignItems='flex-start'>
                                    { mode === 'edit' && <TextField variant="standard" label="id" type="text" disabled fullWidth value={ values.id }/> }
                                    <TextField
                                        variant='standard'
                                        label='Name'
                                        id='name'
                                        required
                                        fullWidth
                                        helperText={ errors.name }
                                        error= { errors.name ? true : false  }
                                        value={ values.name }
                                        onChange={(e) => {
                                            setValues(prev =>({
                                                ...prev,
                                                name: e.target.value
                                            }))
                                        }}
                                    />
                                    </Stack>
                                    <Stack direction='row' spacing={1}>
                                        <Button variant='contained' type='submit'
                                            onClick={async (e) => {
                                                e.preventDefault()
                                                const res = await handleCheck()
                                                res && handleSave()
                                            }}
                                        >Save</Button>
                                        <Button variant='contained' type='reset' onClick={handleCloseDrawer}>Cancel</Button>
                                        { mode==='edit' &&
                                            <Button variant='contained' color="warning"
                                                onClick={() => {
                                                    handleDelete( values.id )
                                                }}
                                            >Delete</Button>
                                        }
                                    </Stack>
                                    { mode === 'edit' &&
                                        <Alert severity="warning">
                                            Wenn diese Optionsgruppe in einer Product genutzt wird, kann sie nicht mehr gelöscht werden.
                                        </Alert>
                                    }
                            </Stack>
                        </TabPanel>
                        <TabPanel value='options'>
                            <OptionsgroupOptions group_id={values.id} apikey={apikey} />
                        </TabPanel>
                    </TabContext>
                </div>
            </Drawer>
            <Dialog open={isDialogOpen}>
                <DialogTitle>Löschen</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Wollen Sie die ausgewählten Optiongroups wirklich löschen?
                    </DialogContentText>
                    <Alert severity="warning">
                        Wenn diese Optionsgruppe in einer Product genutzt wird, kann sie nicht mehr gelöscht werden.
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color='warning'
                        onClick={() => {
                            handleDeleteChecked()
                            setIsDialogOpen(false)
                        }}
                    >Ok</Button>
                    <Button onClick={() => setIsDialogOpen(false)}>Abbruch</Button>
                </DialogActions>
            </Dialog>
        </Container>
     );
}

export default Optionspage;

