import DeleteIcon from '@mui/icons-material/Delete'
import { FormGroup, FormControlLabel, Checkbox, Autocomplete, Box, Tooltip, Skeleton, Button, IconButton, Paper, Stack, TextField, Typography, Alert } from '@mui/material'
import { useState } from 'react'
import { useEffect } from 'react'
import SourceTextfield from '../components/SourceTextfield'
import { fetchOptiongroups } from '../includes/dbOptiongroupsFunc'



const ProductInput = ( {mode, values, errors, warnings, imgRefImage, setValues, setWarnings, setImagedialogfor, setimageDialogOpen, handleCloseDrawer, handleCheck, handleSave, handleDelete } ) => {
    const [options, setOptions] = useState([])
    const [selectedOption, setSelectedOption] = useState(null)


    useEffect(() =>{
        (async () => {
            const answer = await fetchOptiongroups()
            setOptions(answer.results)
        })();
    },[])


    useEffect(() => {
        if(values){
            if(values.optiongroup_id){
                const option = options.find(e => e.id === values.optiongroup_id )
                if(selectedOption !== option){
                    setSelectedOption(option)
                }
            }else if(selectedOption){
                setSelectedOption(null)
            }
        }
    },[values, options, selectedOption])


    return (
        <Paper
            elevation={0}
            component="form"
            autoComplete="off"
        >
            <Stack spacing={4}>
                <Stack spacing={2} alignItems='flex-start'>
                    { mode === 'edit' && <TextField variant="standard" label="id" type="text" disabled fullWidth value={ values.id }/> }

                    <Box display='flex' alignItems='self-start' flexDirection='column' sx={{p: 2, borderRadius: 2, border: '1px solid silver'}}>
                        <Typography variant='body1' mb={1}>Produktbild</Typography>
                        <Box display='flex' alignItems='self-start' flexDirection='row'>
                            <Button
                                onClick={() => {
                                    setImagedialogfor('image')
                                    setimageDialogOpen(true)
                                }}
                            >
                                { values.image_path ?
                                    <Tooltip
                                        title={
                                            <>
                                            <p>{values.image_name}</p>
                                            <p>{values.image_description}</p>
                                            <Button variant='contained' href={'/files/' + values.image_id}>go to</Button>
                                            </>
                                        }
                                    >
                                        <img
                                            onLoad={handleCheck}
                                            ref={imgRefImage}
                                            src={process.env.REACT_APP_IMAGEPFAD + values.image_path}
                                            alt={values.image_alt}
                                            style={{maxHeight: 100, maxWidth: 200}}
                                        />
                                    </Tooltip>
                                : 
                                    <Skeleton variant="rounded" width={100} height={100} />
                                }
                            </Button>
                            { values.image_path &&
                                <IconButton
                                    onClick={() => {
                                        let v = {...values}
                                        delete v.image_id
                                        delete v.image_path
                                        delete v.image_alt
                                        setValues(v)
                                        setWarnings(prev => ({
                                            ...prev,
                                            image_id: null
                                        }))

                                    }}
                                >
                                    <DeleteIcon />
                                </IconButton>                                
                            }
                            { warnings.image_id && 
                                <Alert severity='warning'>{ warnings.image_id }</Alert>
                            }
                            { errors.image_id && 
                                <Alert severity='error'>{ errors.image_id }</Alert>
                            }
                        </Box>
                    </Box>

                    <FormGroup>
                        <FormControlLabel label="Aktiv" control={
                            <Checkbox
                                id="isactive"
                                checked={ values.isactive === 1 ? true : false }
                                onChange={(e) => {
                                    const newValue = e.target.checked === true ? 1 : 0
                                    setValues(prev =>({
                                        ...prev,
                                        isactive: newValue,
                                    }))
                                }}
                            />
                        } />
                    </FormGroup>

                    <TextField
                        variant='standard'
                        label='Produktnummer'
                        id='productnnumber'
                        fullWidth
                        helperText={ errors.productnumber }
                        error= { errors.productnumber ? true : false  }
                        value={ values.productnumber ? values.productnumber : '' }
                        onChange={(e) => {
                            setValues(prev =>({
                                ...prev,
                                productnumber: e.target.value
                            }))
                        }}
                    />

                    <SourceTextfield
                        label="Titel"
                        required={true}
                        fullWidth
                        value={ values.title_source }
                        helperText={ errors.title_source }
                        onChange={(n) => {
                            setValues(prev =>({
                                ...prev,
                                title_source: n
                            }))
                        }}
                    />
                    <SourceTextfield
                        label="Beschreibung"
                        required={true}
                        fullWidth
                        value={ values.description_source }
                        helperText={ errors.description_source }
                        onChange={(n) => {
                            setValues(prev =>({
                                ...prev,
                                description_source: n
                            }))
                        }}
                    />           
                    <SourceTextfield
                        label="Baureihe"
                        required={true}
                        fullWidth
                        value={ values.series_source }
                        helperText={ errors.series_source }
                        onChange={(n) => {
                            setValues(prev =>({
                                ...prev,
                                series_source: n
                            }))
                        }}
                    />
                    <SourceTextfield
                        label="Baureihe-Link"
                        required={true}
                        fullWidth
                        value={ values.serieslink_source }
                        helperText={ errors.serieslink_source }
                        onChange={(n) => {
                            setValues(prev =>({
                                ...prev,
                                serieslink_source: n
                            }))
                        }}
                    />
                    <SourceTextfield
                        label="Datenblatt-Link"
                        required={false}
                        fullWidth
                        value={ values.datasheetlink_source }
                        helperText={ errors.datasheetlink_source }
                        onChange={(n) => {
                            setValues(prev =>({
                                ...prev,
                                datasheetlink_source: n
                            }))
                        }}
                    />
                    <SourceTextfield
                        label="Spezifikation-Link"
                        required={false}
                        fullWidth
                        value={ values.speclink_source }
                        helperText={ errors.speclink_source }
                        onChange={(n) => {
                            setValues(prev =>({
                                ...prev,
                                speclink_source: n
                            }))
                        }}
                    />
                    <SourceTextfield
                        label="CAD-Link"
                        required={false}
                        fullWidth
                        value={ values.cadlink_source }
                        helperText={ errors.cadlink_source }
                        onChange={(n) => {
                            setValues(prev =>({
                                ...prev,
                                cadlink_source: n
                            }))
                        }}
                    />

                    <Autocomplete
                        fullWidth
                        value={ selectedOption ? selectedOption : null }
                        options={ options }
                        getOptionLabel={(option) => option.name ?? option}
                        onChange={(e, optiongroup) => {
                            setSelectedOption( optiongroup )
                            setValues(prev =>({
                                ...prev,
                                optiongroup_id: optiongroup ? optiongroup.id : null
                            }))
                        }}
                        renderInput={(params) => <TextField
                                                    {...params}
                                                    variant='standard'
                                                    label="Options"
                                                    helperText={ errors.optiongroup_id }
                                                    error={ errors.optiongroup_id ? true : false }
                                                />}
                    />

                </Stack>
                <Stack direction='row' spacing={1}>
                    <Button variant='contained' type='submit'
                        onClick={async (e) => {
                            e.preventDefault()
                            const res = await handleCheck()
                            res && handleSave()
                        }}
                    >Save</Button>
                    <Button variant='contained' type='reset' onClick={handleCloseDrawer}>Cancel</Button>
                    { mode==='edit' &&
                        <Button variant='contained' color="warning"
                            onClick={() => {
                                handleDelete( values.id )
                            }}
                        >Delete</Button>
                    }
                </Stack>
                { mode === 'edit' &&
                    <Alert severity="warning">
                        Alle Values dieses Produkt werden ebenfalls gelöscht.
                    </Alert>
                }
            </Stack>
        </Paper>

     );
}




export default ProductInput;