import { coreFetch } from './dbCoreFunc'


//Alle Product abfragen
//limit: nur die ersten n-Datensätze abfragen. Wenn nicht angegeben oder 0 werde alle Datensätze geliefert.
//search: Wird hier ein Wert angegeben, werden alle sinnvollen Spalten nach diesem Wert durchsucht. Ansonsten Null angeben.  
//return: ein Object, mit status, message, und dem Product als results
export const fetchProducts = async ( limit, search ) => {
    const params = new URLSearchParams()
    if(limit && limit>0){ params.append('limit', limit) }
    if(search){ params.append('search', "%" + search + "%") }
    const paramsStr = '?' + params.toString()
    const uri = process.env.REACT_APP_API_PATH + '/products/' + (paramsStr !== '?' ? paramsStr : '')

    const headers = {
        "Content-Type": "application/json"
    }

    return await coreFetch(uri, 'GET', headers)
}


//Ein Product abfragen mit einer id
//id: id des Product die abgefragt werden soll.
//return: ein Object, mit status, message, und dem Product als results
export const fetchProduct = async ( id ) => {
    var uri = process.env.REACT_APP_API_PATH + '/products/' + id;

    const headers = {
        "Content-Type": "application/json",
    }

    return await coreFetch(uri, 'GET', headers)
}


//Ein neuer Product erstellen.
//return: Es wird eine Objekt mit status und message zurückgegeben.
export async function createProduct(productnumber, isactive, image_id, title_id, description_id, series_id, serieslink_id, datasheetlink_id, cadlink_id, speclink_id, optiongroup_id, apikey){

    const payload = {
        productnumber: productnumber,
        isactive: isactive,
        image_id: image_id,
        title_id: title_id,
        description_id: description_id,
        series_id: series_id,
        serieslink_id: serieslink_id,
        datasheetlink_id: datasheetlink_id ? datasheetlink_id : null,
        cadlink_id: cadlink_id ? cadlink_id : null,
        speclink_id: speclink_id ? cadlink_id : null,
        optiongroup_id: optiongroup_id ? optiongroup_id : null
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(process.env.REACT_APP_API_PATH + '/products/', 'POST', headers, JSON.stringify(payload))
}


//Ein Product ändern.
//id:   id des Product das geändert werden soll
//new{x}: die neuen Werte des Product
//apikey: ein gültiger APIKEY, ansonsten kann der PUT-Request nicht durchgeführt werden.
//Es wird eine Objekt mit status und message zurückgegeben.
export async function editProduct(id, productnumber, isactive, image_id, title_id, description_id, series_id, serieslink_id, datasheetlink_id, cadlink_id, speclink_id, optiongroup_id, apikey){
    const payload = {
        productnumber: productnumber,
        isactive: isactive,
        image_id: image_id,
        title_id: title_id,
        description_id: description_id,
        series_id: series_id,
        serieslink_id: serieslink_id,
        datasheetlink_id: datasheetlink_id ? datasheetlink_id : null,
        cadlink_id: cadlink_id ? cadlink_id : null,
        speclink_id: speclink_id ? cadlink_id : null,
        optiongroup_id: optiongroup_id ? optiongroup_id : null        
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(process.env.REACT_APP_API_PATH + '/products/' + id, 'PUT', headers, JSON.stringify(payload))
}


//Ein Product löschen
//Es wird eine Objekt mit status und message zurückgegeben.
export async function deleteProduct(id, apikey){
    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(process.env.REACT_APP_API_PATH + '/products/' + id, 'DELETE', headers)
}


/**
 * Isactive-Wert eines Produkts auf true/false setzen.
 * @param {integer} id Id des Produkts das geändert werden soll.
 * @param {boolean} isactive Der Wert auf den isactive gesetzt werden soll. True oder False
 * @param {string} apikey Ein gültiger APIKEY, ansonsten kann der PUT-Request nicht durchgeführt werden.
 * @returns 
 */
export async function editIsactive(id, isactive, apikey){
    const payload = {
        isactive: isactive
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(process.env.REACT_APP_API_PATH + '/products/' + id, 'PUT', headers, JSON.stringify(payload))
}