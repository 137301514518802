import { coreFetch } from './dbCoreFunc'


//Alle Options abfragen
//limit: nur die ersten n-Datensätze abfragen. Wenn nicht angegeben oder 0 werde alle Datensätze geliefert.
//search: Wird hier ein Wert angegeben, werden alle sinnvollen Spalten nach diesem Wert durchsucht. Ansonsten Null angeben.  
//return: ein Object, mit status, message, und dem Option als results
export const fetchOptions = async ( limit, search ) => {
    const params = new URLSearchParams()
    if(limit && limit>0){ params.append('limit', limit) }
    if(search){ params.append('search', "%" + search + "%") }
    const paramsStr = '?' + params.toString()
    const uri = process.env.REACT_APP_API_PATH + '/options/' + (paramsStr !== '?' ? paramsStr : '')

    const headers = {
        "Content-Type": "application/json"
    }

    return await coreFetch(uri, 'GET', headers)
}


//Ein Option abfragen mit einer id
//id: id des Option die abgefragt werden soll.
//return: ein Object, mit status, message, und dem Option als results
export const fetchOption = async ( id ) => {
    var uri = process.env.REACT_APP_API_PATH + '/options/' + id;

    const headers = {
        "Content-Type": "application/json",
    }

    return await coreFetch(uri, 'GET', headers)
}


//Ein neuer Option erstellen.
//return: Es wird eine Objekt mit status und message zurückgegeben.
export async function createOption(group_id, language_id, label, options, apikey){

    const payload = {
        group_id: group_id,
        language_id: language_id,
        label: label,
        options: options
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(process.env.REACT_APP_API_PATH + '/options/', 'POST', headers, JSON.stringify(payload))
}


//Ein Option ändern.
//id:   id des Option das geändert werden soll
//new{x}: die neuen Werte des Option
//apikey: ein gültiger APIKEY, ansonsten kann der PUT-Request nicht durchgeführt werden.
//Es wird eine Objekt mit status und message zurückgegeben.
export async function editOption(id, group_id, language_id, label, options, apikey){
    const payload = {
        group_id: group_id,
        language_id: language_id,
        label: label,
        options: options
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(process.env.REACT_APP_API_PATH + '/options/' + id, 'PUT', headers, JSON.stringify(payload))
}


//Ein Option löschen
//Es wird eine Objekt mit status und message zurückgegeben.
export async function deleteOption(id, apikey){
    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(process.env.REACT_APP_API_PATH + '/options/' + id, 'DELETE', headers)
}


//Alle Options von einer Group abfragen
//product_id: id des Produkts
export async function fetchOptionsFromGroup(group_id){
    const params = new URLSearchParams()
    params.append('group', group_id)
    const paramsStr = '?' + params.toString()
    const uri = process.env.REACT_APP_API_PATH + '/options/' + (paramsStr !== '?' ? paramsStr : '')

    const headers = {
        "Content-Type": "application/json"
    }

    return await coreFetch(uri, 'GET', headers)
}