import AddIcon from '@mui/icons-material/Add'
import RefreshIcon from '@mui/icons-material/Refresh'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import { Autocomplete, TextField, Stack, Typography, Card, CardHeader, IconButton, Box, CardContent } from "@mui/material";
import { useState } from 'react';
import { useEffect } from "react";
import { useQuery } from "react-query";
import { fetchProperties } from '../includes/dbPropertiesFunc';
import { createValue, deleteValue, editValue, fetchValuesFromProduct } from "../includes/dbValuesFunc";
import { checkSource } from '../includes/dbSourcesFunc'
import SourceTextfield from '../components/SourceTextfield'
import { useSnackbar } from 'notistack'



const ProductValues = ( { product_id, apikey } ) => {
    const { data, refetch} = useQuery(['values', product_id], () => fetchValuesFromProduct(product_id))
    const [properties, setProperties] = useState([])
    const [mode, setMode] = useState('')
    const [values, setValues] = useState(null)
    const [errors, setErrors] = useState({})
    const [selectedProperty, setSelectedProperty] = useState(null)
    const { enqueueSnackbar } = useSnackbar()

   
    useEffect(() =>{
        (async () => {
            const answer = await fetchProperties()
            setProperties(answer.results)
        })();
    },[])


    const handleNew = () => {
        setMode('create')
        setSelectedProperty(null)
        setValues({product_id: product_id, property_id: null, value: '', value_source: ''})  //Die in den Inputs angezeigten Werte rücksetzen (ein leeres Object führt zu einem Fehler).
        setErrors({})
    }


    const handleEdit = ( id ) => {
        const value = data.results.filter(v => v.id === id)[0]
        const property = properties.filter(v => v.id === value.property_id)[0]
        
        setMode('edit')
        setSelectedProperty(property)
        setValues(value)
        setErrors({})
    }


    const handleCheck = () => {
        //Werte überprüfen:
        let collectErrors = {}

        if(values.value && !parseFloat(values.value)){
            collectErrors.value = 'Value muss eine Zahl sein.'
        }


        if(!values.value && !values.value_source){
            collectErrors.value = 'Entweder Value oder eine Value-Source eingeben.'
            collectErrors.value_source = 'Entweder Value oder eine Value-Source eingeben.'
        }

        if(!selectedProperty){
            collectErrors.property_id = 'Property auswählen.'
        }

        setErrors({
            ...collectErrors
        })

        return Object.keys(collectErrors).length === 0
    }


    const handleSave = async () => {
        //Sources kontrollieren, falls notwendig neu anlegen:
        if(values.value_source){
            let answer = await checkSource(values.value_source, apikey)
            if(answer){
                values.value_id = answer
            }else{
                enqueueSnackbar('Source konnte nicht korrekt gespeichert werden.', {variant: 'error'})
                return false
            }
        }else{
            values.value_id=null
        }

        if(mode === 'create'){
            //Neues Value erstellen.
            const res = await createValue(values.product_id, selectedProperty.id, values.value, values.value_id, apikey)
            if(res && res.status === 1){
                enqueueSnackbar("Value erfolgreich angelegt.", {variant: 'success'})
                refetch()
                handleCancelEdit()
            }else{
                enqueueSnackbar(res.message, {variant: 'error'})
            }
        }else if(mode === 'edit'){
            //Value ändern.
            const res = await  editValue(values.id, values.product_id, selectedProperty.id, values.value, values.value_id, apikey)
            if(res && res.status === 1){
                enqueueSnackbar("Value erfolgreich geändert.", {variant: 'success'})
                refetch()
                handleCancelEdit()
            }else{
                enqueueSnackbar(res.message, {variant: 'error'})
            }
        }else{
            enqueueSnackbar('Unbekannter Modus.', {variant: 'error'})
        }
      
    }

    
    const handleCancelEdit = () => {
        setMode('')
        setSelectedProperty(null)
        setValues(null)
        setErrors({})
    }


    const handleDelete = async ( id ) => {
        const result = await deleteValue(id, apikey)
        if(result.status === 0){
            enqueueSnackbar(result.message, {variant: "error"})
        }else{
            enqueueSnackbar("Value erfolgreich gelöscht.", {variant: "success"})
            refetch()
        }  
    }




    return (
        <Box>
            <Stack direction='row' spacing={2} mb={2}>
                <IconButton onClick={() => {handleNew()}}><AddIcon color='primary' fontSize='small' /></IconButton>
                <IconButton onClick={() => {refetch()}}><RefreshIcon color='primary' fontSize='small' /></IconButton>
            </Stack>
            { mode === 'create' && 
                <Card sx={{mb: 5}}>
                    <CardHeader
                        title={<Typography variant='h4' color='primary'>Neues Value</Typography>}
                        action={
                            <>
                            <IconButton
                                onClick={() => {
                                    const check = handleCheck()
                                    check && handleSave()
                                }}
                            ><CheckCircleOutlineIcon color='success' fontSize='small' /></IconButton>
                            <IconButton
                                onClick={() => {
                                    handleCancelEdit()
                                }}
                            ><CancelOutlinedIcon fontSize='small' /></IconButton>
                            </>
                        }
                    />
                    <CardContent>
                        <Stack spacing={1} sx={{pb: 3}}>
                            <Autocomplete
                                fullWidth
                                value={ selectedProperty ? selectedProperty : null }
                                options={ properties }
                                getOptionLabel={(option) => option.label_source + ' (' + option.ptype_name + ')' ?? option}
                                onChange={(e, property) => {
                                    setSelectedProperty( property )
                                }}
                                renderInput={(params) => <TextField
                                                            {...params}
                                                            variant='standard'
                                                            label="Property"
                                                            required
                                                            helperText={ errors.property_id }
                                                            error={ errors.property_id ? true : false }
                                                        />}
                            />
                            <TextField
                                variant='standard'
                                label='Value'
                                fullWidth
                                helperText={ errors.value }
                                error= { errors.value ? true : false  }
                                value={ values.value ? values.value : '' }
                                onChange={(e) => {
                                    setValues(prev =>({
                                        ...prev,
                                        value: e.target.value
                                    }))
                                }}
                            />
                            <SourceTextfield
                                label="Value (Source für Übersetzung)"
                                required={false}
                                fullWidth
                                value={ values.value_source }
                                helperText={ errors.value_source }
                                onChange={(n) => {
                                    setValues(prev =>({
                                        ...prev,
                                        value_source: n
                                    }))
                                }}
                            />
                            
                        </Stack>                        
                    </CardContent>
                </Card>
            }

            { data && data.results && data.results.length > 0 ? (
                <Stack spacing={1}>
                    {data.results.map(value =>
                        <Card key={value.id}>
                            <CardHeader
                                title={ <Typography variant='h6' color='primary'>{value.property_label}</Typography> }
                                action={ <Stack direction='row'>
                                    {mode === 'edit' && values && values.id === value.id ? (
                                        <>
                                        <IconButton
                                            onClick={() => {
                                                const check = handleCheck()
                                                check && handleSave()
                                            }}
                                        ><CheckCircleOutlineIcon color='success' fontSize='small' /></IconButton>
                                        <IconButton
                                            onClick={() => {
                                                handleCancelEdit()
                                            }}
                                        ><CancelOutlinedIcon fontSize='small' /></IconButton>
                                        </>
                                    ) : (
                                        <IconButton onClick={() => {handleEdit(value.id)}}><EditIcon fontSize='small' /></IconButton>
                                    )}


                                    <IconButton
                                        color='warning'
                                        onClick={async () => handleDelete(value.id)}
                                    ><DeleteIcon fontSize='small'/></IconButton>
                                </Stack> }
                            />
                            <CardContent>
                                {mode === 'edit' && values && values.id === value.id ? (
                                    <Stack spacing={1} sx={{pb: 3}}>
                                        <Autocomplete
                                            fullWidth
                                            value={ selectedProperty ? selectedProperty : null }
                                            options={ properties }
                                            getOptionLabel={(option) => option.label_source + ' (' + option.ptype_name + ')' ?? option}
                                            onChange={(e, property) => {
                                                setSelectedProperty( property )
                                            }}
                                            renderInput={(params) => <TextField
                                                                        {...params}
                                                                        variant='standard'
                                                                        label="Property"
                                                                        required
                                                                        helperText={ errors.property_id }
                                                                        error={ errors.property_id ? true : false }
                                                                    />}
                                        />
                                        <TextField
                                            variant='standard'
                                            label='Value'
                                            fullWidth
                                            helperText={ errors.value }
                                            error= { errors.value ? true : false  }
                                            value={ values.value ? values.value : '' }
                                            onChange={(e) => {
                                                setValues(prev =>({
                                                    ...prev,
                                                    value: e.target.value
                                                }))
                                            }}
                                        />
                                        <SourceTextfield
                                            label="Value (Source für Übersetzung)"
                                            required={false}
                                            fullWidth
                                            value={ values.value_source }
                                            helperText={ errors.value_source }
                                            onChange={(n) => {
                                                setValues(prev =>({
                                                    ...prev,
                                                    value_source: n
                                                }))
                                            }}
                                        />
                                        
                                    </Stack>
                                ) : (
                                    <Stack direction='row' justifyContent="space-between">
                                        <Typography>{value.property_label}</Typography>
                                        <Stack direction='row' spacing={0.2}>
                                            <Typography fontWeight='600' color='primary'>{value.value}</Typography>
                                            <Typography fontWeight='600' color='primary'>{value.value_source}</Typography>
                                            <Typography color='primary'>{value.property_unit}</Typography>
                                        </Stack>
                                    </Stack>
                                )}
                            </CardContent>
                        </Card>
                    )}
                </Stack>                
            ) : (
                <Typography>Noch keine Daten</Typography>
            )}
        </Box>
     );
}
 
export default ProductValues;