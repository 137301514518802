import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";

const InfoAlgorithmus = ( {variant, ...other} ) => {
    const [headers, setHeaders] = useState([])

    useEffect(()=> {
        switch (variant) {
            case 'h2':
                setHeaders(['h2', 'h3', 'h4', 'h5', 'h6', 'h6'])
                break;
            case 'h3':
                setHeaders(['h3', 'h4', 'h5', 'h6', 'h6', 'h6'])
                break;
            case 'h4':
                setHeaders(['h4', 'h5', 'h6', 'h6', 'h6', 'h6'])
                break;
            case 'h5':
                setHeaders(['h5', 'h6', 'h6', 'h6', 'h6', 'h6'])
                break;
            case 'h6':
                setHeaders(['h6', 'h6', 'h6', 'h6', 'h6', 'h6'])
                break;
            default:
                setHeaders(['h1', 'h2', 'h3', 'h4', 'h5', 'h6'])
                break;
        }
    },[variant])

    return ( 
        <Box className="info-algorithmus" {...other}>
            <Typography variant={variant ? variant : headers[0]} mb={1}>Verständnis für den Filteralgorithmus</Typography>
            <Typography>Gehen wir als Beispiel von der Eingabe <strong>«Suche Planetengetriebe mit 23Nm Solldrehmoment»</strong>.</Typography>
            <Typography>Daraus würden wir gerne die folgenden Filter ableiten:</Typography>
            <ul>
                <li><Typography>Getriebetyp: Planetengetriebe</Typography></li>
                <li><Typography>Nenndrehmoment: 23Nm</Typography></li>
            </ul>
            <Typography>Betrachten wir den ersten Filter. Dazu müssen wir ein Property «Getriebetyp» anlegen. Beim Produkt kann diesem Property dann der Wert (value) «Planetengetriebe» oder «Schneckengetriebe» etc. enthalten.</Typography>
            <Typography mt={2}>Es ergibt sich nun das Problem, dass wir in der Eingabe nicht nach dem Property-Label «Getriebetyp» suchen können. Dies würde zu keinem befriedigenden Resultat führen (weil der in wahrscheinliche keiner Anfrage vorkommen wird). Wir müssen stattdessen nach den im Produkt eingegebenen Werten (values) suchen. Deshalb aktivieren wir für das Property «Getriebetyp» die Option «Values mit einbeziehen» (usevalues). Der Algorithmus erstellt nun für dieses Property eine Liste mit allen bei den Produkten (für dieses Property) eingegebenen Werten und verwendet diese Liste um im der Eingabe nach diesem Property zu suchen.</Typography>
            <Typography mt={2}>In unserem Fall findet er den Wert «Planetengetriebe» und setzt somit den Filter Getriebetyp auf «Planetengetriebe».</Typography>
            <Typography mt={2}>Für den zweiten Filter erstellen wir ein Property mit dem Label «Nenndrehmoment». Wir weisen dem Property die Einheit Nm zu. Der Algorithmus wird nun nach dem Wort «Nenndrehmoment» in der Eingabe suchen aber diesen nicht finden. Wir gehen aber in diesem Falle davon aus, dass wenn in der Eingabe ein Wert mit der Einheit «Nm» steht, primär das Property Nenndrehmoment gemeint ist. Wir aktivieren dazu die Option «Property als Standard für diese Einheit» (useunit). Wird der Algorithmus also einen Zahlenwert gefolgt von der Einheit «Nm» finden, wird er diesen dem Property «Nenndrehmoment» zuweisen. Dem Text «Solldrehmoment» wird in diesem Fall ignoriert. Ausser es wäre auch ein Property mit dem Label «Solldrehmoment» vorhanden.</Typography>
            <Typography mt={2}>Eine weitere Möglichkeit wäre auch den Thesaurus zu benutzen (siehe Kapitel Thesaurus). Im vergleich dazu würde dann aber eine Eingabe «Suche Planetengetriebe mit 23Nm» nicht dazu führen, dass 23Nm dem Property «Nenndrehmoment» zugewiesen würde.</Typography>
        </Box>
     );
}
 
export default InfoAlgorithmus;