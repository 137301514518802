import { TextField } from "@mui/material";
import { useState } from "react";


const WizardArgumentInputField = ( { defaultValue , onBlur, focusFieldWidth} ) => {
    const [hasFocus, setHasFocus] = useState(false)

    return(
        <TextField
            sx={{minWidth: hasFocus ? focusFieldWidth + 'px' : 0}}
            onFocus={() => setHasFocus(true)}
            size='small'
            margin='none'
            defaultValue={ defaultValue }
            onBlur={(e) => {
                setHasFocus(false)
                onBlur(e)
            }}
        />
        )
}

export default WizardArgumentInputField;
