import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";

const InfoEmail = ( {variant, ...other} ) => {
    const [headers, setHeaders] = useState([])

    useEffect(()=> {
        switch (variant) {
            case 'h2':
                setHeaders(['h2', 'h3', 'h4', 'h5', 'h6', 'h6'])
                break;
            case 'h3':
                setHeaders(['h3', 'h4', 'h5', 'h6', 'h6', 'h6'])
                break;
            case 'h4':
                setHeaders(['h4', 'h5', 'h6', 'h6', 'h6', 'h6'])
                break;
            case 'h5':
                setHeaders(['h5', 'h6', 'h6', 'h6', 'h6', 'h6'])
                break;
            case 'h6':
                setHeaders(['h6', 'h6', 'h6', 'h6', 'h6', 'h6'])
                break;
            default:
                setHeaders(['h1', 'h2', 'h3', 'h4', 'h5', 'h6'])
                break;
        }
    },[variant])

    return ( 
        <Box className="info-email" {...other}>
            <Typography variant={variant ? variant : headers[0]} mb={1}>Email-Versand</Typography>
            <Typography>Es werden Anfragen per Email versendet. Dazu muss festgelegt werden, an welche Email-Adresse diese Anfragen versendet werden.</Typography>
            <Typography>Diese kann hier festgelegt werden. Die Datenbank ist so designed, dass später auch für andere Oprationen (ev. Bestellung) Email-Adressen gespeichert werden können.</Typography>
            <Typography>Dazu ist das Feld <code>Type</code> vorhanden. Hier kann angegben werden für welcheArt versandt die Email verwendet wird. Dieser Type muss eindeutig sein.</Typography>
            <Typography>Folgende Typen können bisher verwendet werden.</Typography>
            <ul>
                <li><Typography><strong>quote</strong> E-Mail für Anfragen.</Typography></li>
            </ul>
            <Typography>Wird die Option <strong>isactive</strong> deaktiviert, kann mit dieser Email-Adresse keine Emails versendet werden.</Typography>
        </Box>
     );
}
 
export default InfoEmail;