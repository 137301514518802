import { coreFetch } from './dbCoreFunc'


//Alle Adresses abfragen
//limit: nur die ersten n-Datensätze abfragen. Wenn nicht angegeben oder 0 werde alle Datensätze geliefert.
//search: Wird hier ein Wert angegeben, werden alle sinnvollen Spalten nach diesem Wert durchsucht. Ansonsten Null angeben.  
//return: ein Object, mit status, message, und dem Address als results
export const fetchEmailaddresses = async ( limit, search ) => {
    const params = new URLSearchParams()
    if(limit && limit>0){ params.append('limit', limit) }
    if(search){ params.append('search', "%" + search + "%") }
    const paramsStr = '?' + params.toString()
    const uri = process.env.REACT_APP_API_PATH + '/emailaddresses/' + (paramsStr !== '?' ? paramsStr : '')

    const headers = {
        "Content-Type": "application/json"
    }

    return await coreFetch(uri, 'GET', headers)
}


//Ein Address abfragen mit einer id
//id: id des Address die abgefragt werden soll.
//return: ein Object, mit status, message, und dem Address als results
export const fetchEmailaddress = async ( id ) => {
    var uri = process.env.REACT_APP_API_PATH + '/emailaddresses/' + id;

    const headers = {
        "Content-Type": "application/json",
    }

    return await coreFetch(uri, 'GET', headers)
}


//Ein neuer Address erstellen.
//return: Es wird eine Objekt mit status und message zurückgegeben.
export async function createEmailaddress(address, type, isactive, apikey){

    const payload = {
        address: address,
        type: type,
        isactive: isactive
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(process.env.REACT_APP_API_PATH + '/emailaddresses/', 'POST', headers, JSON.stringify(payload))
}


//Ein Address ändern.
//id:   id des Address das geändert werden soll
//new{x}: die neuen Werte des Address
//apikey: ein gültiger APIKEY, ansonsten kann der PUT-Request nicht durchgeführt werden.
//Es wird eine Objekt mit status und message zurückgegeben.
export async function editEmailaddress(id, address, type, isactive, apikey){
    const payload = {
        address: address,
        type: type,
        isactive: isactive     
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(process.env.REACT_APP_API_PATH + '/emailaddresses/' + id, 'PUT', headers, JSON.stringify(payload))
}


//Ein Emailaddress löschen
//Es wird eine Objekt mit status und message zurückgegeben.
export async function deleteEmailaddress(id, apikey){
    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(process.env.REACT_APP_API_PATH + '/emailaddresses/' + id, 'DELETE', headers)
}


//Gibt an, ob ein Email-Type schon existiert.
//type: Email-Type die kontrolliert wird
//exclude: hier kann eine [id] eines Datensatzes angegeben werden, welcher nicht berücksichtigt wird. Wieso? Wenn ein Datensatz geändert wird, darf der name identisch mit dem Wert des zu ändernden Datensatzes sein. Deshalb kann die [id] dieses Datensatzes als exclude angegeben werden. 
//return: true wenn der typename schon existiert, ansonsten false
export async function exitsEmailtype(type, exclude, apikey){
    const params = new URLSearchParams()
    params.append('exists', type)
    if(exclude){ params.append('exclude', exclude) }
    const paramsStr = '?' + params.toString()
    const uri = process.env.REACT_APP_API_PATH + '/emailaddresses/' + paramsStr
    const headers = {
        "Content-Type": "application/json",
        "Authorization": apikey
    }

    const answer = await coreFetch(uri, 'GET', headers)
    if(answer && answer.status === 1){
        return answer.results === 1 ? true : false
    }else{
        return false
    }
}
