import { coreFetch } from './dbCoreFunc'


//Alle Property abfragen
//limit: nur die ersten n-Datensätze abfragen. Wenn nicht angegeben oder 0 werde alle Datensätze geliefert.
//search: Wird hier ein Wert angegeben, werden alle sinnvollen Spalten nach diesem Wert durchsucht. Ansonsten Null angeben.  
//return: ein Object, mit status, message, und dem Property als results
export const fetchProperties = async ( limit, search ) => {
    const params = new URLSearchParams()
    if(limit && limit>0){ params.append('limit', limit) }
    if(search){ params.append('search', "%" + search + "%") }
    const paramsStr = '?' + params.toString()
    const uri = process.env.REACT_APP_API_PATH + '/properties/' + (paramsStr !== '?' ? paramsStr : '')

    const headers = {
        "Content-Type": "application/json"
    }

    return await coreFetch(uri, 'GET', headers)
}


//Ein Property abfragen mit einer id
//id: id des Property die abgefragt werden soll.
//return: ein Object, mit status, message, und dem Property als results
export const fetchProperty = async ( id ) => {
    var uri = process.env.REACT_APP_API_PATH + '/properties/' + id;

    const headers = {
        "Content-Type": "application/json",
    }

    return await coreFetch(uri, 'GET', headers)
}


//Ein neuer Property erstellen.
//return: Es wird eine Objekt mit status und message zurückgegeben.
export async function createProperty(label_id, ptype_id, unit, group_id, priority, usevalues, useunit, apikey){

    const payload = {
        label_id: label_id,
        ptype_id: ptype_id,
        unit: unit ? unit : null,
        group_id: group_id ? group_id : null,
        priority: priority,
        usevalues: usevalues,
        useunit: useunit
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(process.env.REACT_APP_API_PATH + '/properties/', 'POST', headers, JSON.stringify(payload))
}


//Ein Property ändern.
//id:   id des Property das geändert werden soll
//new{x}: die neuen Werte des Property
//apikey: ein gültiger APIKEY, ansonsten kann der PUT-Request nicht durchgeführt werden.
//Es wird eine Objekt mit status und message zurückgegeben.
export async function editProperty(id, label_id, ptype_id, unit, group_id, priority, usevalues, useunit, apikey){
    const payload = {
        label_id: label_id,
        ptype_id: ptype_id,
        unit: unit ? unit : null,
        priority: priority,
        group_id: group_id ? group_id : null,
        usevalues: usevalues,
        useunit: useunit
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(process.env.REACT_APP_API_PATH + '/properties/' + id, 'PUT', headers, JSON.stringify(payload))
}


//Ein Property löschen
//Es wird eine Objekt mit status und message zurückgegeben.
export async function deleteProperty(id, apikey){
    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(process.env.REACT_APP_API_PATH + '/properties/' + id, 'DELETE', headers)
}