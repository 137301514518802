import { coreFetch } from './dbCoreFunc'


//Alle Optiongroups abfragen
//limit: nur die ersten n-Datensätze abfragen. Wenn nicht angegeben oder 0 werde alle Datensätze geliefert.
//search: Wird hier ein Wert angegeben, werden alle sinnvollen Spalten nach diesem Wert durchsucht. Ansonsten Null angeben.  
//return: ein Object, mit status, message, und dem Optiongroup als results
export const fetchOptiongroups = async ( limit, search ) => {
    const params = new URLSearchParams()
    if(limit && limit>0){ params.append('limit', limit) }
    if(search){ params.append('search', "%" + search + "%") }
    const paramsStr = '?' + params.toString()
    const uri = process.env.REACT_APP_API_PATH + '/optiongroups/' + (paramsStr !== '?' ? paramsStr : '')

    const headers = {
        "Content-Type": "application/json"
    }

    return await coreFetch(uri, 'GET', headers)
}


//Ein Optiongroup abfragen mit einer id
//id: id des Optiongroup die abgefragt werden soll.
//return: ein Object, mit status, message, und dem Optiongroup als results
export const fetchOptiongroup = async ( id ) => {
    var uri = process.env.REACT_APP_API_PATH + '/optiongroups/' + id;

    const headers = {
        "Content-Type": "application/json",
    }

    return await coreFetch(uri, 'GET', headers)
}


//Ein neuer Optiongroup erstellen.
//return: Es wird eine Objekt mit status und message zurückgegeben.
export async function createOptiongroup(name, apikey){

    const payload = {
        name: name
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(process.env.REACT_APP_API_PATH + '/optiongroups/', 'POST', headers, JSON.stringify(payload))
}


//Ein Optiongroup ändern.
//id:   id des Optiongroup das geändert werden soll
//new{x}: die neuen Werte des Optiongroup
//apikey: ein gültiger APIKEY, ansonsten kann der PUT-Request nicht durchgeführt werden.
//Es wird eine Objekt mit status und message zurückgegeben.
export async function editOptiongroup(id, name, apikey){
    const payload = {
        name: name
    }

    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(process.env.REACT_APP_API_PATH + '/optiongroups/' + id, 'PUT', headers, JSON.stringify(payload))
}


//Ein Optiongroup löschen
//Es wird eine Objekt mit status und message zurückgegeben.
export async function deleteOptiongroup(id, apikey){
    const headers = {
            "Content-Type": "application/json",
            "Authorization": apikey
    }

    return await coreFetch(process.env.REACT_APP_API_PATH + '/optiongroups/' + id, 'DELETE', headers)
}
