import DeleteIcon from '@mui/icons-material/Delete'
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import CloseIcon from '@mui/icons-material/Close'
import RefreshIcon from '@mui/icons-material/Refresh'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'
import { MenuItem, Select, InputLabel, FormControl, InputAdornment, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, IconButton, Paper, Stack, TextField, Typography, Tooltip } from '@mui/material'
import { deleteQuery, fetchQuery, fetchQueries, deleteAllQueries } from "../includes/dbQueriesFunc";
import { DataGrid } from "@mui/x-data-grid"
import { AuthContext } from '../components/AuthProvider'
import { useSnackbar } from 'notistack'
import { useContext, useState, useEffect, useRef, useCallback } from 'react'
import { useQuery } from 'react-query'
import debounce from "lodash.debounce"
import { fetchLanguages } from '../includes/dbLanguagesFunc'



const columns = [
    { field: 'id', headerName: 'id', width: 50 },
    { field: 'type', headerName: 'Typ', width: 50 },
    { field: 'properties', headerName: 'Props', width: 100 },
    { field: 'query', headerName: 'Query', width: 700 },
    { field: 'creationtime', headerName: 'Erstellt', width: 170 }
];


const Queriespage = () => {
    const { enqueueSnackbar } = useSnackbar()
    const { apikey, isLoggedin } = useContext(AuthContext)
    const searchInput = useRef()
    const [ search, setSearch ] = useState('')
    const [ rowsCount, setRowsCount ] = useState(15)    
    const [ languages, setLanguages ] = useState(null)
    const [ selectionModel, setSelectionModel ] = useState([])
    const [ values, setValues ] = useState({})
    const [ isDrawerOpen, setIsDrawerOpen ] = useState(false)
    const [ isDialogOpen, setIsDialogOpen ] = useState(false)
    const [ isDialogDeleteAllOpen, setIsDialogDeleteAllOpen ] = useState(false)
    const { data, isLoading, refetch } = useQuery(['queries', rowsCount, search], () => fetchQueries(rowsCount, search))


    useEffect(() => {
        (async () => {
            const answer = await fetchLanguages()
            if(answer && answer.status === 1){ setLanguages(answer.results) }
        })();
    // eslint-disable-next-line
    },[])


    /**
     * Eine Query im Drawer anzeigen
     * @param {number} id Die id der Query die im Drawer angezeigt wird.
     */
    const handleShow = async ( id ) => {
        const row = await fetchQuery(id)
        if(row && row.status === 1){
            if(row.count === 1){
                setValues(row.results)
                handleOpenDrawer()
            }else{
                enqueueSnackbar('Es existiert keine Query mit der Id ' + id, {variant: 'warning'})
            }
        }else{
            enqueueSnackbar('Fehler bei der Abfrage der Datenbank.', {variant: 'error'})
        }
    }


    /**
     * Den Drawer öffnen
     */
    const handleOpenDrawer= () => {
        setIsDrawerOpen(true)
    }

    /**
     * Den Drawer (rechte Seite) schliessen
     */
    const handleCloseDrawer = () =>{
        setIsDrawerOpen(false)
    }

    /**
     * Einen Datensatz löschen
     * @param {number} id Die id des Query das gelöscht wird.
     * @returns True wenn erfolgreich, ansonsten false
     */
    const handleDelete = async (id) => {
        const result = await deleteQuery(id, apikey)
        if(result.status === 0){
            enqueueSnackbar(result.message, {variant: "error"})
            return
        }else{
            enqueueSnackbar("Query erfolgreich gelöscht.", {variant: "success"})
            handleCloseDrawer()
            refetch()
            return
        }   
    }

    /**
     * Alle ausgewählten Zeilen löschen
     */
    const handleDeleteChecked = async () => {
        //selectionModel enthällt eine Liste der id mit allen selectierten Zeilen. Wieso id als Standard verwendet wird, keine Ahnung!
        for(const id of selectionModel){
            const result = await deleteQuery(id, apikey)
            if(result.status === 0){
                enqueueSnackbar(result.message, {variant: "error"})
            }
        }
        refetch()
    }

    /**
     * Alle Queries löschen.
     */
    const handleDeleteAll = async () => {
        const answer = await deleteAllQueries(apikey)
        if(answer && answer.status === 1){
            enqueueSnackbar('Alle Einträge gelöscht.', {variant: "success"})
            refetch()
        }else{
            enqueueSnackbar('Fehler beim Löschen: ' + answer.message, {variant: "error"})
        }        
    }

    //Die Auswahl in der Tabelle aufgrund des Suchtextes einschränken.
    const handleSearch = ( text ) => {
        searchInput.current.value = text
        debounceSearch(text)
    }

    //Filtert die Tabelle aufgrund des Suchkriteriums, aber erst nach 500ms. Solange wird auf weitere Eingaben gewartet. Reduziert die Anzahl Anfragen.
    // eslint-disable-next-line
    const debounceSearch = useCallback(
        debounce( (value) => {
            setSearch( value )
        }, 500)
    ,[])




    return ( 
        <Container>
            <Typography variant="h1">
                Queries
            </Typography>
            { isLoggedin() &&
                <div>
                    <Stack spacing={2} className="data">
                        <Stack direction='row' spacing={1}  justifyContent="flex-start" alignItems="baseline" className="data-table-search">
                            <TextField
                                variant='outlined'
                                label='Suche'
                                fullWidth
                                inputRef={ searchInput }
                                onChange={ (e) => debounceSearch(e.target.value) }
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton onClick={() => handleSearch('')}>
                                                <CloseIcon fontSize='small'/>
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <FormControl sx={{ width: 200 }}>
                                <InputLabel>Rows</InputLabel>
                                <Select
                                    label="Rows"
                                    value={rowsCount}
                                    onChange={(e) => setRowsCount(e.target.value)}
                                >
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={15}>15</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={30}>30</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                    <MenuItem value={100}>100</MenuItem>
                                    <MenuItem value={0}>Alle</MenuItem>
                                </Select>
                            </FormControl>

                        </Stack>
                        <Stack direction='row' spacing={1}  justifyContent="flex-start" alignItems="baseline" className="data-table-toolbar">
                            <IconButton color="warning" onClick={() => setIsDialogOpen(true)} disabled={selectionModel.length === 0}>
                                <DeleteIcon />
                            </IconButton>
                            <Tooltip title="Alle löschen">
                                <IconButton color="warning" onClick={() => setIsDialogDeleteAllOpen(true)}>
                                    <DeleteSweepIcon />
                                </IconButton>
                            </Tooltip>
                            <IconButton color="primary" onClick={refetch}>
                                <RefreshIcon />
                            </IconButton>
                            { isLoading && <HourglassBottomIcon color='warning' />}
                        </Stack>
                        <div className="data-table" style={{ height: 650, width: '100%' }}>
                            <DataGrid
                                density="compact"
                                rows={data ? data.results : []}
                                columns={columns}
                                disableSelectionOnClick
                                checkboxSelection
                                pageSize={15}
                                rowsPerPageOptions={[15]}                    
                                disableColumnFilter
                                onRowClick={(e) => handleShow(e.id)}
                                onSelectionModelChange={(newModel) => setSelectionModel(newModel)}
                                selectionModel={selectionModel}
                            />                             
                        </div>
                    </Stack>

                    <Paper sx={{p: 3}}>
                        <Typography variant='h4'>Infos</Typography>
                        <Typography variant='h5' mt={3}>Typ</Typography>
                        <Typography component='div'>
                            <ul>
                                <li>1: Suche mit Filter</li>
                                <li>2: Volltextsuche.</li>
                            </ul>
                        </Typography>
                        <Typography variant='h5' mt={3}>Properties (Props)</Typography>
                        <Typography>
                            Eintrag nur bei Typ=1 Suche mit Filter. Alle verwendeten Properties beim Filtern als id.
                            Von jedem Property wird die id in eckigen Klammern angezeigt. Dadurch kann mit der Suche nach einem Property gefiltert werden.
                            Will man Bsp. die Queries mit den Properies 9 und 25 sehen gibt man im Suchfeld [9]%[25] ein. Die Id's sind immer in aufsteigenden Reihenfolge.
                        </Typography>
                        <Typography variant='h5' mt={3}>Query</Typography>
                        <Typography>
                            Bei einer Volltextsuche steht hier der Text, nach dem gesucht wurde.
                            Bei einer Filtersuche werden alle Filter als JSON hier aufgeführt. Und zwar als Key die Property-id und als Value entweder eine auflistung aller angeklickter Filter oder bei einem Slider der min/max oder beide Werte.
                        </Typography>
                    </Paper>
                </div>
            }
            { !isLoggedin() && <Typography variant='h3'>Keine Berechtigung</Typography> }
            <Drawer className='drawer'
                PaperProps={{ sx: {width: { xs: 1, sm: 0.4 }} }}
                anchor="right"
                open={isDrawerOpen}
                onClose={handleCloseDrawer}
            >
                <div className="drawer-content">
                    <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="baseline">
                        <IconButton onClick={handleCloseDrawer}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <Typography variant='h5' gutterBottom color="primary">Query</Typography>
                    <Paper
                        elevation={0}
                        component="form"
                        autoComplete="off"
                    >
                        <Stack spacing={4}>
                            <Stack spacing={2} alignItems='flex-start'>
                            <TextField variant="standard" label="id" type="text" disabled fullWidth value={ values.id }/>
                                <TextField variant="standard" label="Datum" type="text" disabled fullWidth value={ values.creationtime }/>
                                <TextField variant="standard" label="Properties" type="text" disabled fullWidth value={ values.properties ? values.properties : '' }/>
                                <TextField variant="standard" label="Suchtext" type="text" disabled fullWidth value={ values.query }/>
                                <TextField variant="standard" label="Sprache" type="text" disabled fullWidth
                                    value={(languages &&  languages.find(o => o.id === values.language) ) ? languages.find(o => o.id === values.language).symbol : values.language}
                                />
                            </Stack>
                            <Stack direction='row' spacing={1}>
                                <Button variant='contained' type='reset' onClick={handleCloseDrawer}>Cancel</Button>
                                <Button variant='contained' color="warning"
                                    onClick={() => {
                                        handleDelete( values.id )
                                    }}
                                >Delete</Button>
                            </Stack>
                        </Stack>
                    </Paper>
                </div>
            </Drawer>
            <Dialog open={isDialogOpen}>
                <DialogTitle>Löschen</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Wollen Sie die ausgewählten Queries wirklich löschen?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color='warning'
                        onClick={() => {
                            handleDeleteChecked()
                            setIsDialogOpen(false)
                        }}
                    >Ok</Button>
                    <Button onClick={() => setIsDialogOpen(false)}>Abbruch</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={isDialogDeleteAllOpen}>
                <DialogTitle>Löschen</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Wollen Sie wirklich ALLE Queries löschen?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color='warning'
                        onClick={() => {
                            handleDeleteAll()
                            setIsDialogDeleteAllOpen(false)
                        }}
                    >Ok</Button>
                    <Button onClick={() => setIsDialogDeleteAllOpen(false)}>Abbruch</Button>
                </DialogActions>
            </Dialog>
        </Container>
     );
}


export default Queriespage;